import { Pipe, PipeTransform } from '@angular/core';
import { FormatUtils } from '@utils/format-utils';

const formatters: { [key: string]: (val: string) => string } = {
  cpf: FormatUtils.formatCPFToString,
  cnpj: FormatUtils.formatCNPJToString,
  cardExpiration: FormatUtils.formatCardExpirationIfNeeded,
  zip: FormatUtils.formatZipCodeToString,
  phone: FormatUtils.maskPhone,
};

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  transform(data: any, type: keyof typeof formatters): string {
    return formatters[type](data.toString());
  }
}
