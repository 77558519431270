import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GymBenefit } from '@models';
import { GymService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class GymBenefitResolveGuard implements Resolve<GymBenefit[]> {

  constructor(
    private readonly gymService: GymService,
  ) {}

  resolve(): ResolveResult<GymBenefit[]> {
    return this.gymService.getBenefits();
  }
}
