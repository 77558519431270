import { LocationStrategy } from '@angular/common';
import { ElementRef, OnChanges, OnDestroy, Renderer2, isDevMode } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
var RouterLinkDirective = (function () {
    function RouterLinkDirective(router, route, tabIndex, renderer, el) {
        this.router = router;
        this.route = route;
        this.commands = [];
        if (tabIndex == null) {
            renderer.setAttribute(el.nativeElement, 'tabindex', '0');
        }
    }
    Object.defineProperty(RouterLinkDirective.prototype, "appRouterLink", {
        set: function (commands) {
            if (commands != null) {
                this.commands = Array.isArray(commands) ? commands : [commands];
                this.isInternal = this.commands[0].search(/(\.|\/[^\/])/) === 0;
            }
            else {
                this.commands = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouterLinkDirective.prototype, "preserveQueryParams", {
        set: function (value) {
            if (isDevMode() && console && console.warn) {
                console.warn('preserveQueryParams is deprecated!, use queryParamsHandling instead.');
            }
            this.preserve = value;
        },
        enumerable: true,
        configurable: true
    });
    RouterLinkDirective.prototype.onClick = function () {
        var extras = {
            skipLocationChange: attrBoolValue(this.skipLocationChange),
            replaceUrl: attrBoolValue(this.replaceUrl),
        };
        if (this.isInternal) {
            this.router.navigateByUrl(this.urlTree, extras);
        }
        else {
            this.router.navigateByUrl(this.commands[0], extras);
        }
        return true;
    };
    Object.defineProperty(RouterLinkDirective.prototype, "urlTree", {
        get: function () {
            return this.router.createUrlTree(this.commands, {
                relativeTo: this.route,
                queryParams: this.queryParams,
                fragment: this.fragment,
                preserveQueryParams: attrBoolValue(this.preserve),
                queryParamsHandling: this.queryParamsHandling,
                preserveFragment: attrBoolValue(this.preserveFragment),
            });
        },
        enumerable: true,
        configurable: true
    });
    return RouterLinkDirective;
}());
export { RouterLinkDirective };
var RouterLinkWithHrefDirective = (function () {
    function RouterLinkWithHrefDirective(router, route, locationStrategy, renderer, el) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.locationStrategy = locationStrategy;
        this.renderer = renderer;
        this.el = el;
        this.commands = [];
        this.subscription = router.events.subscribe(function (s) {
            if (s instanceof NavigationEnd) {
                _this.updateTargetUrlAndHref();
            }
        });
    }
    Object.defineProperty(RouterLinkWithHrefDirective.prototype, "appRouterLink", {
        set: function (commands) {
            if (commands != null) {
                this.commands = Array.isArray(commands) ? commands : [commands];
                this.isInternal = this.commands[0].search(/(\.|\/[^\/])/) === 0;
                this.setTargetAndRel();
            }
            else {
                this.commands = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    RouterLinkWithHrefDirective.prototype.setTargetAndRel = function () {
        if (!this.target) {
            this.target = this.isInternal ? '_self' : '_blank';
        }
        if (this.target === '_blank') {
            this.renderer.setAttribute(this.el.nativeElement, 'rel', 'noopener');
        }
        else {
            this.renderer.removeAttribute(this.el.nativeElement, 'rel');
        }
    };
    RouterLinkWithHrefDirective.prototype.ngOnChanges = function (_) { this.updateTargetUrlAndHref(); };
    RouterLinkWithHrefDirective.prototype.ngOnDestroy = function () { this.subscription.unsubscribe(); };
    RouterLinkWithHrefDirective.prototype.onClick = function (button, ctrlKey, metaKey, shiftKey) {
        if (button !== 0 || ctrlKey || metaKey || shiftKey) {
            return true;
        }
        if (typeof this.target === 'string' && this.target !== '_self') {
            return true;
        }
        var extras = {
            skipLocationChange: attrBoolValue(this.skipLocationChange),
            replaceUrl: attrBoolValue(this.replaceUrl),
        };
        if (this.isInternal) {
            this.router.navigateByUrl(this.urlTree, extras);
        }
        else {
            window.location.assign(this.commands[0]);
        }
        return false;
    };
    RouterLinkWithHrefDirective.prototype.updateTargetUrlAndHref = function () {
        if (this.isInternal) {
            this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
        }
        else {
            this.href = this.commands[0];
        }
    };
    Object.defineProperty(RouterLinkWithHrefDirective.prototype, "urlTree", {
        get: function () {
            return this.router.createUrlTree(this.commands, {
                relativeTo: this.route,
                queryParams: this.queryParams,
                fragment: this.fragment,
                preserveQueryParams: attrBoolValue(this.preserve),
                queryParamsHandling: this.queryParamsHandling,
                preserveFragment: attrBoolValue(this.preserveFragment),
            });
        },
        enumerable: true,
        configurable: true
    });
    return RouterLinkWithHrefDirective;
}());
export { RouterLinkWithHrefDirective };
function attrBoolValue(s) {
    return s === '' || !!s;
}
