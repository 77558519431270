import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var BodyClassService = (function () {
    function BodyClassService(document) {
        this.document = document;
        this.body = this.document.body;
    }
    BodyClassService.prototype.setClass = function (str) {
        this.body.classList.add(str);
    };
    BodyClassService.prototype.removeClass = function (str) {
        this.body.classList.remove(str);
    };
    BodyClassService.ngInjectableDef = i0.defineInjectable({ factory: function BodyClassService_Factory() { return new BodyClassService(i0.inject(i1.DOCUMENT)); }, token: BodyClassService, providedIn: "root" });
    return BodyClassService;
}());
export { BodyClassService };
