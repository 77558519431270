var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { HttpClient } from '@angular/common/http';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
var PolicyService = (function () {
    function PolicyService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
    }
    PolicyService.prototype.getPrivacyPolicy = function () {
        var _this = this;
        if (this.cachedPrivacyPolicy) {
            return of(this.cachedPrivacyPolicy);
        }
        else {
            return this.http.get('assets/data/privacy-policy.json').pipe(tap(function (data) { return _this.cachedPrivacyPolicy = data; }));
        }
    };
    PolicyService.prototype.getTermsByGymUnitSlug = function (gymUnitSlugs) {
        if (!gymUnitSlugs.length) {
            return of({});
        }
        gymUnitSlugs = Array.from(new Set(gymUnitSlugs));
        var queryBody = gymUnitSlugs.map(function (slug, i) {
            return "term" + i + ": getSubscriptionTermBySlug(gymUnitSlug: \"" + slug + "\") { url }";
        }).join('\n');
        return this.apollo.query({
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["query { ", " }"], ["query { ", " }"])), queryBody),
        })
            .pipe(map(function (json) {
            var terms = {};
            var result = JSONUtil.turnApolloMutable()(json);
            gymUnitSlugs.forEach(function (slug, i) {
                terms[slug] = result["term" + i];
            });
            return terms;
        }));
    };
    return PolicyService;
}());
export { PolicyService };
var templateObject_1;
