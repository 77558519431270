<div class="text-center" *ngIf="data" (deferLoad)="setLoadImage()">
  <div class="title d-none d-md-block font-weight-light">{{ data.title }}</div>
  <div class="app-bar">
    <a id="get-app-android"
      (click)="clickApp('BT-Android')"
      (auxclick)="clickApp('BT-Android')"
      target="_blank"
      rel="noopener"
      href="https://play.google.com/store/apps/details?id=com.nostro.bodytech&hl=pt_BR">
      <img alt="Baixar na Play Store" *ngIf="loadImage" src="assets/footer/google.svg"/>
    </a>

    <a id="get-app-ios"
      (click)="clickApp('BT-iOS')"
      (auxclick)="clickApp('BT-iOS')"
      target="_blank"
      rel="noopener"
      href="https://itunes.apple.com/br/app/bodytech/id549237579?mt=8">
      <img alt="Baixar na App Store" *ngIf="loadImage" src="assets/footer/apple.svg"/>
    </a>
  </div>
</div>
