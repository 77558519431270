import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface MapOfURLs { [slug: string]: { url: string }; }

@Injectable()
export class PolicyService {
  private cachedPrivacyPolicy: string[];

  constructor(
    private readonly http: HttpClient,
    private readonly apollo: Apollo,
  ) { }

  getPrivacyPolicy(): Observable<string[]> {
    if (this.cachedPrivacyPolicy) {
      return of(this.cachedPrivacyPolicy);
    } else {
      return this.http.get<string[]>('assets/data/privacy-policy.json').pipe(
        tap(data => this.cachedPrivacyPolicy = data),
      );
    }
  }

  getTermsByGymUnitSlug(gymUnitSlugs: string[]): Observable<MapOfURLs> {
    if (!gymUnitSlugs.length) {
      return of({});
    }

    gymUnitSlugs = Array.from(new Set(gymUnitSlugs));
    const queryBody = gymUnitSlugs.map((slug, i) => {
      return `term${i}: getSubscriptionTermBySlug(gymUnitSlug: "${slug}") { url }`;
    }).join('\n');


    return this.apollo.query<any>({
      query: gql`query { ${queryBody} }`,
    })
    .pipe(
      map(json => {
        const terms: MapOfURLs = {};
        const result = JSONUtil.turnApolloMutable<MapOfURLs>()(json);

        gymUnitSlugs.forEach((slug, i) => {
          terms[slug] = result[`term${i}`];
        });

        return terms;
      }),
    );
  }

}
