import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgmCoreModule } from '@agm/core';
import { Angulartics2Module } from 'angulartics2';
import { ModalModule } from 'ngx-bootstrap/modal';

import { googlemapsapikey, environment, isProduction } from '@env/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DebugModule } from './debug/debug.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedModule } from './shared/shared.module';
import { footerComponents } from './site-footer';
import { headerComponents } from './site-header';
import { NavbarUserMenuComponent } from './site-header/navbar-top/navbar-user-menu/navbar-user-menu.component';
import { OpenModalPaddingModule } from './component-modules/open-modal-padding/open-modal-padding.module';
import { SentryErrorHandler } from './sentry-error-handler';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

const toastrConfig = {
  positionClass: 'inline',
  timeOut: 10000,
  preventDuplicates: true,
  progressBar: true,
  closeButton: true,
  tapToDismiss: false,
};
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ...headerComponents,
    ...footerComponents,
    NavbarUserMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: googlemapsapikey }),
    DebugModule,
    AppRoutingModule,
    Angulartics2Module.forRoot({
      developerMode: !isProduction,
      gtm: { userId: environment.googleAnalytics.tagManagerId },
      ga: { userId: environment.googleAnalytics.trackingID },
    }),
    OpenModalPaddingModule,
    ToastrModule.forRoot(toastrConfig),
    ToastContainerModule,
  ],
  bootstrap: [AppComponent],
  providers: [ { provide: ErrorHandler, useClass: SentryErrorHandler } ],
})
export class AppModule { }
