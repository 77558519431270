import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { SimpleLink } from '@models';
import { HeaderService } from '@services/header.service';
import { Subscription } from 'rxjs';
import { BreakpointsService, screenSizeMap } from '@services/breakpoints.service';
import { HeaderLinksResponse } from '@models/responses/header-links-response.model';
import { PortalConfigService } from '@services/portal-config.service';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('headerContainer') headerContainer: ElementRef;
  @ViewChild('headerContent', { read: ViewContainerRef }) headerContent: ViewContainerRef;

  private subs: Subscription;
  barHeightArray = [ 64, 64, 80, 80, 80 ];
  barHeightClose = 80;
  headerHeight = 120;
  opacity = 1;
  width = 0;

  links: SimpleLink[];
  linksMobile: SimpleLink[];
  linksLogged: SimpleLink[];
  private templateListener: Subscription;

  constructor(
    private readonly headerService: HeaderService,
    private readonly breakpointsService: BreakpointsService,
    private readonly portalConfigService: PortalConfigService,
  ) { }

  ngOnInit() {

    this.portalConfigService.getPortalConfiguration<HeaderLinksResponse>('header-menu')
      .subscribe((response: HeaderLinksResponse) => {
        if (response.links) {
          this.links = response.links;
          this.linksMobile = response.mobile;
          this.linksLogged = response.logged;
        } else {
          this.setDefaultLinks();
        }
      });

    this.templateListener = this.headerService.template.subscribe(data => {
      this.headerContent.clear();
      if (data) {
        this.headerContent.createEmbeddedView(data);
        setTimeout(() => this.headerHeight = this.headerContainer.nativeElement.clientHeight, 0);
      }
    });
    this.subs = this.breakpointsService.emitter.subscribe(size => {
      this.barHeightClose = screenSizeMap(size, this.barHeightArray);
      this.headerHeight = this.headerContainer.nativeElement.clientHeight;
    });
  }

  setDefaultLinks() {
    this.headerService.getHeaderLinks().subscribe(data => this.links = data);
    this.headerService.getHeaderLinksMobile().subscribe(data => this.linksMobile = data);
    this.headerService.getHeaderLinksLogged().subscribe(data => this.linksLogged = data);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.templateListener.unsubscribe();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.opacity = 1 - window.pageYOffset / (this.headerHeight - this.barHeightClose);
  }
}
