<div class="modal fade" bsModal #templateModal="bs-modal"
  (onHidden)="dismiss()"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-title title text-center">{{ modalObj.title }}</div>
      </div>
      <div class="modal-body text-justify" [innerHTML]="modalObj.message"></div>

      <div class="modal-footer">
        <button *ngIf="modalObj?.cancelButton" type="button"
          class="col btn btn-outline-secondary" (click)="cancel()">
          {{ modalObj.cancelButton || 'Cancelar' }}
        </button>
        <button type="button" class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj?.confirmButton || 'OK' }}
        </button>
      </div>
    </div>
  </div>
</div>
