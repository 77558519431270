import { ActivatedRoute } from '@angular/router';
import { BasicHeaderConfig } from '@models/configs';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from '@services';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  public config: BasicHeaderConfig;

  @ViewChild('header') headerComponent: TemplateRef<any>;

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
  ) {
    this.config = this.route.snapshot.data.config.notFound;
  }

  ngOnInit() {
    this.headerService.template.next(this.headerComponent);
  }

}
