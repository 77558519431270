import { HttpClient } from '@angular/common/http';
import { sur } from '@utils/app-endpoints';
import { BehaviorSubject } from 'rxjs';
import { catchError, scan } from 'rxjs/operators';
var Message = (function () {
    function Message(content, sendBy, when) {
        this.content = content;
        this.sendBy = sendBy;
        this.when = when;
    }
    Message.USER = 'user';
    Message.BOT = 'bot';
    return Message;
}());
export { Message };
var BotResponse = (function () {
    function BotResponse() {
    }
    return BotResponse;
}());
var FaqBotService = (function () {
    function FaqBotService(http) {
        this.http = http;
        this.endpoint = sur("/faq");
        this.helloRequest = 'oi';
        this.helloResponse = 'Olá, em que posso ajudar?';
        this._conversation = new BehaviorSubject([]);
    }
    Object.defineProperty(FaqBotService.prototype, "conversation", {
        get: function () {
            return this._conversation.asObservable()
                .pipe(scan(function (acc, val) { return acc.concat(val); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FaqBotService.prototype, "conversationValue", {
        get: function () {
            return this._conversation.getValue();
        },
        enumerable: true,
        configurable: true
    });
    FaqBotService.prototype.startConversation = function () {
        var _this = this;
        this.http.get(this.endpoint, { params: { message: this.helloRequest } })
            .pipe(catchError(this.catchError))
            .subscribe(function (data) { return _this.botMessage(data); });
    };
    FaqBotService.prototype.converse = function (msg) {
        var _this = this;
        var userMessage = new Message(msg, Message.USER, new Date());
        this.update(userMessage);
        this.http.get(this.endpoint, { params: { message: msg } })
            .pipe(catchError(this.catchError))
            .subscribe(function (data) { return _this.botMessage(data); });
    };
    FaqBotService.prototype.catchError = function () {
        this.botMessage({
            answer: 'Desculpe. O Assistente Virtual está indisponível no momento.',
            code: 'notFound',
        });
        return [];
    };
    FaqBotService.prototype.botMessage = function (data) {
        var message = 'Desculpe, não entendi.';
        if (data.code === 'chatStart') {
            message = this.helloResponse;
        }
        else if (data.answer) {
            message = data.answer;
        }
        var botMessage = new Message(message, Message.BOT, new Date());
        this.update(botMessage);
    };
    FaqBotService.prototype.update = function (msg) {
        this._conversation.next([msg]);
    };
    return FaqBotService;
}());
export { FaqBotService };
