import { map } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';
import { AppGraphql } from '@utils/app-graphql';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GymResponse, Gym, GymSearchQuery } from '@models';

@Injectable()
export abstract class GymSearchAbstractService {
  constructor(
    protected readonly apollo: Apollo,
  ) {

  }
  _getGymNames(): Observable<any[]> {
    return this.apollo.query({
      query: AppGraphql.queryListGymUnitsThatAreNotHolding,
    }).pipe(
      map(JSONUtil.turnApolloMutable('listGymUnitsThatAreNotHolding')),
    );
  }
  abstract getGymNames(): Observable<any[]>;
  abstract searchBy(filterParam: GymSearchQuery, max: number, page: number): Observable<GymResponse>;
  abstract searchGymNames(text: string): Observable<Gym[]>;
}
