import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gym, GymActivity, GymSearchLocation, GymConvenience, GymSearchMessage, PhotoTypeEnum, SelectItem } from '@models';
import { ActivityService } from './activity.service';
import { BenefitService } from './benefit.service';
import { PlanService } from './plan.service';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { FormatUtils } from '@utils/format-utils';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { Observable, of, EMPTY } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface CacheType {
  id: number;
  gym: any;
}

@Injectable()
export class GymService {

  cache: CacheType[] = [];

  static preFormatGym(gym: Gym, activities?: GymActivity[]) {
    gym.googleLink = gym.address && (gym.address.latitude || gym.address.longitude)
      ? `${AppConstants.googleMapUrl}${gym.address.latitude}, ${gym.address.longitude}` : '';
    gym.formattedCNPJ = FormatUtils.formatCNPJToString(gym.cnpj.toString());

    if (gym.address && Object.keys(gym.address).length) {
        gym.address.formattedAddress = FormatUtils.formatAddressToString(gym.address);
        gym.address.formattedZipCode = FormatUtils.formatZipCodeToString(gym.address.zipCode);
        gym.address.latitudeN = parseFloat(gym.address.latitude);
        gym.address.longitudeN = parseFloat(gym.address.longitude);

        if (gym.address.city) {
          gym.address.city.formattedCity = FormatUtils.formatCityToString(gym.address.city);
        }
    }

    gym.activities = activities ? activities : [];

    gym.phones.forEach(phone => phone.formattedPhone = FormatUtils.formatPhoneToString(phone));
  }

  constructor(
    private readonly http: HttpClient,
    private readonly apollo: Apollo,
    private readonly benefitService: BenefitService,
    private readonly activityService: ActivityService,
    private readonly planService: PlanService,
    private readonly authService: AuthService,
  ) { }

  getGymNameBySlug(slug: string, withActivities = false) {
    return this.getGymNames(withActivities)
    .pipe(
      map(gymNames => gymNames.find(gymName => gymName.slug === slug)),
    );
  }

  getGymNames(withActivities = false): Observable<any[]> {
    return this.apollo.query({
      query: withActivities ? AppGraphql.queryListGymUnitsThatAreNotHoldingWithActivities : AppGraphql.queryListGymUnitsThatAreNotHolding,
    }).pipe(
      map(JSONUtil.turnApolloMutable('listGymUnitsThatAreNotHolding')),
    );
  }

  getGymNamesWithState(): Observable<Gym[]> {
    return this.apollo.query({
      query: AppGraphql.queryListGymUnitsNotHoldingWithState,
    }).pipe(
      map(JSONUtil.turnApolloMutable('listGymUnitsThatAreNotHolding')),
    );
  }

  getGymFromOldCanais(id: any) {
    const cachedGym = this.cache.find(item => item.id === id);
    if (cachedGym) {
      return of(cachedGym.gym);
    } else {
      return this.http.get<any>(back(BackEndpoints.Gym).concat(`?idUnidade=${id}`))
        .pipe(tap(gym => this.cache.push({ id, gym })));
    }
  }

  getActivities() {
    return this.activityService.getActiveActivities();
  }

  getActivityGroupTags() {
    return this.activityService.getActiveActivityGroups();
  }

  getBenefits() {
    return this.benefitService.getBenefits();
  }

  getCommotidies() {
    return this.http.get<GymConvenience[]>('assets/mockup/gym-commotidies.json');
  }

  getGym(slug: string): Observable<Gym> {

    return this.apollo.query({
      query: AppGraphql.queryGetGymUnitBySlug,
      variables: { slug },
    }).pipe(
      map(JSONUtil.turnApolloMutable<{getGymUnitBySlug: Gym, listActiveActivitiesByGymUnit: GymActivity[]}>()),
      map(data => {
        if (data.getGymUnitBySlug) {
          GymService.preFormatGym(data.getGymUnitBySlug, data.listActiveActivitiesByGymUnit);
        }
        return data.getGymUnitBySlug;
      }),
    );
  }

  getCombinatedGymUnits(gymUnitId: number) {
    return this.apollo.query({
      query: AppGraphql.queryListCombinatedGymUnits,
      variables: { gymUnitId },
    }).pipe(
      map(JSONUtil.turnApolloMutable<Gym[]>('listCombinatedGymUnits')),
    );
  }

  getPlansWithPeriodicityByGym(gym: number) {
    return this.planService.getActivePlansWithPeriodicityByGym(gym);
  }

  getBannerImage(gym: Gym) {
    return this.filterPhotos(gym, PhotoTypeEnum.BANNER)[0];
  }

  getCarrosselImages(gym: Gym) {
    return this.filterPhotos(gym, PhotoTypeEnum.CARROSSEL);
  }

  getThumbnailImage(gym: Gym) {
    return this.filterPhotos(gym, PhotoTypeEnum.THUMBNAIL)[0];
  }

  filterPhotos(gym: Gym, type: PhotoTypeEnum) {
    return gym.photos.filter(photo => photo.objectType === type);
  }

  getGymSearchMessage(): Observable<GymSearchMessage[]> {
    return this.http.get<GymSearchMessage[]>('assets/data/msgsSearch.json');
  }

  getParamNameBySlug(state: string, city: string, neighborhood: string): Observable<GymSearchLocation> {
    return this.apollo.query({
      query: AppGraphql.queryGetGymUnitStateCityAndNeighborhood,
      variables: { state, city, neighborhood },
    }).pipe(
      map(JSONUtil.turnApolloMutable<GymSearchLocation>('getGymUnitStateCityAndNeighborhood')),
      map(data => ({
        stateName: data && state ? data.stateName : '',
        cityName: data && city ? data.cityName : '',
        addressNeighborhood: data && neighborhood ? data.addressNeighborhood : '',
      })),
    );
  }

  gymToSelect(gym: Gym): SelectItem<Gym> {
    return Object.assign({
      text: gym.name,
    }, gym);
  }

  getUserMainGym(withActivities = false) {
    const user = this.authService.getUser();
    if (user) {
      return this.getGymNames(withActivities)
        .pipe(
          map(gyms => gyms.find(name => name.id === user.maingym)),
        );
    }
    return EMPTY;
  }

  queryGetGymUnitSlug(gymUnitId: number) {
    return this.apollo.query({
      query: AppGraphql.queryGetGymUnitSlug,
      variables: { gymUnitId },
    }).pipe(
      map(JSONUtil.turnApolloMutable<any[]>('listPlansFromView')),
      map(result => {
        return result[0].gymUnitSlug;
      }),
    );
  }

}
