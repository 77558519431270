import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CouponResponse, CurrentPurchase, PaymentData, RegistrationPersonalData, RegistrationRequest, RegistrationValues } from '@models';
import { LoadingService } from './loading.service';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class RegistrationService {

  private total = 3;

  constructor(
    private readonly http: HttpClient,
    private readonly apollo: Apollo,
    private readonly loadingService: LoadingService,
  ) { }

  checkCouponDiscount(coupon: string, cpf: string): Observable<CouponResponse> {
    const options = { headers: { 'Content-Type': 'application/json' } };
    coupon = '"' + coupon + '"';

    let couponEndpoint: BackEndpoints;
    let registrationEndpoint: BackEndpoints;

    if (localStorage.getItem('user-token')) {
      couponEndpoint = BackEndpoints.CuponCliente;
      registrationEndpoint = BackEndpoints.PurchaseItem;
    } else {
      couponEndpoint = BackEndpoints.Coupon;
      registrationEndpoint = BackEndpoints.RegistrationItem;
    }

    if (cpf) {
      Object.assign(options, { params: { idCompraUsuario: cpf} });
    }

    return this.http.post<any>(back(couponEndpoint), coupon, options)
      .pipe(
        mergeMap(() => this.http.get<any>(back(registrationEndpoint), options)),
        map(data => {
          const resumo = JSONUtil.get(data, 'ResumoCompraDcc') || JSONUtil.get(data, 'ResumoCompraDemaisPeriodicidades');

          const listItens: any[] = JSONUtil.get(resumo, 'ListaItensEscolhidos');
          const listParcels: any[] = JSONUtil.get(resumo, 'ListaOpcoesParcelamento');

          const couponResp: CouponResponse = {
            value: 0,
            discount: 0,
            total: 0,
            type: 0,
            parcels: listParcels.map(parc => ({
              value: parc.Valor,
              quantity: parc.QuantidadeParcelas,
            })),
          };

          listItens.forEach(item => {
            if (item.ValorDoDesconto !== 0) {
              couponResp.discount = Math.abs(item.ValorDoDesconto);
              couponResp.total = item.ValorComDesconto;
              couponResp.value = item.Valor;
              couponResp.type = item.TipoItem;
            }
          });

          return couponResp;
        }),
      );
  }

  checkValues(cpf: string): Observable<RegistrationValues> {
    const options = cpf ? { params: { idCompraUsuario: cpf} } : {};
    return this.http.get(back(cpf ? BackEndpoints.RegistrationItem : BackEndpoints.PurchaseItem), options)
      .pipe(
        map(data => {
          const resumo = JSONUtil.get(data, 'ResumoCompraDcc') || JSONUtil.get(data, 'ResumoCompraDemaisPeriodicidades');

          const value = JSONUtil.get(resumo, 'ListaItensEscolhidos.0');
          const adhesion = JSONUtil.get(resumo, 'ListaItensEscolhidos.1');

          return {
            value: value ? value.Valor : 0,
            adhesion: adhesion ? adhesion.Valor : 0,
          };
        }),
      );
  }

  checkOperations(gymId: string, groupId: string) {
    const options = { params: { unidade: gymId, agrupamentoPlano: groupId} };
    return this.http.get(back(BackEndpoints.CheckOperations), options);
  }

  checkCardFlag(flag: number, cardNumber: string): Observable<boolean> {
    return this.http.get<Array<{number: string, flag: number}>>('assets/mockup/registration/card-flag.json')
    .pipe(
      map(values => values.find(v => cardNumber.startsWith(v.number)).flag === flag),
    );
  }

  // @ts-ignore
  sendPersonalData(formData: RegistrationPersonalData): Observable<boolean> {
    return this.http.get<{origin: string}>('https://httpbin.org/get')
    .pipe(
      map(json => json.origin.length > 0),
    );
  }

  // @ts-ignore
  sendRegistrationData(formData: RegistrationRequest): Observable<string> {
    return of(Math.random().toString().split('.')[1]);
  }

  setTotalSteps(total: number) {
    this.total = total;
  }

  startPurchase(data: any, cpf: string, preSale: boolean): Observable<PaymentData> {
    const options = cpf ? { params: { idCompraUsuario: cpf} } : {};
    this.loadingService.startLoading(this.percent(0, this.total));

    const endpoint = preSale ? BackEndpoints.PreSaleNewClientStart : BackEndpoints.RegistrationStart;

    return this.http.post(back(endpoint), data.personal, options).pipe(
        tap(      () => this.loadingService.startLoading(this.percent(1, this.total))             ),
        mergeMap( () => this.http.post(back(BackEndpoints.RegistrationItem), data.plans, options) ),
        tap(      () => this.loadingService.startLoading(this.percent(2, this.total))             ),
        mergeMap(() => this.http.get <PaymentData>(back(BackEndpoints.RegistrationCheckPayment), options)      ),
        catchError(err => {
          console.log('Error in personal', err);
          return throwError(err);
        }),
      );
  }

  getCurrentPurchase(cpf: string): Observable<CurrentPurchase> {
    const options = cpf ? { params: { idCompraUsuario: cpf} } : {};
    return this.http.get<CurrentPurchase>(back(BackEndpoints.RegistrationItem), options).pipe(
      catchError(err => {
        console.log('Error in get current purchase', err);
        return throwError(err);
      }),
    );
  }

  registerUser(data: any, cpf: string) {
    const options = cpf ? { params: { idCompraUsuario: cpf } } : {};
    this.loadingService.startLoading(this.percent(this.total - 3, this.total));

    return this.http.post(back(BackEndpoints.RegistrationSendPersonal), data.personal, options).pipe(
        tap(      () => this.loadingService.startLoading(this.percent(this.total - 2, this.total))         ),
        mergeMap( () => this.http.post(back(BackEndpoints.RegistrationSendAddress), data.address, options) ),
        catchError(err => {
          console.log('Error in registering', err);
          return throwError(err);
        }),
      );
  }

  finalizePurchase(data: any, cpf: string) {
    const options = cpf ? { params: { idCompraUsuario: cpf } } : {};
    this.loadingService.startLoading(this.percent(this.total - 1, this.total));

    return this.http.post(back(BackEndpoints.RegistrationFinish), data.payment, options).pipe(
        catchError(err => {
          console.log('Error in finalize', err);
          return throwError(err);
        }),
      );
  }

  startLoggedPurchase(data: any, preSale: boolean) {
    const options = { params: { unidade: data.personal.unidade } };
    this.loadingService.startLoading(this.percent(0, this.total));

    const endpoint = preSale ? back(BackEndpoints.PreSaleStart) : back(BackEndpoints.PurchaseStart);

    return this.http.get(endpoint, options).pipe(
      tap(      () => this.loadingService.startLoading(this.percent(1, this.total)) ),
      mergeMap( () => this.http.post(back(BackEndpoints.PurchaseItem), data.plans)  ),
      tap(      () => this.loadingService.startLoading(this.percent(2, this.total)) ),
      mergeMap( () => this.http.get(back(BackEndpoints.PurchaseCheckPayment))       ),
        catchError(err => {
          console.log('Error in init', err);
          return throwError(err);
        }),
      );
  }

  finalizeLoggedPurchase(data: any) {
    this.loadingService.startLoading(this.percent(1, 2));

    return this.http.post(back(BackEndpoints.PurchaseFinish), data).pipe(
        catchError(err => {
          console.log('Error in finalize logged', err);
          return throwError(err);
        }),
      );
  }

  private percent(current: number, total: number): string {
    return Math.floor(100 * current / total).toString() + '%';
  }

  getCoupomDescription(id: number) {
    return this.apollo.query({
      query: AppGraphql.queryCoupon,
      variables: { id },
    }).pipe(
      map(JSONUtil.turnApolloMutable<any>('coupon')),
    );
  }

  /**
   * PRE SALE METHODS
   */

  finalizePreSalePurchase(data: any, cpf: string) {
    const options = cpf ? { params: { idCompraUsuario: cpf } } : {};
    this.loadingService.startLoading(this.percent(this.total - 1, this.total));

    return this.http.post(back(BackEndpoints.PreSaleNewClientFinish), data.payment, options).pipe(
        catchError(err => {
          console.log('Error in finalize Pre-Sale', err);
          return throwError(err);
        }),
      );
  }

  finalizeLoggedPurchasePresale(data: any) {
    this.loadingService.startLoading(this.percent(1, 2));

    return this.http.post(back(BackEndpoints.PreSaleClientFinish), data).pipe(
        catchError(err => {
          console.log('Error in finalize', err);
          return throwError(err);
        }),
      );
  }
}
