import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SelectItem } from '@models';
import { UserProfileService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class ProfileRelationshipResolveGuard implements Resolve<SelectItem[]> {

  constructor(
    private readonly profileService: UserProfileService,
  ) {}

  resolve(): ResolveResult<SelectItem[]> {
    return this.profileService.getRelationships();
  }

}
