import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingsResponse, Training, Ergometer } from '@models';
import { Observable, EMPTY } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { GymService } from './gym.service';

@Injectable()
export class UserTrainingService {

  constructor(
    private readonly http: HttpClient,
    private readonly gymService: GymService,
  ) { }

  getDashboard(): Observable<TrainingsResponse[]> {
    return this.http.get<any[]>(back(BackEndpoints.Training))
      .pipe(
        map(json => json || []),
        map(jsonResponse => {
          const response: TrainingsResponse[] = [];
          for (const json of jsonResponse) {
            const detalhes: any[] = JSONUtil.get(json, 'DetalheTreino') || [];
            const trainings: Training[] = [];
            const ergometer: Ergometer[] = [];
            detalhes.forEach(item =>
              JSONUtil.get(item, 'TipoTreino')
                ? ergometer.push(this.extractErgometerFromJson(item))
                : trainings.push(this.extractTrainingFromJson(item)),
            );
            response.push(Object.assign(
              this.composeTrainingResponse(json),
              { ergometer, trainings },
              { letter: String.fromCharCode(97 + (jsonResponse.indexOf(json) % 26)) },
            ));
          }
          return response;
        }),
        mergeMap(json => json ? this.gymService.getGymNames()
          .pipe(
            tap(gyms => json.forEach(train => train.gym = gyms.find(gym => gym.id === train.gym).name || 'Servidor indisponível')),
            map(_ => json),
          ) : EMPTY,
        ),
      );
  }

  extractTrainingFromJson(item: any) {
    return {
      order: JSONUtil.get(item, 'Ordem'),
      machine: JSONUtil.get(item, 'Maquina'),
      exercise: JSONUtil.get(item, 'Exercicio'),
      series: JSONUtil.get(item, 'Serie'),
      repetition: JSONUtil.get(item, 'Repeticao'),
      load: JSONUtil.get(item, 'Carga'),
      regulate: JSONUtil.get(item, 'Regulagem'),
      interval: JSONUtil.get(item, 'Frequencia'),
      method: JSONUtil.get(item, 'Metodo'),
    };
  }

  extractErgometerFromJson(item: any) {
    return {
      exercise: JSONUtil.get(item, 'Exercicio'),
      frequency: JSONUtil.get(item, 'Frequencia'),
      load: JSONUtil.get(item, 'Carga'),
      target: JSONUtil.get(item, 'ZonaAlvo'),
      time: JSONUtil.get(item, 'Tempo'),
      observation: JSONUtil.get(item, 'Observacao'),
    };
  }

  composeTrainingResponse(json: any): TrainingsResponse {
    return {
      gym: JSONUtil.get(json, 'Unidade') || 0,
      start: JSONUtil.getDate(json, 'DataCriacao'),
      end: JSONUtil.getDate(json, 'DataVencimento'),
      sequence: JSONUtil.get(json, 'Sequencia'),
      frequency: JSONUtil.get(json, 'Frequencia'),
      progress: {
        current: JSONUtil.get(json, 'QuantidadeTreinosRealizados'),
        total: JSONUtil.get(json, 'QuantidadeTreinosPrescritos'),
      },
      trainings: [],
      ergometer: [],
      observation: JSONUtil.get(json, 'Observacao'),
      streching: JSONUtil.get(json, 'Alongamento'),
      teacher: JSONUtil.get(json, 'Professor'),
    };
  }

}
