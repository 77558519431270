import * as i0 from "./header-static-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipe/bucket.pipe";
import * as i3 from "../../../component-modules/open-modal-padding/width-from-window.directive";
import * as i4 from "../../services/breakpoints.service";
import * as i5 from "@angular/common";
import * as i6 from "./header-static-image.component";
var styles_HeaderStaticImageComponent = [i0.styles];
var RenderType_HeaderStaticImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderStaticImageComponent, data: {} });
export { RenderType_HeaderStaticImageComponent as RenderType_HeaderStaticImageComponent };
export function View_HeaderStaticImageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.BucketPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "img", [["appWidthFromWindow", ""], ["class", "header-image d-block"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i3.WidthFromWindowDirective, [i1.ElementRef, i1.Renderer2, i4.BreakpointsService], null, null), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "header-text text-light text-center w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h1", [["class", "title header-title"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.headerObj == null) ? null : _co.headerObj.titleStyle); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.prefixBucket ? i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), (((_co.headerObj == null) ? null : _co.headerObj.image) || _co.imageUrl))) : (((_co.headerObj == null) ? null : _co.headerObj.image) || _co.imageUrl)); _ck(_v, 1, 0, currVal_0); var currVal_2 = (((_co.headerObj == null) ? null : _co.headerObj.title) || _co.pageTitle); _ck(_v, 8, 0, currVal_2); }); }
export function View_HeaderStaticImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-static-image", [], null, null, null, View_HeaderStaticImageComponent_0, RenderType_HeaderStaticImageComponent)), i1.ɵdid(1, 49152, null, 0, i6.HeaderStaticImageComponent, [], null, null)], null, null); }
var HeaderStaticImageComponentNgFactory = i1.ɵccf("app-header-static-image", i6.HeaderStaticImageComponent, View_HeaderStaticImageComponent_Host_0, { pageTitle: "pageTitle", imageUrl: "imageUrl", prefixBucket: "prefixBucket", headerObj: "headerObj" }, {}, []);
export { HeaderStaticImageComponentNgFactory as HeaderStaticImageComponentNgFactory };
