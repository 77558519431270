import { Pipe, PipeTransform } from '@angular/core';
import { GymUnitPeriodicity } from '@models';

@Pipe({
  name: 'sortPlans',
})
export class SortPlansPipe implements PipeTransform {
  private static readonly planOrder = ['ANUAL', 'SEMESTRAL', 'TRIMESTRAL', 'MENSAL', 'DÉBITO MENSAL'];

  transform(gymUnitPeriodicity: GymUnitPeriodicity[]): GymUnitPeriodicity[] {
    let extraIndex = SortPlansPipe.planOrder.length;

    return gymUnitPeriodicity
    .map((gup): [number, GymUnitPeriodicity] => {
      let idx = SortPlansPipe.planOrder.indexOf(gup.periodicity.name.toUpperCase());
      if (idx < 0) {
        idx = extraIndex;
        extraIndex++;
      }
      return [idx, gup];
    })
    .sort((a, b) => a[0] - b[0])
    .map(([_, gup]) => gup);
  }
}
