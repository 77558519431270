import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-static-image',
  templateUrl: './header-static-image.component.html',
  styleUrls: ['./header-static-image.component.scss'],
})
export class HeaderStaticImageComponent {

  @Input() pageTitle = '';
  @Input() imageUrl = '';
  @Input() prefixBucket = true;
  @Input() headerObj: {title: string, image: string, [keys: string]: any};
}
