import * as i0 from "./footer-help.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@trademe/ng-defer-load/dist/defer-load.directive";
import * as i5 from "./footer-help.component";
var styles_FooterHelpComponent = [i0.styles];
var RenderType_FooterHelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterHelpComponent, data: {} });
export { RenderType_FooterHelpComponent as RenderType_FooterHelpComponent };
function View_FooterHelpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "btn btn-primary"], ["routerLinkActive", "d-none"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.url; _ck(_v, 1, 0, currVal_3); var currVal_4 = "d-none"; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "help-", _v.context.index, ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.text; _ck(_v, 5, 0, currVal_5); }); }
function View_FooterHelpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "img-block d-flex justify-content-center align-items-center"]], [[4, "background-image", null]], [[null, "deferLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deferLoad" === en)) {
        var pd_0 = ((_co.loadImage = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i4.DeferLoadDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], null, { deferLoad: "deferLoad" }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "img-caption d-flex flex-column h-100 justify-content-around"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title-footer text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "buttons-container w-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterHelpComponent_2)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.links; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + (_co.loadImage ? _co.data.image : "")) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_FooterHelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterHelpComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterHelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-help", [], null, null, null, View_FooterHelpComponent_0, RenderType_FooterHelpComponent)), i1.ɵdid(1, 49152, null, 0, i5.FooterHelpComponent, [], null, null)], null, null); }
var FooterHelpComponentNgFactory = i1.ɵccf("app-footer-help", i5.FooterHelpComponent, View_FooterHelpComponent_Host_0, { data: "data" }, {}, []);
export { FooterHelpComponentNgFactory as FooterHelpComponentNgFactory };
