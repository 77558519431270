import * as i0 from "./sitemap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/router-external.directive";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../shared/directives/router-active.directive";
import * as i6 from "./sitemap.component";
var styles_SitemapComponent = [i0.styles];
var RenderType_SitemapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SitemapComponent, data: {} });
export { RenderType_SitemapComponent as RenderType_SitemapComponent };
function View_SitemapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["appRouterLinkActive", "text-primary"], ["class", "title"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4], ["self", 4]], 0, i2.RouterLinkWithHrefDirective, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy, i1.Renderer2, i1.ElementRef], { appRouterLink: [0, "appRouterLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i5.RouterActiveDirective, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { appRouterLinkActive: [0, "appRouterLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.url; _ck(_v, 1, 0, currVal_3); var currVal_4 = "text-primary"; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "sitemap-", _v.context.index, ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.text; _ck(_v, 5, 0, currVal_5); }); }
function View_SitemapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-dark row align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.links; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SitemapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SitemapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sitemap", [], null, null, null, View_SitemapComponent_0, RenderType_SitemapComponent)), i1.ɵdid(1, 49152, null, 0, i6.SitemapComponent, [], null, null)], null, null); }
var SitemapComponentNgFactory = i1.ɵccf("app-sitemap", i6.SitemapComponent, View_SitemapComponent_Host_0, { data: "data" }, {}, []);
export { SitemapComponentNgFactory as SitemapComponentNgFactory };
