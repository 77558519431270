var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
function interpolateParams(str, params) {
    try {
        return templateString ? templateString(str, { params: params }) : eval("`" + str + "`");
    }
    catch (_a) {
        console.warn('Interpolate error on PortalConfigService.\nEval failed for: ' + str);
        return str;
    }
}
var PortalConfigService = (function () {
    function PortalConfigService(apollo) {
        this.apollo = apollo;
    }
    PortalConfigService.prototype.getPortalConfiguration = function (tag) {
        return this.apollo.query({
            query: AppGraphql.queryGetPortalConfiguration,
            variables: { tag: tag },
        }).pipe(map(JSONUtil.turnApolloMutable('getPortalConfiguration')), map(function (json) {
            try {
                return JSON.parse(json['configValue']);
            }
            catch (_a) {
                return {};
            }
        }));
    };
    PortalConfigService.prototype.getPortalConfigurations = function (config, params) {
        if (params === void 0) { params = {}; }
        var body = Object.entries(config).map(function (_a) {
            var key = _a[0], value = _a[1];
            return key + ": getPortalConfiguration(tag: \"" + interpolateParams(value, params) + "\") { configValue }";
        }).join('\n');
        return this.apollo.query({
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["query { ", " }"], ["query { ", " }"])), body),
        }).pipe(map(function (json) {
            var result = {};
            Object.keys(config).forEach(function (k) {
                try {
                    result[k] = JSON.parse(json.data[k].configValue);
                }
                catch (_a) {
                    result[k] = {};
                }
            });
            return result;
        }));
    };
    return PortalConfigService;
}());
export { PortalConfigService };
var templateObject_1;
