import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MyPurchasesResponse } from '@models';
import { MyPurchasesService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class PurchaseResolveGuard implements Resolve<MyPurchasesResponse> {

  constructor(
    private readonly myPurchasesService: MyPurchasesService,
  ) {}

  resolve(): ResolveResult<MyPurchasesResponse> {
    return this.myPurchasesService.getInfo();
  }

}
