import { FullGymSearchConfig } from '@models/configs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AgendaAgeRange,
  Gym,
  GymActivity,
  GymAgendaFilter,
  GymFilter,
  ModalAgesObject,
  ModalGymActivityObject,
  ModalGymSelectObject,
  ModalType,
} from '@models';
import { ModalService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { ArrayUtil } from '@utils/array-util';
import { FormatUtils } from '@utils/format-utils';
import { Brand, BrandService } from '@services/brand.service';

@Component({
  selector: 'app-agenda-filter',
  templateUrl: './agenda-filter.component.html',
  styleUrls: ['./agenda-filter.component.scss'],
})
export class AgendaFilterComponent implements OnInit {
  private filterGymActivitiesByGymCache: { [key: number]: GymActivity[] } = {};

  @Input() showPlace = false;
  @Input() gym: Gym;
  @Input() allActivities: GymActivity[];

  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;

  activityGroups: GymFilter[] = [];

  activityTags: GymFilter[] = [];
  ageTags: GymFilter[] = [];

  @Input() filter: GymAgendaFilter = {
    gym: null,
    ages: [],
    activities: [],
  };

  @Output() filterChanges = new EventEmitter<boolean>();

  allGyms: Gym[];
  allAges: GymFilter[] = [];
  config: FullGymSearchConfig;
  brand: Brand;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: ModalService,
    private readonly brandService: BrandService,
  ) { }

  ngOnInit() {
    this.brand = this.brandService.getBrand();
    this.config = this.route.snapshot.data.config;
    this.allGyms = this.route.snapshot.data.gyms;

    this.activityGroups = this.route.snapshot.data.groups || [];
    if (!this.allActivities) {
      this.allActivities = this.route.snapshot.data.activities;
    }

    this.activityGroups = this.filterGroupsByActivities(this.allActivities, this.activityGroups);
    this.activityGroups.forEach(v => v.activityGroup = { id: -1 });

    this.allAges = this.route.snapshot.data.ages.map((age: AgendaAgeRange) => ({
        id: age.id,
        name: age.name,
        description: FormatUtils.ageRangeToDescription(age.minimumAge, age.maximumAge),
      } as GymFilter),
    );

    const queryParamMap = this.route.snapshot.queryParamMap;
    const activities = atob(queryParamMap.get(AppConstants.PARAM_AGENDA_ACTIVITIES)) || '';
    activities.split(',').map(a => +a).forEach(activityId => {
      const activity = this.allActivities.find(a => a.id === activityId);
      if (activity) {
        activity.selected = true;
        this.filter.activities.push(activity as GymFilter);
      }
    });
    this.updateActivityTags();

    const ages = atob(queryParamMap.get(AppConstants.PARAM_AGENDA_AGE_RANGES)) || '';
    ages.split(',').map(a => +a).forEach(ageId => {
      const age = this.allAges.find(a => a.id === ageId);
      if (age) {
        age.selected = true;
        this.filter.ages.push(age);
      }
    });
  }

  showGymSelectModal() {
    this.modalService.show({
      type: ModalType.gymSelect,
      title: 'Selecione academia',
      message: '',
      confirmCallback: (data: Gym) => {
        if (data !== this.gym) {
          this.clearFilter(false);
          this.gym = data;
          this.filterChanged();
        }
      },
      gyms: this.allGyms,
      selected: this.gym ? this.gym.id : null,
    } as ModalGymSelectObject);
  }

  showAgeSelectModal() {
    this.modalService.show({
      type: ModalType.gymAge,
      title: 'Faixa etária',
      message: '',
      confirmCallback: (data: GymFilter[]) => {
        this.hasModification(this.filter.ages, data);
        this.filter.ages = data;
      },
      ages: this.allAges,
    } as ModalAgesObject);
  }

  private filterGymActivitiesByGym(gym: Gym, allActivities: GymActivity[]): GymActivity[] {
    if (!this.filterGymActivitiesByGymCache[gym.id]) {
      const activities = gym.activeActivities ? gym.activeActivities : gym.activities;
      if (!activities) { return []; }
      const ids = activities.map(a => a.id);
      const result = allActivities.filter(a => ids.includes(a.id));
      this.filterGymActivitiesByGymCache[gym.id] = result;
    }
    return this.filterGymActivitiesByGymCache[gym.id];
  }

  private filterGroupsByActivities(activities: GymActivity[], allGroups: GymFilter[]): GymFilter[] {
    return allGroups.filter(group =>
      activities.some(act => act.activityGroup.id === group.id),
    );
  }

  showActivitiesSelectModal() {
    const activities = this.filterGymActivitiesByGym(this.gym, this.allActivities);
    const groups = this.filterGroupsByActivities(activities, this.activityGroups);
    this.modalService.show({
      type: ModalType.gymActivity,
      title: this.config.activitiesModal.title,
      message: '',
      confirmCallback: (data: GymFilter[]) => {
        this.hasModification(this.filter.activities, data);
        this.filter.activities = data;
        this.updateActivityTags();
      },
      activities,
      groups,
      config: this.config.activitiesModal,
    } as ModalGymActivityObject);
  }

  removeFilter(list: GymFilter[], item: GymFilter) {
    item.selected = false;
    ArrayUtil.removeItem(list, item);
    if (list === this.filter.activities) {
      ArrayUtil.removeItem(this.activityTags, item);
      if (item.activityGroup.id < 0) {
        list.filter(a => a.activityGroup.id === item.id).forEach(a => this.removeFilter(list, a));
      }
    }

    this.filterChanged();
  }

  updateActivityTags() {
    this.activityTags = [];
    for (const group of this.activityGroups) {
      this.activityTags.push(...this.groupTagsWhenAllChecked(group.id));
    }
  }

  groupTagsWhenAllChecked(group: number): GymFilter[] {
    const listActivities = this.filter.activities.filter(a => a.activityGroup.id === group);
    if (listActivities.length &&
      listActivities.length === this.allActivities.filter(a => a.activityGroup.id === group).length) {
      return [this.activityGroups.find(item => item.id === group)];
    } else {
      return listActivities;
    }
  }

  doSearch() {
    if (this.gym) {
      this.router.navigate(
        ['agenda', this.gym.slug],
        { queryParams: FormatUtils.encodeAgendaFilterToParamObject(this.filter) },
      );
    } else {
      this.showGymSelectModal();
    }
  }

  clearFilter(clearAges = true) {
    this.filter.activities.forEach(activity => activity.selected = false);
    if (clearAges) { this.filter.ages.forEach(age => age.selected = false); }

    this.activityTags = [];
    this.filter.activities = [];
    if (clearAges) { this.filter.ages = []; }

    this.filterChanged();
  }

  private hasModification(arr1: any[], arr2: any[]) {
    if ((arr1.filter(a => arr2.includes(a)).length !== arr1.length) || arr1.length < arr2.length) {
      this.filterChanged();
    }
  }

  filterChanged(isChanged: boolean = true) {
    this.filterChanges.emit(isChanged);
  }
}
