<app-site-header class="d-print-none"></app-site-header>

<router-outlet></router-outlet>

<app-site-footer class="d-print-none"></app-site-footer>

<!-- <app-faqbot class="d-print-none"></app-faqbot> -->

<app-modal-agenda></app-modal-agenda>
<app-modal-alert></app-modal-alert>
<app-modal-confirm></app-modal-confirm>

<app-loading [visible]="loading"></app-loading>

<app-debug></app-debug>
