import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParameterCodec,
  HttpParams,
  HttpRequest,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { HttpParamsOptions } from '@angular/common/http/src/params';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AlertMessage, MessageMap } from '@models';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthService } from '@services/auth.service';
import { LoadingService } from '@services/loading.service';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints, blog, BlogEndpoints, sur, SurEndpoints } from '@utils/app-endpoints';
import { JSONUtil } from '@utils/json-util';
import { Observable, ObservableInput, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retry, retryWhen } from 'rxjs/operators';

class PlainTextEncoder implements HttpParameterCodec {
  encodeKey = (key: string): string => key;
  encodeValue = (value: string): string => value;

  decodeKey = (key: string): string => key;
  decodeValue = (value: string): string => value;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  static readonly ignoreStatusWhenRetry = [
    AppConstants.HTTP_OK,
    AppConstants.HTTP_BAD_REQUEST,
    AppConstants.HTTP_SERVER_ERROR,
  ];

  static readonly ignoreReasonsWhenRetry = [
    AppConstants.LEGACY_ERROR.INVALID_LOGIN_OR_PASSWORD,
    AppConstants.LEGACY_ERROR.EXISTING_USER,
  ];

  static readonly maxTimeout = 30000;

  static readonly ignoreUrl = [
    back(BackEndpoints.AppToken),
    sur(SurEndpoints.AppToken),
    blog(BlogEndpoints.ApiUrl),
  ];

  static readonly interceptUrl = [
    back(BackEndpoints.Root),
    sur(SurEndpoints.Root),
  ];

  paramNotEncode = ['tokenreset', 'codigoacesso', 'tokenConfirmacao', 'confirmacao'];

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
  ) { }

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!window.navigator.onLine) {
      this.loadingService.stopLoading();
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));

      return throwError(MessageMap.SEM_CONEXAO_INTERNET);
    }

    const url = originalRequest.url;
    if (!AuthInterceptor.interceptUrl.some(value => url.startsWith(value))
        || AuthInterceptor.ignoreUrl.some(value => url.startsWith(value))) {
      return this.ignoreRequest(originalRequest, next);
    }
    return this.requestWithToken(next, originalRequest);
  }

  requestWithToken(next: HttpHandler, request: HttpRequest<any>) {
    return this.authService.getAppToken(this.getTokenKey(request.url))
      .pipe(
        mergeMap((token: string) => {
          const authRequest = this.cloneRequestWithEncodedParams(request, token);

          return next.handle(authRequest)
            .pipe(
              // Catch Error will invalidate token and try again, if necessary
              catchError(err => this.handleError(err, next, request)),
              retryWhen(attempt => attempt.pipe(mergeMap(this.shouldRetry))),
              catchError(err => this.handleGenericError(err)),
            );
        }),
      );
  }

  cloneRequestWithEncodedParams(request: HttpRequest<any>, token: string) {
    const oldparams = request.params;
    const oldparamsEncoder = new HttpUrlEncodingCodec();
    const plainTextEncoder = new PlainTextEncoder();
    let newparams = new HttpParams({ encoder: plainTextEncoder } as HttpParamsOptions);

    oldparams.keys().forEach(key => {
      const eKey = oldparamsEncoder.encodeKey(key);
      oldparams.getAll(key)
               .map(value => {

                  if (this.paramNotEncode.includes(key)) {
                    return encodeURIComponent(value);
                  }

                  return oldparamsEncoder.encodeValue(value);
                })
               .forEach(eValue => { newparams = newparams.append(eKey, eValue); });
    });

    const tokenusuario = this.authService.getUserToken();

    const setParams = tokenusuario ? { token, tokenusuario } : { token };

    return request.clone({
      params: newparams,
      setParams,
      setHeaders: { 'X-Authorization': token },
    });
  }

  getTokenKey(url: string) {
    return this.isToOldCanais(url) ? AppConstants.STOR_APP_TOKEN : AppConstants.STOR_SUR_TOKEN;
  }

  isToOldCanais(url: string): boolean {
    return url.startsWith(back(BackEndpoints.Root));
  }

  shouldRetry(error: any, currentRetry: number) {
    const statusText = error.statusText || '';
    return (currentRetry > 1
        || (error.status >= 400 && error.status < 600)
        || AuthInterceptor.ignoreStatusWhenRetry.includes(error.status)
        || AuthInterceptor.ignoreReasonsWhenRetry.some(reason => statusText.startsWith(reason)))
      ? throwError(error)
      : timer(500);
  }

  handleError(err: HttpErrorResponse, next: HttpHandler, request: HttpRequest<any>): Observable<any> | ObservableInput<any> {
    if (isDevMode) {
      console.warn(`Caught error ${err.status} from ${request.url}`, err);
    }

    const url = request.url || '';

    if (url.startsWith(back(BackEndpoints.Root))) {
      return this.handleErrorCanais(err, next, request);
    } else if (url.startsWith(sur(SurEndpoints.Root))) {
      return this.handleErrorSur(err, next, request);
    }

    return throwError(err);
  }

  handleErrorCanais(err: HttpErrorResponse, next: HttpHandler, request: HttpRequest<any>): Observable<any> | ObservableInput<any> {
    const reason = err.statusText || 'Undefinied';

    if (err.status === AppConstants.HTTP_FORBIDDEN) {
      if (reason.startsWith('Token')) {
        // Caso falhe por causa de Token de aplicação, limpa o cache e tenta de novo
        this.authService.clearAppToken(AppConstants.STOR_APP_TOKEN);
        return this.requestWithToken(next, request);
      } else if (reason.startsWith(AppConstants.LEGACY_ERROR.INVALID_USER_TOKEN)) {
        // Token de Usuário inválido
        this.alertMessageService.showToastr(AlertMessage.warning(MessageMap.SESSAO_EXPIRADA));
        this.authService.logout(this.router.url);
        return [];
      } else if (reason.startsWith(AppConstants.LEGACY_ERROR.INVALID_LOGIN_OR_PASSWORD)) {
        throwError({ message: reason });
      }
    }

    return throwError(err);
  }

  handleErrorSur(err: HttpErrorResponse, next: HttpHandler, request: HttpRequest<any>): Observable<any> | ObservableInput<any> {

    if (err.status === AppConstants.HTTP_UNAUTHORIZED && JSONUtil.get(err, 'error.code') &&
        JSONUtil.get(err, 'error.code').startsWith(AppConstants.SUR_ERROR.INVALID_APP_TOKEN)) {
      this.authService.clearAppToken(AppConstants.STOR_SUR_TOKEN);
      return this.requestWithToken(next, request);
    }

    return throwError(err);
  }

  handleGenericError(err: HttpErrorResponse): ObservableInput<any> {
    this.loadingService.stopLoading();
    return throwError(err);
  }

  ignoreRequest(originalRequest: HttpRequest<any>, next: HttpHandler) {
    return next.handle(originalRequest)
      .pipe(
        retry(2),
        catchError(err => {
          console.log('Ignored request error', err);
          if (err.status === AppConstants.HTTP_UNKNOWN) {
            console.warn(`Is ${originalRequest.url} offline?`);
            throw [err];
          }
          if (err.status >= 400 && err.status < 500) {
            // Client errors
            throw err;
          }
          return [];
        }),
      );
  }

}
