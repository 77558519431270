import { BreakpointsService, ScreenSize } from '@services/breakpoints.service';
import { GymSearchRealService } from '@services/gym-search-real.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';

import { Gym } from '@models';

@Component({
  selector: 'app-gym-search-input',
  templateUrl: './gym-search-input.component.html',
  styleUrls: ['./gym-search-input.component.scss'],
})
export class GymSearchInputComponent implements OnInit, OnDestroy {
  @ViewChild('search') seachInput: ElementRef;

  @Input() inSearchRoute = true;
  @Input() query = '';
  @Output() queryChange = new EventEmitter<string>();
  @Output() searchClick = new EventEmitter<string>();

  private readonly search = new BehaviorSubject<string>('');
  private searchSubs: Subscription;
  private autocompleteSubs: Subscription;
  private debounceTime = 800;
  suggestions: Gym[];
  focused = false;
  loading = false;

  constructor(
    private readonly gymSearchService: GymSearchRealService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly breakpointsService: BreakpointsService,
  ) { }

  changes(size: number) {
    let placeholder = '';

    if (size >= ScreenSize.MD) {
      this.debounceTime = 500;
      placeholder = 'Academia, CEP, bairro ou cidade';
    } else {
      this.debounceTime = 800;
      placeholder = 'Academia ou local';
    }

    if (this.seachInput) {
      this.seachInput.nativeElement.placeholder = placeholder;
    }
  }

  ngOnInit() {
    this.breakpointsService.emitter.subscribe(size => {
      this.changes(size);
    });

    // Pega o parâmetro da Query String
    this.query = this.route.snapshot.queryParamMap.get('q') || '';
    this.queryChange.next(this.query);

    this.searchSubs = this.search.pipe(
      tap(() => {
        // Assim que o input mudar, mostra o Loading e cancela qualquer
        // outra chamada para o autocomplete que estiver acontecendo
        this.loading = true;
        if (this.autocompleteSubs) { this.autocompleteSubs.unsubscribe(); }
      }),
      debounce(() => timer(this.debounceTime)),
    ).subscribe(value => {
      // Quando o input se estabilizar, então chama o serviço
      this.autocompleteSubs = this.gymSearchService.searchGymsAutocomplete(value.trim())
        .subscribe(values => {
          // Quando o serviço retornar, esconde o Loading e mostra os valores
          this.suggestions = values;
          this.loading = false;
        });
    });
  }

  ngOnDestroy() {
    this.searchSubs.unsubscribe();
    if (this.autocompleteSubs) { this.autocompleteSubs.unsubscribe(); }
  }

  updateQuery(value: string) {
    if (this.search.getValue() !== value) {
      this.focused = true;
      this.search.next(value);
    }
  }

  blur() {
    setTimeout(() => this.focused = false, 500);
  }

  confirmClicked() {
    this.focused = false;
    this.queryChange.next(this.query);
    this.searchClick.emit(this.query);
    let queryParams: Params = {};

    if (this.inSearchRoute) {
      // Joga todos os parâmetros para a Query String
      queryParams = Object.assign({}, this.route.snapshot.queryParams);
    }

    queryParams.q = this.query || null;
    this.router.navigate(['/academias'], { queryParams });
  }

  selectGym(slug: string) {
    this.router.navigate(['/academia', slug]);
  }
}
