import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MyPurchasesService } from '@services';
import { ResolveResult } from './type-resolve-bundle';
import { PreSalePurchase } from '@models';

@Injectable()
export class PreSalePurchaseResolveGuard implements Resolve<PreSalePurchase[]> {

  constructor(
    private readonly myPurchasesService: MyPurchasesService,
  ) {}

  resolve(): ResolveResult<PreSalePurchase[]> {
    return this.myPurchasesService.getPreSaleInfo();
  }

}
