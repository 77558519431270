import gql from 'graphql-tag';
import { postPerPage } from '@models';

export class AppGraphql {

  public static readonly queryListActiveActivities = gql`
    query {
      listActiveActivities {
        id
        name
        activityGroup {
          id,
          text: name
        }
      }
    }
  `;

  public static readonly queryGetActivity = gql`
    query($id: Long!) {
      activity(id: $id) {
        id
        name
        description
      }
    }
  `;

  public static readonly queryListActiveActivityGroups = gql`
    query {
      listActiveActivityGroups {
        id
        name
        icon
      }
    }
  `;

  public static readonly queryListDoubtAreasByChannelName = gql`
    query {
      listDoubtAreasByChannelName(name: "Portal"){
        id
        displayOrder
        name: title
        sectionsDoubtArea {
          id
          displayOrder
          title
          doubts {
            id
            displayOrder
            question
            answer
          }
        }
      }
    }
  `;

  public static readonly queryListGymUnitsThatAreNotHolding = gql`
  query {
    listGymUnitsThatAreNotHolding {
      id
      name
      slug
      tag
    }
  }`;

  public static readonly queryListGymUnitsThatAreNotHoldingWithActivities = gql`
  query {
    listGymUnitsThatAreNotHolding {
      id
      name
      slug
      tag
      activeActivities { id }
    }
  }`;

  public static readonly listGymUnitsByStateCityAndNeighborhood = gql`
  query($stateInitials: String!, $city: String, $neighborhood: String) {
    listGymUnitsByStateCityAndNeighborhood(stateInitialsSlug: $stateInitials, cityNameSlug: $city, addressNeighborhoodSlug: $neighborhood) {
      address {
        adjunct
        city {
          id
          name
          state {
            country {
              id
              initials
              name
            }
            id
            initials
            name
          }
        }
        latitude
        longitude
        neighborhood
        number
        publicPlace {
          acronym
          id
          idiom
          name
          visible
        }
        street
        zipCode
      }
      businessName
      cnpj
      conveniences {
        id
        name
        icon
        description
      }
      gymUnitServices: activeGymUnitServices {
        freeCost
        main
        service{id}
      }
      id
      name
      phones {
        ddd
        id
        main
        number
      }
      slug
      tag
      photos {
        description
        portalDescription
        url
        objectType
        displayOrder
      }
      showChannels
      activities {id}
      gymUnitPeriodicities {subscriptionFee}
      workingPeriods {
        dayOfTheWeek
        timeOfDay
      }
    }
  }`;

  public static readonly queryMaritalStatusList = gql`
    query {
      listMaritalStatusesRedis {
        id
        text: name
      }
    }
  `;

  public static readonly queryServiceList = gql`
    query {
      listServicesRedis {
        id
        name
        icon
        description
      }
    }
  `;

  public static readonly queryState = gql`
    query ($stateId: Long!) {
      state(id: $stateId) {
        id
        name
        cities {
          id
          name
        }
      }
    }
  `;

  public static readonly queryCityState = gql`
    query($id: Long!) {
      city (id: $id) {
        state {
          id
          name
        }
      }
    }
  `;

  public static readonly queryStateList = gql`
    query {
      listStatesRedis {
        id
        name
        initials
      }
    }
  `;

  public static readonly mutationFutureFranchiseeCreate = gql`
    mutation FranchiseeCreate($data: FutureFranchiseeCreate) {
      futureFranchiseeCreate(futureFranchisee: $data) {
        id
        errors {
          field
          message
        }

        name
        email
        address {
          city {
            id
            name
            state {
              id
              name
            }
          }
        }
      }
    }
  `;

  public static readonly queryPressAreaList = gql`
    query {
      pressAreaList {
        id
        name
      }
    }
  `;

  public static readonly queryListPressPostsByPressAreaName = gql`
    query ($area: String!, $offset: Int!) {
      listPressPostsByPressAreaName(pressAreaName: $area, max:${postPerPage}, offset: $offset) {
        results {
          title
          text
          postDate
          documentUrl
          documentSize
        }
      }
    }
  `;

  public static readonly queryListPressPostsTotalCount = gql`
    query ($area: String!, $offset: Int!) {
      listPressPostsByPressAreaName(pressAreaName: $area, max:5, offset: $offset) {
        totalCount
      }
    }
  `;

  public static readonly queryGetPortalConfiguration = gql`
    query ($tag: String!) {
      getPortalConfiguration(tag: $tag) {
        configValue
      }
    }
  `;

  public static readonly queryListActivePartners = gql`
    query{
      listActivePartners(channelName: "Portal"){
        id
        name
        icon
        link
        active
        displayOrder
      }
    }
  `;

  public static queryListActivePlansWithPeriodicityByGymUnit = gql`query ($gym: Long!) {
    listActivePlansWithPeriodicityByGymUnit(id: $gym) {
      id
      shortName
      longName
      slug
      gymUnitGroupPlans {
        group {
          name
        }
      }
      gymUnitPeriodicities {
        subscriptionFee
        planValue
        periodicity {
          name
          days
          months
          slug
        }
      }
    }
  }`;

  public static readonly queryGetGymUnitBySlug = gql`
    query($slug: String!) {
      getGymUnitBySlug(slug: $slug) {
        id
        name
        businessName
        cnpj
        slug
        preSale
        isGymUnit
        gymUnitSocialMedias {
          url
          display
          socialMedia {
            id
            name
            urlImage
          }
        }
        gymUnitDestinations {
          id
          name
        }
        address {
          id
          street
          number
          adjunct
          neighborhood
          zipCode
          latitude
          longitude
          publicPlace {
            acronym
            id
            idiom
            name
            visible
          }
          city {
            id
            name
            state {
              id
              name
              initials
              country {
                id
                name
              }
            }
          }
        }
        phones {
          id
          ddd
          number
          main
        }
        gymUnitServices: activeGymUnitServices {
          main
          freeCost
          service {
            id
            name
            icon
            description
          }
        }
        conveniences {
          id
          name
          icon
          description
          url
        }
        workingPeriods {
          id
          dayOfTheWeek
          timeOfDay
        }
        photos {
          id
          description
          url
          portalDescription
          objectType
        }
      }

      listActiveActivitiesByGymUnit(slug: $slug) {
        id
        name
        activityGroup {
          id
        }
      }
    }
  `;

  public static readonly queryListActiveAgeRanges = gql`
    query {
      listActiveAgeRanges {
        id
        name
        minimumAge
        maximumAge
      }
    }
  `;

  public static readonly queryListSchedules = gql`
    query($gym: Long!, $activities: [Long!], $ages: [Long!]) {
      listSchedules(gymUnitId: $gym, activities: $activities, ageRanges: $ages) {
        id
        dayOfTheWeek
        itemGridTitle
        itemGridId
        teacherName
        itemGridPlace
        gridActivityId
        activityInitialTimeFormatted
        activityInitialTimeHour
        activityInitialTimeMinute
      }
      # REMOVE_INTENSITY
      #listIntensities(activities: $activities) {
      #  activityId
      #  weekDay
      #  intensityColorCode
      #  hasColorCode
      #}
    }
  `;

  public static readonly querySchedule = gql`
  # REMOVE_INTENSITY
  # query ($id: Long!, $activityId: Long!, $weekDay: String!) {

  query ($id: Long!) {
    schedule(id: $id) {
      id
      itemGridId
      itemGridTitle
      teacherName
      itemGridPlace
      audiences {
        name
        minimumAge
        maximumAge
      }
      targetAudienceLevel
      duration
      activityInitialTimeFormatted
    }
    # REMOVE_INTENSITY
    #getIntensityByActivityIdAndWeekDay(activityId: $activityId, weekDay: $weekDay) {
    #  activityId
    #  weekDay
    #  intensityColorCode
    #  hasColorCode
    #  domain13Description
    #  domain14Description
    #  domain15Description
    #  domain16Description
    #}
  }
  `;

  public static readonly queryListSchedulesByItemGridId = gql`
  query ($itemGridId: Long!, $activityId: Long!, $weekDay: String!) {
    listSchedulesByItemGridId(itemGridId: $itemGridId) {
      id
      itemGridTitle
      teacherName
      itemGridId
      itemGridPlace
      audiences {
        name
        minimumAge
        maximumAge
      }
      targetAudienceLevel
      duration
      activityInitialTimeFormatted
    }
    getIntensityByActivityIdAndWeekDay(activityId: $activityId, weekDay: $weekDay) {
      activityId
      weekDay
      intensityColorCode
      hasColorCode
      domain13Description
      domain14Description
      domain15Description
      domain16Description
    }
  }
  `;

  public static readonly queryGetAddressByZipCode = gql`
    query($zip: String!) {
      getAddressByZipCode(zipCode: $zip) {
        street
        neighborhood
        city {
          id
          state {
            id
          }
        }
      }
    }
  `;

  public static queryGetGymUnitStateCityAndNeighborhood = gql`
    query($state: String!, $city: String, $neighborhood: String) {
      getGymUnitStateCityAndNeighborhood(stateInitialsSlug: $state, cityNameSlug: $city, addressNeighborhoodSlug: $neighborhood) {
        id
        stateName
        cityName
        addressNeighborhood
      }
    }
  `;

  public static readonly queryListCollaboratorDepartment = gql`
    query {
      listCollaboratorQuantitiesRedis {
        id
        numberRange
      }
      listDepartmentsRedis {
        id
        name
      }
    }
  `;

  public static queryListCombinatedGymUnits = gql`
    query($gymUnitId: Long!) {
      listCombinatedGymUnits(gymUnitId: $gymUnitId) {
        id
        name
      }
    }
    `;

  public static queryGetPlanFromView = gql`
    query ($gymUnitSlug: String!, $planSlug: String!, $periodicitySlug: String!) {
      getGymUnitBySlug(slug: $gymUnitSlug) {
        id
        name
        cnpj
        businessName
        preSale
        activePromotion {
          id
          thumbnailText
          purchaseTitle
          purchaseText
          initialDate
          finalDate
        }
      }
      getPlanFromView(gymUnitSlug: $gymUnitSlug, planSlug: $planSlug, periodicitySlug: $periodicitySlug) {
        planShortName
        periodicityName

        gymUnitId
        gymUnitGroupPlanId
        planId
        gymUnitPeriodicityId

        gymUnitPeriodicityPlanValue
        gymUnitPeriodicitySubscriptionFee
        gymUnitPeriodicitySubscriptionFeeText
        gymUnitPeriodicityShowChannelSubscriptionFee
        gymUnitPeriodicityRegistration
        gymUnitPeriodicityRegistrationText
        gymUnitPeriodicityShowChannelRegistration
        periodicityMonths
        gymUnitGroupPlanPortalName

        gymUnitPeriodicityCouponId
      }
      getSubscriptionTermBySlug(gymUnitSlug: $gymUnitSlug) {
        url
        subscriptionDate
      }
    }`;

  public static readonly queryListGymUnitsNotHoldingWithState = gql`
    query {
      listGymUnitsThatAreNotHolding {
        id
        name
        slug
        tag
        address {
          id
          city {
            id
            state {
              id
              name
              initials
            }
          }
        }
      }
    }
  `;

  public static queryListPlansFromView = gql`
    query($gymUnitId: Long!) {
      listPlansFromView(gymUnitId: $gymUnitId) {
        gymUnitId
        gymUnitSlug
        gymUnitPreSale
        gymUnitPeriodicityId
        gymUnitPeriodicityCouponId
        gymUnitPeriodicityPlanValue
        gymUnitPeriodicityPopular
        gymUnitPeriodicitySubscriptionFee
        gymUnitPeriodicitySubscriptionFeeText
        gymUnitPeriodicityShowChannelSubscriptionFee
        gymUnitPeriodicityRegistration
        gymUnitPeriodicityRegistrationText
        gymUnitPeriodicityShowChannelRegistration
        planSuperiorId
        planId
        planShortName
        planLongName
        planSlug
        planDebitPlanId
        planDebitedMonthly
        planCircuit
        planShowChannels
        planGymUnitExclusive
        planForSale
        periodicityName
        periodicitySlug
        periodicityMonths
        periodicityDays
        gymUnitGroupPlanId
        gymUnitGroupPlanShortDescription
        gymUnitGroupPlanLongDescription
        gymUnitGroupPlanOrderInSite
        gymUnitGroupPlanShowPlan
        gymUnitGroupPlanPortalName
      }
    }
  `;

  public static readonly queryListActivitiesByGymUnitAndPlan = gql`
    query($gymUnitId: Long!, $planId: Long!) {
      listActivitiesByGymUnitAndPlan(gymUnitId: $gymUnitId, planId: $planId) {
        id
        name
        activityGroup {
          id
          name
          icon
        }
      }
    }
  `;

  public static readonly queryCoupon = gql`
    query($id: Long!) {
      coupon(id: $id) {
        name
        couponKey
        description
      }
    }
  `;

  public static readonly queryListPlanViewMinAndMaxTranchesValues = gql`
    query {
      listPlanViewMinAndMaxTranchesValues {
        gymUnitPeriodicityTrancheValue
      }
    }
  `;

  public static queryGetGymUnitSlug = gql`
    query($gymUnitId: Long!) {
      listPlansFromView(gymUnitId: $gymUnitId) {
        gymUnitSlug
      }
    }
  `;

}
