import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gym, GymResponse, GymSearchQuery, GymSearchResponse } from '@models';
import { FusePipe } from '@pipes';
import { GymSearchAbstractService } from './gym-search-abstract.service';
import { GymService } from './gym.service';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class GymSearchRealService extends GymSearchAbstractService {

  constructor(
    protected readonly http: HttpClient,
    protected readonly apollo: Apollo,
  ) {
    super(apollo);
  }

  getGymNames(): Observable<any[]> {
    return this._getGymNames();
  }

  searchBy(filterParam: GymSearchQuery, max: number, page: number): Observable<GymResponse> {
    delete filterParam.stateInitials;
    delete filterParam.city;
    delete filterParam.neighborhood;

    filterParam['max'] = max;
    filterParam['offset'] = page * max;

    const filters: { [key: string]: string } = {};
    Object.entries(filterParam).forEach(v => filters[v[0]] = v[1].toString());

    return this.http.get<GymSearchResponse>(sur(SurEndpoints.UrlGymUnit), { params: filters })
    .pipe(
      map(value => {
        value.results.forEach(r => GymService.preFormatGym(r));
        return {
          results: value.results,
          more: value.totalCount > (page * max) + max,
          total: value.totalCount,
          resultType: value.resultType,
          orderType: value.orderType,
        } as GymResponse;
      }),
    );
  }

  searchGymsAutocomplete(text: string): Observable<Gym[]> {
    const filters = {
      search: text,
      max: '8',
      simple: 'true',
    };

    return this.http.get<GymSearchResponse>(sur(SurEndpoints.UrlGymUnit), { params: filters }).pipe(
      map(value => value.results),
    );
  }

  searchGymNames(text: string): Observable<Gym[]> {
    return this.getGymNames()
    .pipe(
      map(value => new FusePipe().transform(value, text, { keys: ['name'] })),
    );
  }
}
