export class AppConstants {
  public static HTTP_UNKNOWN = 0;
  public static HTTP_OK = 200;
  public static HTTP_BAD_REQUEST = 400;
  public static HTTP_UNAUTHORIZED = 401;
  public static HTTP_FORBIDDEN = 403;
  public static HTTP_NOT_ACCEPTABLE = 406;
  public static HTTP_SERVER_ERROR = 500;

  public static LOCALE = 'pt-BR';

  public static STOR_LAST_LOGIN = 'user-login';
  public static STOR_LOGGED = 'user-is-logged';
  public static STOR_USER = 'user-data';
  public static STOR_APP_TOKEN = 'application-token';
  public static STOR_SUR_TOKEN = 'ultra-application-token';
  public static STOR_USER_TOKEN = 'user-token';
  public static STOR_RESET_USER_ACESS_TOKEN = 'reset-user-access-token';
  public static STOR_AGENDA_GYM = 'agenda-gym-selected';

  public static SUR_ERROR = {
    INVALID_APP_TOKEN: 'br.com.bodytech.portal.token.unauthorized',
  };

  public static LEGACY_ERROR = {
    INVALID_USER_TOKEN: '4030002',
    INVALID_LOGIN_OR_PASSWORD: '4030003',
    EXISTING_USER: '4120012',
    BLOCKED_USER: '4030004',
    BLOCKED_USER_RESET: '4120015',
    PLAN_NOT_AVAIABLE_TO_AGE: '4120022',
  };

  public static PARAM_SEARCHGYM_ACTIVITIES = 'a';
  public static PARAM_SEARCHGYM_SERVICES = 's';
  public static PARAM_SEARCHGYM_PRICES = 'p';

  public static PARAM_AGENDA_ACTIVITIES = 'a';
  public static PARAM_AGENDA_AGE_RANGES = 'i';

  public static readonly googleMapUrl = 'https://www.google.com.br/maps/dir/My+Location/';

  public static readonly Mask = {
    date: (raw: string) =>
      raw.startsWith('3')
      ? ['3', /[0-1]/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
      : [/[0-2]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    zip: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    doc: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    phone: (raw: string) => raw.replace(/[\(\)\- ]/g, '').length < 11
        ? ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        : ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    expire: (raw: string) => {
      const pattern = [/[0-1]/, /[1-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
      const rawArray = Array.from(raw);

      if (rawArray[0] && rawArray[0] === '1') {
        pattern[1] = /[0-2]/;
      }

      if (rawArray[3] && rawArray[3] === '1') {
        pattern[4] = /[9]/;
      }

      return pattern;
    },
    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    dateFull: (raw: string) => {
      const pattern = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
      const rawArray = Array.from(raw);

      if (rawArray[0] && rawArray[0] === '3') {
        pattern[1] = /[0-1]/;
      } else if (rawArray[0] && rawArray[0] === '0') {
        pattern[1] = /[1-9]/;
      }

      if (rawArray[3] && rawArray[3] === '1') {
        pattern[4] = /[0-2]/;
      } else if (rawArray[3] && rawArray[3] === '0') {
        pattern[4] = /[1-9]/;
      }

      if (rawArray[6] && rawArray[6] === '1') {
        pattern[7] = /[9]/;
      }

      return pattern;
    },
  };
}
