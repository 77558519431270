import { Injectable } from '@angular/core';
import { DoubtArea } from '@models';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FaqPageService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getFAQ(): Observable<DoubtArea[]> {
    return this.apollo.query<any>({
      query: AppGraphql.queryListDoubtAreasByChannelName,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<DoubtArea[]>('listDoubtAreasByChannelName')),
    );
  }

}
