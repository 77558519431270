import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from '@services/header.service';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss'],
})
export class SuccessPageComponent implements OnInit {

  @Input() pageTitle = '';
  @Input() title = '';
  @Input() message = '';
  @Input() buttonName = '';
  @Input() redirectTo = '';

  @ViewChild('header') headerComponent: TemplateRef<any>;

  constructor(
    private readonly headerService: HeaderService,
  ) { }

  ngOnInit() {
    if (this.pageTitle) {
      this.headerService.template.next(this.headerComponent);
    }
  }

}
