import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GymFilter } from '@models';
import { GymService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class GymGroupActivitiesResolveGuard implements Resolve<GymFilter[]> {

  constructor(
    private readonly gymService: GymService,
  ) {}

  resolve(): ResolveResult<GymFilter[]> {
    return this.gymService.getActivityGroupTags();
  }
}
