import * as i0 from "@angular/core";
import * as i1 from "./site-header/site-header.component.ngfactory";
import * as i2 from "./site-header/site-header.component";
import * as i3 from "./shared/services/header.service";
import * as i4 from "./shared/services/breakpoints.service";
import * as i5 from "./shared/services/portal-config.service";
import * as i6 from "@angular/router";
import * as i7 from "./site-footer/site-footer.component.ngfactory";
import * as i8 from "./site-footer/site-footer.component";
import * as i9 from "./shared/services/partners.service";
import * as i10 from "./shared/services/brand.service";
import * as i11 from "./shared/components/modal/modal-agenda/modal-agenda.component.ngfactory";
import * as i12 from "./shared/components/modal/modal-agenda/modal-agenda.component";
import * as i13 from "./shared/services/modal.service";
import * as i14 from "./shared/components/modal/modal-alert/modal-alert.component.ngfactory";
import * as i15 from "./shared/components/modal/modal-alert/modal-alert.component";
import * as i16 from "./shared/components/modal/modal-confirm/modal-confirm.component.ngfactory";
import * as i17 from "./shared/components/modal/modal-confirm/modal-confirm.component";
import * as i18 from "./shared/components/loading/loading.component.ngfactory";
import * as i19 from "./shared/components/loading/loading.component";
import * as i20 from "./shared/services/loading.service";
import * as i21 from "./debug/debug.component.ngfactory";
import * as i22 from "./debug/debug.component";
import * as i23 from "./app.component";
import * as i24 from "./shared/services/seo.service";
import * as i25 from "./shared/services/window-context.service";
import * as i26 from "angulartics2/ga";
import * as i27 from "angulartics2/gtm";
import * as i28 from "./shared/services/analytics.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-site-header", [["class", "d-print-none"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SiteHeaderComponent_0, i1.RenderType_SiteHeaderComponent)), i0.ɵdid(1, 245760, null, 0, i2.SiteHeaderComponent, [i3.HeaderService, i4.BreakpointsService, i5.PortalConfigService], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-site-footer", [["class", "d-print-none"]], null, null, null, i7.View_SiteFooterComponent_0, i7.RenderType_SiteFooterComponent)), i0.ɵdid(5, 114688, null, 0, i8.SiteFooterComponent, [i5.PortalConfigService, i9.PartnersService, i6.Router, i10.BrandService], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-modal-agenda", [], null, null, null, i11.View_ModalAgendaComponent_0, i11.RenderType_ModalAgendaComponent)), i0.ɵdid(7, 245760, null, 0, i12.ModalAgendaComponent, [i13.ModalService], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-modal-alert", [], null, null, null, i14.View_ModalAlertComponent_0, i14.RenderType_ModalAlertComponent)), i0.ɵdid(9, 245760, null, 0, i15.ModalAlertComponent, [i13.ModalService], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-modal-confirm", [], null, null, null, i16.View_ModalConfirmComponent_0, i16.RenderType_ModalConfirmComponent)), i0.ɵdid(11, 245760, null, 0, i17.ModalConfirmComponent, [i13.ModalService], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-loading", [], null, null, null, i18.View_LoadingComponent_0, i18.RenderType_LoadingComponent)), i0.ɵdid(13, 114688, null, 0, i19.LoadingComponent, [i20.LoadingService], { visible: [0, "visible"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-debug", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).keyTrigger($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_DebugComponent_0, i21.RenderType_DebugComponent)), i0.ɵdid(15, 49152, null, 0, i22.DebugComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); _ck(_v, 11, 0); var currVal_0 = _co.loading; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i23.AppComponent, [i6.Router, i20.LoadingService, i24.SEOService, i25.WindowContextService, i13.ModalService, i26.Angulartics2GoogleAnalytics, i27.Angulartics2GoogleTagManager, i28.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i23.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
