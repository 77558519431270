export class DateUtil {

  static newDateFromDateString(dateString: string): Date {
    return new Date(dateString + ' 12:00:00');
  }

  /* Converte string de data do formato brasileiro (DD/MM/YYYY) para o formato ISO (YYYY-MM-DDT00:00:00.000Z)
   * Checa apenas formato, não acusando erros em dias ou meses inválidos
   */
  static dateToISO(brDateString: string) {
    try {
      let numbers: any[] = brDateString.split('/');
      numbers = numbers.map(number => parseInt(number, 10));

      const date = new Date(numbers[2], numbers[1] - 1, numbers[0]);
      date.setUTCHours(0, 0, 0, 0);
      return date.toISOString();

    } catch {
      console.error('Data em formato incorreto');
    }
  }

  static sortDateArray(array: Date[]): Date[] {
    return array.sort((a, b) => a.getTime() - b.getTime());
  }

  static filterUniqueDateArray(array: Date[]): Date[] {
    return array.filter((day, index) => DateUtil.getFirstIndex(day, array) === index);
  }

  static getMinutesInDay(date: Date): number {
    return date.getHours() * 60 + date.getMinutes();
  }

  static getFirstIndex(date: Date, array: Date[]): number {
    return array.findIndex(d => d.toDateString() === date.toDateString());
  }

  static timeSince(date: Date): number {
    if (!date) {
      return Number.MAX_SAFE_INTEGER;
    }
    return new Date().getTime() - date.getTime();
  }

}
