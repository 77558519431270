// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const isProduction = false;
export const googlemapsapikey = 'AIzaSyCr3j9jp1yCk01hQA6iJY2MUwqLgziXISk';
export const environment = Object.freeze({
  production: false,
  homolog: true,
  brand: 'bt',
  reclameAquiUrl: 'https://raresponde.reclameaqui.com.br/faleconosco/index/?client=9714',
  bizutUrl: 'https://www.bizut.me/vagas/publicas/725',

  // tslint:disable:max-line-length
  seo: {
    DEFAULT_FULL_TITLE: 'Bodytech Company',
    DEFAULT_TITLE: 'Bodytech',
    DEFAULT_META_DESCRIPTION: 'Nas academias Bodytech você conta com os melhores profissionais e programas exclusivos de musculação e aulas coletivas para cuidar de sua saúde física e mental.',
  },

  sentry: {
    dsn: 'https://5e7a4ffd014240bb9695442931c12692@sentry.dev.bodytech.company/3',
    enabled: true,
    environment: 'dev',
    release: 'btportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  services: {
    baseUrl: 'https://bt-tst.tokenlab.com.br',
  },

  recaptchaKey: '6Le3inEUAAAAAM1dWWSm-mh9aYQEiSXbQJl1ulxT',

  wordpress: {
    baseUrl: 'https://blog.bodytech.com.br',
  },

  apollo: {
    baseUrl: 'https://tst.bodytech.company/api',
  },

  googleAnalytics: {
    trackingID: 'UA-XXXXXXXXX-X',
    tagManagerId: '',
  },

  s3buckets: {
    images: 'https://images.tst.bodytech.company',
    medias: 'https://medias.tst.bodytech.company',
  },

});
