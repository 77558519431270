/* jscpd:ignore-start */
import { LocationStrategy } from '@angular/common';
import { Attribute, Directive, ElementRef, HostBinding, HostListener,
    Input, OnChanges, OnDestroy, Renderer2, isDevMode } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, UrlTree } from '@angular/router';
import { QueryParamsHandling } from '@angular/router/src/config';
import { Subscription } from 'rxjs';

// Copy from https://github.com/angular/angular/blob/master/packages/router/src/directives/router_link.ts
// Changes:
// add isInternal and correctly redirecting when necessary
@Directive({selector: ':not(a)[appRouterLink]'})
export class RouterLinkDirective {
  @Input() queryParams: {[k: string]: any};
  @Input() fragment: string;
  @Input() queryParamsHandling: QueryParamsHandling;
  @Input() preserveFragment: boolean;
  @Input() skipLocationChange: boolean;
  @Input() replaceUrl: boolean;
  private commands: any[] = [];
  private preserve: boolean;
  private isInternal: boolean;

  constructor(
      private router: Router, private route: ActivatedRoute,
      @Attribute('tabindex') tabIndex: string, renderer: Renderer2, el: ElementRef) {
    if (tabIndex == null) {
      renderer.setAttribute(el.nativeElement, 'tabindex', '0');
    }
  }

  @Input()
  set appRouterLink(commands: any[]|string) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
      // starts with . or a single / (/ but not //)
      this.isInternal = this.commands[0].search(/(\.|\/[^\/])/) === 0;
    } else {
      this.commands = [];
    }
  }

  /**
   * @deprecated 4.0.0 use `queryParamsHandling` instead.
   */
  @Input()
  set preserveQueryParams(value: boolean) {
    if (isDevMode() && console as any && console.warn as any) {
      console.warn('preserveQueryParams is deprecated!, use queryParamsHandling instead.');
    }
    this.preserve = value;
  }

  @HostListener('click')
  onClick(): boolean {
    const extras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
    };
    if (this.isInternal) {
      this.router.navigateByUrl(this.urlTree, extras);
    } else {
      this.router.navigateByUrl(this.commands[0], extras);
    }
    return true;
  }

  get urlTree(): UrlTree {
    return this.router.createUrlTree(this.commands, {
      relativeTo: this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      preserveQueryParams: attrBoolValue(this.preserve),
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment),
    });
  }
}

// tslint:disable-next-line:max-classes-per-file
@Directive({
  selector: 'a[appRouterLink]',
  exportAs: 'RouterLinkWithHrefDirective',
})
export class RouterLinkWithHrefDirective implements OnChanges, OnDestroy {
  /**
   * Default (undefined) => dynamic behavior; external links means '_blank' and internal links means '_self' or '' (empty string)
   * If it's defined, then will use that value
   */
  @HostBinding('attr.target') @Input() target: string;
  @Input() queryParams: {[k: string]: any};
  @Input() fragment: string;
  @Input() queryParamsHandling: QueryParamsHandling;
  @Input() preserveFragment: boolean;
  @Input() skipLocationChange: boolean;
  @Input() replaceUrl: boolean;
  private commands: any[] = [];
  private subscription: Subscription;
  private preserve: boolean;
  public isInternal: boolean;

  // the url displayed on the anchor element.
  @HostBinding() href: string;

  constructor(
    private router: Router, private route: ActivatedRoute,
    private locationStrategy: LocationStrategy,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    this.subscription = router.events.subscribe((s: RouterEvent) => {
      if (s instanceof NavigationEnd) {
        this.updateTargetUrlAndHref();
      }
    });
  }

  @Input()
  // tslint:disable-next-line:no-identical-functions
  set appRouterLink(commands: any[]|string) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
      // starts with . or a single / (/ but not //)
      this.isInternal = this.commands[0].search(/(\.|\/[^\/])/) === 0;
      this.setTargetAndRel();
    } else {
      this.commands = [];
    }
  }

  private setTargetAndRel() {
    if (!this.target) {
      this.target = this.isInternal ? '_self' : '_blank';
    }
    if (this.target === '_blank') {
      this.renderer.setAttribute(this.el.nativeElement, 'rel', 'noopener');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'rel');
    }
  }

  ngOnChanges(_: {}): any { this.updateTargetUrlAndHref(); }
  ngOnDestroy(): any { this.subscription.unsubscribe(); }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    const extras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
    };

    if (this.isInternal) {
      this.router.navigateByUrl(this.urlTree, extras);
    } else {
      window.location.assign(this.commands[0]);
    }
    return false;
  }

  private updateTargetUrlAndHref(): void {
    if (this.isInternal) {
      this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
    } else {
      this.href = this.commands[0];
    }
  }

  // tslint:disable-next-line:no-identical-functions
  get urlTree(): UrlTree {
    return this.router.createUrlTree(this.commands, {
      relativeTo: this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      preserveQueryParams: attrBoolValue(this.preserve),
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment),
    });
  }
}

function attrBoolValue(s: any): boolean {
  return s === '' || !!s;
}
/* jscpd:ignore-end */
