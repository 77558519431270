import * as i0 from "./footer-get-app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@trademe/ng-defer-load/dist/defer-load.directive";
import * as i3 from "@angular/common";
import * as i4 from "./footer-get-app.component";
import * as i5 from "../../shared/services/analytics.service";
var styles_FooterGetAppComponent = [i0.styles];
var RenderType_FooterGetAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterGetAppComponent, data: {} });
export { RenderType_FooterGetAppComponent as RenderType_FooterGetAppComponent };
function View_FooterGetAppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Baixar na Play Store"], ["src", "assets/footer/google.svg"]], null, null, null, null, null))], null, null); }
function View_FooterGetAppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Baixar na App Store"], ["src", "assets/footer/apple.svg"]], null, null, null, null, null))], null, null); }
function View_FooterGetAppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "text-center"]], null, [[null, "deferLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deferLoad" === en)) {
        var pd_0 = (_co.setLoadImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.DeferLoadDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], null, { deferLoad: "deferLoad" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title d-none d-md-block font-weight-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "app-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["href", "https://play.google.com/store/apps/details?id=com.nostro.bodytech&hl=pt_BR"], ["id", "get-app-android"], ["rel", "noopener"], ["target", "_blank"]], null, [[null, "click"], [null, "auxclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickApp("BT-Android") !== false);
        ad = (pd_0 && ad);
    } if (("auxclick" === en)) {
        var pd_1 = (_co.clickApp("BT-Android") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterGetAppComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["href", "https://itunes.apple.com/br/app/bodytech/id549237579?mt=8"], ["id", "get-app-ios"], ["rel", "noopener"], ["target", "_blank"]], null, [[null, "click"], [null, "auxclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickApp("BT-iOS") !== false);
        ad = (pd_0 && ad);
    } if (("auxclick" === en)) {
        var pd_1 = (_co.clickApp("BT-iOS") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterGetAppComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loadImage; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.loadImage; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_FooterGetAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterGetAppComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterGetAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-get-app", [], null, null, null, View_FooterGetAppComponent_0, RenderType_FooterGetAppComponent)), i1.ɵdid(1, 49152, null, 0, i4.FooterGetAppComponent, [i5.AnalyticsService], null, null)], null, null); }
var FooterGetAppComponentNgFactory = i1.ɵccf("app-footer-get-app", i4.FooterGetAppComponent, View_FooterGetAppComponent_Host_0, { data: "data" }, {}, []);
export { FooterGetAppComponentNgFactory as FooterGetAppComponentNgFactory };
