import { AfterContentInit, ChangeDetectorRef, ContentChildren, Directive,
  ElementRef, Input, OnChanges, OnDestroy, QueryList, Renderer2, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { RouterLinkDirective, RouterLinkWithHrefDirective } from './router-external.directive';
import { Subscription } from 'rxjs';

// Copy from https://github.com/angular/angular/blob/master/packages/router/src/directives/router_link_active.ts
// Only changes:
// RouterLink => RouterLinkExternalDirective
// RouterLinkWithHref => RouterLinkWithHrefExternalDirective
@Directive({
  selector: '[appRouterLinkActive]',
  exportAs: 'appRouterLinkActive',
})
export class RouterActiveDirective implements OnChanges, OnDestroy, AfterContentInit {
  @ContentChildren(RouterLinkDirective, {descendants: true}) links: QueryList<RouterLinkDirective>;
  @ContentChildren(RouterLinkWithHrefDirective, {descendants: true})
  linksWithHrefs: QueryList<RouterLinkWithHrefDirective>;

  private classes: string[] = [];
  private subscription: Subscription;
  public readonly isActive: boolean = false;

  @Input() routerLinkActiveOptions: {exact: boolean} = {exact: false};

  constructor(
    private router: Router, private element: ElementRef, private renderer: Renderer2,
    // @ts-ignore
    private cdr: ChangeDetectorRef,
  ) {
    this.subscription = router.events.subscribe((s: RouterEvent) => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }


  ngAfterContentInit(): void {
    this.links.changes.subscribe(_ => this.update());
    this.linksWithHrefs.changes.subscribe(_ => this.update());
    this.update();
  }

  @Input()
  set appRouterLinkActive(data: string[]|string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }
  // @ts-ignore
  ngOnChanges(changes: SimpleChanges): void { this.update(); }
  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  private update(): void {
    if (!this.links || !this.linksWithHrefs || !this.router.navigated) { return; }

    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        this.classes.forEach((c) => {
          if (hasActiveLinks) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      }
    });
  }

  private isLinkActive(router: Router): (link: (RouterLinkDirective|RouterLinkWithHrefDirective)) => boolean {
    return (link: RouterLinkDirective | RouterLinkWithHrefDirective) =>
            router.isActive(link.urlTree, this.routerLinkActiveOptions.exact);
  }

  private hasActiveLinks(): boolean {
    return this.links.some(this.isLinkActive(this.router)) ||
      this.linksWithHrefs.some(this.isLinkActive(this.router));
  }
}
