import { BreakpointsService } from '@services/breakpoints.service';
import { OnDestroy, OnInit, ElementRef } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { BrandService } from '@services/brand.service';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
var NavbarTopComponent = (function () {
    function NavbarTopComponent(authService, breakpointsService, brandService, toastrService) {
        var _this = this;
        this.authService = authService;
        this.breakpointsService = breakpointsService;
        this.brandService = brandService;
        this.toastrService = toastrService;
        this.height = 60;
        this.isUserLogged = false;
        this.userAvatar = '';
        this.brandNameLowerCase = '';
        this.navbarItems = [];
        this.navbarItemsMobile = [];
        this.navbarItemsLogged = [];
        this._menuOpened = false;
        this._screenLG = false;
        this._expanded = false;
        this.subsBreakpoints = this.breakpointsService.emitter.subscribe(function (val) {
            _this.screenLG = val >= 4;
        });
    }
    Object.defineProperty(NavbarTopComponent.prototype, "menuOpened", {
        get: function () { return this._menuOpened; },
        set: function (val) {
            this._menuOpened = val;
            this.recalculateExpanded();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarTopComponent.prototype, "screenLG", {
        get: function () { return this._screenLG; },
        set: function (val) {
            this._screenLG = val;
            this.recalculateExpanded();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarTopComponent.prototype, "expanded", {
        get: function () { return this._expanded; },
        enumerable: true,
        configurable: true
    });
    NavbarTopComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.toastrService.overlayContainer = this.toastContainer;
        this.brand = this.brandService.getBrand();
        this.brandNameLowerCase = this.brandService.getFullBrandName().toLowerCase();
        this.isUserLogged = this.authService.checkAuth();
        this.subs = this.authService.getListener()
            .subscribe(function (data) { return _this.isUserLogged = data; });
        var user = this.authService.getUser();
        if (user) {
            this.userAvatar = user.avatar;
        }
    };
    NavbarTopComponent.prototype.ngOnDestroy = function () {
        this.subs.unsubscribe();
        this.subsBreakpoints.unsubscribe();
        this.isUserLogged = false;
    };
    NavbarTopComponent.prototype.logout = function () {
        this.authService.logout();
    };
    NavbarTopComponent.prototype.onExpandedChange = function (val) {
        if (!this.screenLG) {
            this.menuOpened = val;
        }
    };
    NavbarTopComponent.prototype.recalculateExpanded = function () {
        this._expanded = this.menuOpened || this.screenLG;
    };
    return NavbarTopComponent;
}());
export { NavbarTopComponent };
