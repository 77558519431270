import { Tab } from './tab.model';
import { GymBenefit, Gym, GymActivity, GymFilter, FlatGymUnitService, GymPlan } from './responses/gym-response.model';
import { AgendaEventDetails } from './responses/agenda-response.model';
import { PriceRange } from './price.model';

// tslint:disable:ban-types
export interface ModalObject<T = any> {
  type: ModalType;
  title: string;
  message: string;
  confirmButtonClass?: string;
  confirmButton?: string;
  cancelButton?: string;
  confirmCallback?: Function;
  cancelCallback?: Function;
  dismissCallback?: Function;
  data?: any;
  config?: T;
}
// tslint:enable:ban-types

export const enum ModalType {
  _close,
  alert,
  confirm,
  agenda,
  schedule,
  priceRange,
  gymActivity,
  gymBenefit,
  gymBenefitList,
  gymAge,
  gymPlan,
  fullScreenImage,
  gymSelect,
  presaleDetails,
  singleSelect,
}

export interface ModalAgendaObject extends ModalObject {
  event: AgendaEventDetails;
}

export interface ModalGymSelectObject extends ModalObject {
  selected: number;
  gyms: Gym[];
}

export interface ModalGymActivityObject extends ModalObject {
  groups: Tab[];
  activities: GymActivity[];
}

export interface ModalAgesObject extends ModalObject {
  ages: GymFilter[];
}

export interface ModalGymBenefitsObject extends ModalObject {
  benefits: GymBenefit[];
}

export interface ModalUnitServicesObject extends ModalObject {
  benefits: FlatGymUnitService[];
}

export interface ModalGymPlanObject extends ModalObject {
  plan: GymPlan;
}

export interface ModalPriceRangeObject extends ModalObject {
  priceRange: PriceRange;
}

export enum ModalMethodsLabel {
  confirmCallback = 'confirmCallback',
  cancelCallback = 'cancelCallback',
  dismissCallback = 'dismissCallback',
}

export interface ModalSingleSelectObject extends ModalObject {
  selected: any;
  cities?: any[];
  states?: any[];
}
