<div class="background-shadow" [style.height.px]="height">
  <div toastContainer class="toastr"></div>
</div>
<nav id="navbar" appOpenModalPadding="right" class="navbar fixed-top container w-100 navbar-expand-lg navbar-dark align-items-start py-0" [class.formula-only]="brand === 'fr'" >
  <!-- LOGO -->
  <a id="navbar-home"
      class="navbar-brand"
      routerLink="/"
      (click)="menuOpened = false">
    <img alt="Logo da {{brandNameLowerCase}}" src="assets/images/img-logo.svg">
  </a>
  <!-- BOTÃO MENU -->
  <button appClickable
      class="navbar-toggler"
      type="button"
      aria-controls="navbarSupportedContent"
      aria-label="Toggle navigation"
      #toggleIcon
      (click)="navLinks.toggle()">
    <img alt="Menu" [class.close-icon]="navLinks.expanded"
      src="{{ navLinks.expanded ? menuCloseImagePath : menuOpenImagePath }}">
  </button>

  <!-- NAVBAR -->
  <div class="d-flex" [style.minHeight.px]="height" [style.height.px]="height">
    <div id="navbarSupportedContent"
        #navLinks
        app-collapse
        [animation]="!screenLG"
        [expanded]="expanded"
        (expandedChange)="onExpandedChange($event)"
        class="navbar-collapse">
      <div class="hide-bar d-lg-none">
        <div class="container text-right">
          <!-- FOTO DE USUÁRIO -->
            <a *ngIf="isUserLogged"
                id="navbar-user-link"
                class="nav-link user-link no-padding"
                routerLink="/cliente"
                routerLinkActive="active"
                (click)="menuOpened = false">
              <img alt="Perfil do usuário"
                  class="user-pic rounded-circle"
                  [src]="userAvatar || 'assets/images/default-user-image.png'">
            </a>
          </div>
      </div>
      <ul class="navbar-nav title ml-auto">
        <!-- MENU DE USUÁRIO PEQUENO -->
        <ng-container *ngIf="isUserLogged">
          <li *ngFor="let link of navbarItemsLogged" class="nav-item font-weight-normal d-lg-none">
            <a id="{{ link.id }}"
                class="nav-link {{ link.class }}"
                [appRouterLink]="link.url"
                (click)="menuOpened = false"
                appRouterLinkActive="active"
                [routerLinkActiveOptions]="{exact: link?.exact}">
              {{ link.text }}
            </a>
          </li>
        </ng-container>

        <!-- LINKS -->
        <li *ngFor="let link of navbarItems" class="nav-item d-flex {{ link.class }}">
          <a id="{{ link.id }}"
              class="nav-link d-flex align-items-center"
              [appRouterLink]="link.url"
              appRouterLinkActive="active"
              (click)="menuOpened = false; true;"
              [routerLinkActiveOptions]="{exact: link?.exact}">
              {{ link.text }}
          </a>
        </li>

        <!-- MENU DE USUÁRIO -->
        <app-navbar-user-menu *ngIf="isUserLogged" [navbarUserMenu]="navbarItemsLogged"></app-navbar-user-menu>
        <!-- LOGIN/ LOGOUT -->
        <li class="nav-item highlight" *ngIf="!isUserLogged">
          <a id="navbar-login"
              class="nav-link login"
              routerLink="/cliente/login"
              (click)="menuOpened = false">
            Login
          </a>
        </li>
        <li class="nav-item highlight d-lg-none" *ngIf="isUserLogged">
          <a id="navbar-logout"
              appClickable
              class="nav-link login"
              (click)="menuOpened = false; logout();">
            Logout
          </a>
        </li>

        <!-- LINKS MOBILE -->
        <li class="nav-item d-lg-none" *ngFor="let link of navbarItemsMobile">
          <a id="{{ link.id }}"
              class="nav-link nav-link-mobile {{ link.class }}"
              [appRouterLink]="link.url"
              (click)="menuOpened = false"
              appRouterLinkActive="active"
              [routerLinkActiveOptions]="{exact: link?.exact}">
            {{ link.text }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
