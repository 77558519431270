import * as i0 from "./footer-partners.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/clickable.directive";
import * as i3 from "@trademe/ng-defer-load/dist/defer-load.directive";
import * as i4 from "@angular/common";
import * as i5 from "./footer-partners.component";
import * as i6 from "../../shared/services/analytics.service";
var styles_FooterPartnersComponent = [i0.styles];
var RenderType_FooterPartnersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterPartnersComponent, data: {} });
export { RenderType_FooterPartnersComponent as RenderType_FooterPartnersComponent };
function View_FooterPartnersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appClickable", ""], ["class", "partner-logo"], ["rel", "noopener"], ["target", "_blank"]], [[8, "id", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickPartner(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "alt", 0], [8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "partner-", _v.context.index, ""); var currVal_1 = _v.context.$implicit.link; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; var currVal_4 = (_co.loadImage ? _v.context.$implicit.icon : ""); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
function View_FooterPartnersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, [[null, "deferLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deferLoad" === en)) {
        var pd_0 = ((_co.loadImage = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i3.DeferLoadDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], null, { deferLoad: "deferLoad" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "partners-title no-bottom-line text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "partners-row row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterPartnersComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_FooterPartnersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterPartnersComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterPartnersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-partners", [], null, null, null, View_FooterPartnersComponent_0, RenderType_FooterPartnersComponent)), i1.ɵdid(1, 49152, null, 0, i5.FooterPartnersComponent, [i6.AnalyticsService], null, null)], null, null); }
var FooterPartnersComponentNgFactory = i1.ɵccf("app-footer-partners", i5.FooterPartnersComponent, View_FooterPartnersComponent_Host_0, { data: "data", title: "title" }, {}, []);
export { FooterPartnersComponentNgFactory as FooterPartnersComponentNgFactory };
