var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StringUtil } from '@utils/string-util';
import { GymService } from './gym.service';
import { BenefitService } from './benefit.service';
import { ActivityService } from './activity.service';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Router, NavigationEnd } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { of, forkJoin } from 'rxjs';
import { map, tap, mergeMap } from 'rxjs/operators';
import { FusePipe } from '@pipes';
import { GymSearchAbstractService } from './gym-search-abstract.service';
var GymSearchCacheService = (function (_super) {
    __extends(GymSearchCacheService, _super);
    function GymSearchCacheService(apollo, http, router, activityService, benefitService) {
        var _this = _super.call(this, apollo) || this;
        _this.apollo = apollo;
        _this.http = http;
        _this.router = router;
        _this.activityService = activityService;
        _this.benefitService = benefitService;
        _this.cacheGyms = null;
        _this.cacheActivities = null;
        _this.cacheBenefits = null;
        _this.router.events.subscribe(function (e) {
            if (e instanceof NavigationEnd) {
                _this.cacheGyms = null;
            }
        });
        return _this;
    }
    GymSearchCacheService.prototype.clearCache = function () {
        this.cacheGyms = null;
    };
    GymSearchCacheService.prototype.getGymNames = function () {
        return this._getGymNames();
    };
    GymSearchCacheService.prototype.distance = function (x1, y1, x2, y2) {
        var a = Math.abs(x1 - x2);
        var b = Math.abs(y1 - y2);
        return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
    };
    GymSearchCacheService.prototype.filterGymsByGymSearchQuery = function (filterParam) {
        var _this = this;
        var filtered = this.cacheGyms.filter(function (gym) {
            if (filterParam.minPrice &&
                gym.unitGroupPlan.map(function (groupPlan) { return groupPlan.plan.gymUnitPeriodicities.every(function (p) { return p.subscriptionFee < filterParam.maxPrice; }); })) {
                return false;
            }
            if (filterParam.maxPrice &&
                gym.unitGroupPlan.map(function (groupPlan) { return groupPlan.plan.gymUnitPeriodicities.every(function (p) { return p.subscriptionFee < filterParam.maxPrice; }); })) {
                return false;
            }
            var servicesId = gym.gymUnitServices.map(function (s) { return s.service.id; });
            if (filterParam.services &&
                filterParam.services.length &&
                !filterParam.services.every(function (s) { return servicesId.includes(s); })) {
                return false;
            }
            var activitiesId = gym.activities.map(function (a) { return a.id; });
            if (filterParam.activities &&
                filterParam.activities.length &&
                !filterParam.activities.every(function (a) { return activitiesId.includes(a); })) {
                return false;
            }
            return true;
        });
        if (JSONUtil.exists(filterParam.lat) && JSONUtil.exists(filterParam.lon)) {
            var lat_1 = parseFloat(filterParam.lat);
            var lon_1 = parseFloat(filterParam.lon);
            filtered.forEach(function (gym) {
                gym.distanceForSearch = _this.distance(lat_1, lon_1, gym.address.latitudeN, gym.address.longitudeN);
            });
            filtered.sort(function (a, b) { return a.distanceForSearch - b.distanceForSearch; });
        }
        else {
            filtered.sort(StringUtil.strcmpName);
        }
        return filtered;
    };
    GymSearchCacheService.prototype.paginate = function (gyms, pageLength, offset) {
        if (gyms.length < offset) {
            return [];
        }
        else {
            return gyms.slice(offset, Math.min((gyms.length - 1), (offset + pageLength)));
        }
    };
    GymSearchCacheService.prototype.getActivities = function () {
        var _this = this;
        if (this.cacheActivities) {
            return of(this.cacheActivities);
        }
        else {
            return this.activityService.getActiveActivities()
                .pipe(tap(function (val) { return _this.cacheActivities = val; }));
        }
    };
    GymSearchCacheService.prototype.getBenefits = function () {
        var _this = this;
        if (this.cacheActivities) {
            return of(this.cacheBenefits);
        }
        else {
            return this.benefitService.getBenefits()
                .pipe(tap(function (val) { return _this.cacheBenefits = val; }));
        }
    };
    GymSearchCacheService.prototype.searchBy = function (filterParam, pageLength, page) {
        var _this = this;
        filterParam['max'] = pageLength;
        filterParam['offset'] = page * pageLength;
        if (this.cacheGyms) {
            var filtered = this.filterGymsByGymSearchQuery(filterParam);
            var results = this.paginate(filtered, pageLength, filterParam.offset);
            return of({
                results: results,
                more: filtered.length > ((page + 1) * pageLength),
                total: filtered.length,
                resultType: 'search',
                orderType: JSONUtil.exists(filterParam.lat) && JSONUtil.exists(filterParam.lon) ? 'location.user' : 'alphabetically',
            });
        }
        else {
            var getGyms = this.apollo.query({
                query: AppGraphql.listGymUnitsByStateCityAndNeighborhood,
                variables: {
                    stateInitials: filterParam.stateInitials || 'sp',
                    city: filterParam.city,
                    neighborhood: filterParam.neighborhood,
                },
            }).pipe(map(JSONUtil.turnApolloMutable('listGymUnitsByStateCityAndNeighborhood')));
            return forkJoin([
                this.getActivities(),
                this.getBenefits(),
                getGyms,
            ]).pipe(tap(function (_a) {
                var _ = _a[0], benefits = _a[1], gyms = _a[2];
                var benefitsMap = {};
                benefits.forEach(function (b) { return benefitsMap[b.id] = b; });
                gyms.forEach(function (gym) {
                    gym.gymUnitServices.forEach(function (s) { return s.service = benefitsMap[s.service.id]; });
                    var photos = gym.photos
                        .filter(function (p) { return p.objectType === "THUMBNAIL"; })
                        .sort(function (a, b) { return a.displayOrder - b.displayOrder; });
                    if (photos && photos.length) {
                        gym.thumbnail = photos[0];
                    }
                    GymService.preFormatGym(gym);
                });
                _this.cacheGyms = gyms;
            }), mergeMap(function () { return _this.searchBy(filterParam, pageLength, page); }));
        }
    };
    GymSearchCacheService.prototype.searchGymNames = function (text) {
        return this.getGymNames()
            .pipe(map(function (value) { return new FusePipe().transform(value, text, { keys: ['name'] }); }));
    };
    return GymSearchCacheService;
}(GymSearchAbstractService));
export { GymSearchCacheService };
