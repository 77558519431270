import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { RedirectWithParameterComponent } from './shared/components/redirect-with-parameter/redirect-with-parameter.component';

import { UrlInternalRedirect } from './shared/utils/app-url-redirects';

// @ts-ignore
export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: 'app/home/home.module#HomeModule',
      },
      {
        path: 'academias',
        loadChildren: 'app/gym/gym.module#GymModule',
      },
      {
        path: 'academia/:unitSlug/compra/:planSlug/:periodicitySlug',
        loadChildren: 'app/registration/registration.module#RegistrationModule',
      },
      {
        path: 'academia',
        redirectTo: 'academias',
        pathMatch: 'full',
      },
      {
        path: 'academia',
        loadChildren: 'app/gym-detail/gym-detail.module#GymDetailModule',
      },
      {
        path: 'agenda',
        loadChildren: 'app/agenda/agenda.module#AgendaModule',
      },
      {
        path: 'cliente',
        loadChildren: 'app/area-user/area-user.module#AreaUserModule',
      },
      {
        path: 'login',
        redirectTo: UrlInternalRedirect.login,
        pathMatch: 'full',
      },
      {
        path: 'bodytech',
        loadChildren: 'app/bt-company/bt-company.module#BtCompanyModule',
      },
      {
        path: 'formula',
        loadChildren: 'app/bt-company/bt-company.module#BtCompanyModule',
      },
      {
        path: 'privacidade',
        loadChildren: 'app/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
      },
      {
        path: 'imprensa',
        loadChildren: 'app/press-release/press-release.module#PressReleaseModule',
      },
      {
        path: 'trabalhe-conosco',
        loadChildren: 'app/work-with-us/work-with-us.module#WorkWithUsModule',
      },
      {
        path: 'franqueado',
        loadChildren: 'app/franchise/franchise.module#FranchiseModule',
      },
      {
        path: 'contato',
        loadChildren: 'app/ticket/ticket.module#TicketModule',
      },
      {
        path: 'duvidas',
        redirectTo: 'faq',
      },
      {
        path: 'faq',
        loadChildren: 'app/faq/faq.module#FaqModule',
      },
      {
        path: 'conceito',
        loadChildren: 'app/concept/concept.module#ConceptModule',
      },
      {
        path: 'corporativo',
        loadChildren: 'app/corporate/corporate.module#CorporateModule',
      },

      // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado
      { path: 'ecommerce/cadastronovasenha',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.recoveryPassword,
        },
      },
      { path: 'ECommerce/cadastronovasenha',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.recoveryPassword,
        },
      },

      // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado
      { path: 'ECommerce/DesbloqueioConta',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.login,
        },
      },

       // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado FORMULA
      { path: 'ContaUsuario/DesbloquearUsuario',
       component: RedirectWithParameterComponent,
       data: {
         redirectTo: UrlInternalRedirect.login,
       },
      },

       // Redirecionamento do link de cadastro de nova senha enviado por email do sistema legado FORMULA
      { path: 'ContaUsuario/AlterarSenha',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: UrlInternalRedirect.recoveryPassword,
        },
      },

     // Redirecionamento do link de cadastro de menor e estrangeiro
      { path: 'Ecommerce/CriarAcesso',
        component: RedirectWithParameterComponent,
        data: {
          redirectTo: '/cliente/novo',
        },
      },

      {
        path: '**',
        component: NotFoundComponent,
        resolve: {
          config: PortalConfigResolveGuard,
        },
        data: {
          windowTitle: '${data.config.notFound.header.title}',
          metaRobots: 'noindex,follow',
          config: { notFound: 'not-found' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
