import { BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class OpenModalPaddingService implements OnDestroy {
  public readonly emitter = new BehaviorSubject<string>(this.getPaddingRight());
  private readonly mutationObserver: MutationObserver;
  private lastValue = '';

  constructor() {
    this.mutationObserver = new MutationObserver(() => this.onStyleChange());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
  }

  private onStyleChange() {
    const value = this.getPaddingRight();
    if (value !== this.lastValue) {
      this.emitter.next(value);
      this.lastValue = value;
    }
  }

  private getPaddingRight(): string {
    return window.getComputedStyle(document.body).paddingRight;
  }

  ngOnDestroy() {
    this.mutationObserver.disconnect();
  }

}
