import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent implements OnInit {

  @ContentChild('cardHeader', { read: TemplateRef }) cardHeader: TemplateRef<any>;
  @ContentChild('cardButtons', { read: TemplateRef }) cardButtons: TemplateRef<any>;

  @Input() message = '';

  @Input() icon = '';
  @Input() altIcon = '';

  @Input() buttonText = '';
  @Input() buttonLink = '';

  constructor() { }

  ngOnInit() {
  }

}
