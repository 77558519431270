import * as i0 from "./empty-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./empty-card.component";
var styles_EmptyCardComponent = [i0.styles];
var RenderType_EmptyCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyCardComponent, data: {} });
export { RenderType_EmptyCardComponent as RenderType_EmptyCardComponent };
function View_EmptyCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EmptyCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EmptyCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "btn btn-primary btn-confirm"], ["id", "empty-button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonLink; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.buttonText; _ck(_v, 2, 0, currVal_3); }); }
export function View_EmptyCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "card row-on-xs no-side-border-xs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyCardComponent_1)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "position-relative backdrop d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "alt", 0], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "card-body text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyCardComponent_2)), i1.ɵdid(9, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyCardComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardHeader; _ck(_v, 2, 0, currVal_0); var currVal_4 = _co.cardButtons; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.buttonText; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.altIcon, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.message; _ck(_v, 7, 0, currVal_3); }); }
export function View_EmptyCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-empty-card", [], null, null, null, View_EmptyCardComponent_0, RenderType_EmptyCardComponent)), i1.ɵdid(1, 114688, null, 2, i4.EmptyCardComponent, [], null, null), i1.ɵqud(335544320, 1, { cardHeader: 0 }), i1.ɵqud(335544320, 2, { cardButtons: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmptyCardComponentNgFactory = i1.ɵccf("app-empty-card", i4.EmptyCardComponent, View_EmptyCardComponent_Host_0, { message: "message", icon: "icon", altIcon: "altIcon", buttonText: "buttonText", buttonLink: "buttonLink" }, {}, []);
export { EmptyCardComponentNgFactory as EmptyCardComponentNgFactory };
