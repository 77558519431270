import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { GymImage } from '@models';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent {

  @ViewChild(SwiperDirective) swiper: SwiperDirective;

  @Input() images: GymImage[] = [];
  @Input() isModal = false;
  @Input() currentImage: { index: number } = { index: 0 };
  @Output() buttonClick = new EventEmitter<number>();

  disabled = false;
  private defaultAutoplay = { delay: 6000, disableOnInteraction: false };
  private defaultSpeed = 700;

  swiperConfig: SwiperConfigInterface = {
    loop: true,
    autoplay: this.defaultAutoplay,
    speed: 700,
    navigation: true,
    threshold: 10,
  };

  refresh() {
    Object.assign(this.swiperConfig, { speed: this.swiperConfig.speed > 700 ? 700 : 750 });
  }

  hoveringButtonClick() {
    this.buttonClick.emit(this.currentImage.index);
  }

  stop() {
    this.disabled = true;
    this.swiperConfig.autoplay = false;
    this.swiperConfig.speed = 0;
    this.swiper.stopAutoplay(false);
  }

  restart() {
    this.swiper.startAutoplay(false);
    this.swiperConfig.speed = this.defaultSpeed;
    this.swiperConfig.autoplay = this.defaultAutoplay;
    this.disabled = false;
  }

}
