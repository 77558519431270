import { StringUtil } from '@utils/string-util';
import { Injectable } from '@angular/core';
import { City, SelectItem, State } from '@models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { JSONUtil } from '@utils/json-util';
import { AppGraphql } from '@utils/app-graphql';

interface CacheState extends State {
  cities?: City[];
}

@Injectable()
export class AddressService {

  private cache: CacheState[];

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getStates(): Observable<State[]> {
    if (this.cache) {
      return of(this.cache);
    } else {
      return this.apollo.query<State[]>({
        query: AppGraphql.queryStateList,
      }).pipe(
        map(JSONUtil.turnApolloMutable<State[]>('listStatesRedis')),
        map(value => {
          this.cache = value;
          return value;
        }),
      );
    }
  }

  getStateByCityId(cityId: number): Observable<{state: State}> {
    return this.apollo.query<State>({
      query: AppGraphql.queryCityState,
      variables: {
        id: cityId,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<{state: State}>('city')),
    );
  }

  getCitiesInState(stateId: number): Observable<City[]> {
    const stateObj = this.cache ? this.cache.find(value => value.id === stateId) : null;

    if (!stateObj) {
      return of([]);
    }

    if (stateObj.cities && stateObj.cities.length) {
      return of(stateObj.cities);
    } else {
      return this.apollo.query<CacheState>({
        query: AppGraphql.queryState,
        variables: { stateId },
      }).pipe(
        map(JSONUtil.turnApolloMutable<CacheState>('state')),
        map(value => {
          value.cities.sort(StringUtil.strcmpName);
          stateObj.cities = value.cities;
          return value.cities;
        }),
      );
    }
  }

  stateToSelect(state: State): SelectItem<State> {
    return Object.assign({
      text: state.name,
    }, state);
  }

  cityToSelect(city: City): SelectItem<City> {
    return Object.assign({
      text: city.name,
      disabled: typeof city.state === 'undefined' ? false : city.state.id < 0,
    }, city);
  }

}
