<li id="navbar-user-menu" class="nav-item d-lg-flex d-none">
  <div appDropdownArrows
      appClickable
      (click)="collapse.toggle()"
      class="light" #userLinkItem
      aria-controls="userMenu"
      [attr.aria-expanded]="collapse.expanded"
      aria-label="Toggle navigation">
    <a id="navbar-user-link" class="nav-link user-link d-flex align-items-center" routerLink="/cliente" routerLinkActive="active" (click)="$event.stopPropagation()">
      <span class="d-none d-xl-block user-name">{{ userName || 'Usuário' }}</span>
      <img alt="Perfil do usuário" class="user-pic rounded-circle" [src]="userAvatar || 'assets/images/default-user-image.png'">
    </a>
  </div>
</li>

<div id="userMenu"
    #collapse
    app-collapse
    class="user-menu position-absolute text-center"
    (mouseleave)="collapse.close()"
    (click)="collapse.close()">
  <div class="arrow"></div>
  <a id="navbar-user-{{index}}" *ngFor="let link of navbarUserMenu; let index = index" [routerLink]="link.url" class="list-group-item list-group-item-action">
    {{ link.text }}
  </a>
  <a id="navtop-user-logout" appClickable (click)="logout()" class="list-group-item list-group-item-action text-primary">Logout</a>
</div>
