import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./screen-size.component";
var styles_ScreenSizeComponent = [".size[_ngcontent-%COMP%] { width: 90%; margin-left: 5%; background-color: rgba(0, 0, 0, .1)}"];
var RenderType_ScreenSizeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ScreenSizeComponent, data: {} });
export { RenderType_ScreenSizeComponent as RenderType_ScreenSizeComponent };
export function View_ScreenSizeComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.UpperCasePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" Current size: ", " (", "px) "])), i0.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.screenSize)); var currVal_1 = _co.screenSizePx; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ScreenSizeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-debug-screen-size", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event.target.innerWidth) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ScreenSizeComponent_0, RenderType_ScreenSizeComponent)), i0.ɵdid(1, 114688, null, 0, i2.ScreenSizeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScreenSizeComponentNgFactory = i0.ɵccf("app-debug-screen-size", i2.ScreenSizeComponent, View_ScreenSizeComponent_Host_0, {}, {}, []);
export { ScreenSizeComponentNgFactory as ScreenSizeComponentNgFactory };
