var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { HttpClient } from '@angular/common/http';
import { sur } from '@utils/app-endpoints';
var CorporateService = (function () {
    function CorporateService(apollo, http) {
        this.apollo = apollo;
        this.http = http;
    }
    CorporateService.prototype.getCollaboratorsAndDepartments = function () {
        return this.apollo.query({
            query: AppGraphql.queryListCollaboratorDepartment,
        }).pipe(map(JSONUtil.turnApolloMutable()));
    };
    CorporateService.prototype.postCorporateForm = function (formData) {
        var requestBody = __assign({}, (formData.contact), (formData.company), (formData.responsible));
        return this.http.post(sur("/corporateForm/"), requestBody);
    };
    CorporateService.prototype.collaboratorToSelect = function (collaborator) {
        return Object.assign({
            text: collaborator.numberRange,
        }, collaborator);
    };
    CorporateService.prototype.departmentToSelect = function (dept) {
        return Object.assign({
            text: dept.name,
        }, dept);
    };
    CorporateService.prototype.getCorporatePrices = function () {
        return this.http.get('assets/data/bt-corporate.json');
    };
    return CorporateService;
}());
export { CorporateService };
