import { filter, map, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { SchemaOrg } from '@utils/json-ld-functions';
import { environment } from '@env/environment';

const DEFAULT_META_ROBOTS = 'index,follow';
const DEFAULT_SCRIPT_JSONLD = {};

@Injectable()
export class SEOService {

  private readonly ELEMENT_META_DESCRIPTION: HTMLMetaElement;
  private readonly ELEMENT_META_ROBOTS: HTMLMetaElement;
  private readonly ELEMENT_SCRIPT_JSONLD: HTMLScriptElement;

  private static interpolate(str: string, data: Data): string {
    try {
      // tslint:disable-next-line:no-eval
      return templateString ? templateString(str, { data }) : eval(`\`${str}\``);
    } catch {
      console.warn('Interpolate error on SEOService.\nEval failed for: ' + str);
      return '';
    }
  }

  constructor(
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.ELEMENT_META_DESCRIPTION = document.querySelector('head meta[name="description"]');
    this.ELEMENT_META_ROBOTS = document.querySelector('head meta[name="robots"]');
    this.ELEMENT_SCRIPT_JSONLD = document.querySelector('head script[type="application/ld+json"]');
  }

  public init() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      switchMap(route => route.data),
    )
    .subscribe((data: Data) => {
      this.setTitle(data);
      this.setDescription(data);
      this.setRobots(data);
      this.setJSONLDfn(data.jsonldfn, data);
    });
  }

  public setTitle(data: Data, addSuffix = true) {
    let title = '';
    title = data.windowTitle || '';
    addSuffix = typeof data.addSuffix === 'boolean' ? data.addSuffix : addSuffix;

    title = SEOService.interpolate(title, data);
    this.setTitleStr(title, addSuffix);
  }

  public setTitleStr(str: string, addSuffix = true) {
    if (addSuffix && str) {
      str += (' | ' + environment.seo.DEFAULT_TITLE);
    }
    this.titleService.setTitle(str || environment.seo.DEFAULT_TITLE);
  }

  public setDescription(data: Data) {
    let description = data.metaDescription || '';
    description = SEOService.interpolate(description, data);
    this.setDescriptionStr(description);
  }

  public setDescriptionStr(str: string) {
    this.ELEMENT_META_DESCRIPTION.content = str || environment.seo.DEFAULT_META_DESCRIPTION;
  }

  public setRobots(data: Data) {
    let robots = data.metaRobots || '';
    robots = SEOService.interpolate(robots, data);
    this.setRobotsStr(robots);
  }

  public setRobotsStr(str: string) {
    this.ELEMENT_META_ROBOTS.content = str || DEFAULT_META_ROBOTS;
  }

  public setJSONLDfn(fn: (data: Data) => SchemaOrg, data: Data) {
    this.setJSONLD(fn ? fn(data) : null);
  }

  public setJSONLD(obj: SchemaOrg) {
    try {
      this.ELEMENT_SCRIPT_JSONLD.innerHTML = JSON.stringify(obj ? obj : DEFAULT_SCRIPT_JSONLD, null, 2);
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.ELEMENT_SCRIPT_JSONLD.innerHTML = JSON.stringify(DEFAULT_SCRIPT_JSONLD, null, 2);
      } else {
        throw e;
      }
    }
  }

}
