import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollaboratorsDepartmentsResponse, Department, SelectItem, CollaboratorsRange, CorporateFormRequest } from '@models';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { HttpClient } from '@angular/common/http';
import { SurEndpoints, sur } from '@utils/app-endpoints';
import { CorporatePrices } from '@models/corporate-prices';


@Injectable()
export class CorporateService {

  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient,
  ) { }

  getCollaboratorsAndDepartments(): Observable<CollaboratorsDepartmentsResponse> {
    return this.apollo.query({
      query: AppGraphql.queryListCollaboratorDepartment,
    }).pipe(
      map(JSONUtil.turnApolloMutable()),
    );
  }

  postCorporateForm(formData: CorporateFormRequest): Observable<any> {
    const requestBody = {
      ...(formData.contact),
      ...(formData.company),
      ...(formData.responsible),
    };

    return this.http.post(sur(SurEndpoints.CorporateForm), requestBody);
  }

  collaboratorToSelect(collaborator: CollaboratorsRange): SelectItem<CollaboratorsRange> {
    return Object.assign({
      text: collaborator.numberRange,
    }, collaborator);
  }

  departmentToSelect(dept: Department): SelectItem<Department> {
    return Object.assign({
      text: dept.name,
    }, dept);
  }

  getCorporatePrices(): Observable<CorporatePrices[]> {
    return this.http.get<CorporatePrices[]>('assets/data/bt-corporate.json');
  }
}
