import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';

Sentry.init(environment.sentry);

@Injectable()
export class SentryErrorHandler extends ErrorHandler {

    handleError(error: any): void {
        Sentry.captureException(error);
    }
}
