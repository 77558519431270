import { BreakpointsService, ScreenSize } from '@services/breakpoints.service';
import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SimpleLink } from '@models';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { Brand, BrandService } from '@services/brand.service';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss'],
})
export class NavbarTopComponent implements OnInit, OnDestroy {

  @Input() height = 60;
  isUserLogged = false;
  userAvatar = '';
  brand: Brand;
  brandNameLowerCase = '';
  private subs: Subscription;

  @Input() navbarItems: SimpleLink[] = [];
  @Input() navbarItemsMobile: SimpleLink[] = [];
  @Input() navbarItemsLogged: SimpleLink[] = [];

  @Input() menuOpenImagePath: string;
  @Input() menuCloseImagePath: string;

  @ViewChild('navLinks') navLinksComponent: ElementRef;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  private _menuOpened = false;
  private _screenLG = false;
  private _expanded = false;

  get menuOpened() { return this._menuOpened; }
  set menuOpened(val: boolean) {
    this._menuOpened = val;
    this.recalculateExpanded();
  }
  get screenLG() { return this._screenLG; }
  set screenLG(val: boolean) {
    this._screenLG = val;
    this.recalculateExpanded();
  }
  get expanded() { return this._expanded; }

  private subsBreakpoints: Subscription;

  constructor(
    public readonly authService: AuthService,
    public readonly breakpointsService: BreakpointsService,
    private readonly brandService: BrandService,
    private toastrService: ToastrService,
  ) {
    this.subsBreakpoints = this.breakpointsService.emitter.subscribe(
      val => {
        this.screenLG = val >= ScreenSize.lg;
      },
    );
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    this.brand = this.brandService.getBrand();
    this.brandNameLowerCase = this.brandService.getFullBrandName().toLowerCase();
    this.isUserLogged = this.authService.checkAuth();
    this.subs = this.authService.getListener()
      .subscribe((data) => this.isUserLogged = data);

    const user = this.authService.getUser();
    if (user) {
      this.userAvatar = user.avatar;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.subsBreakpoints.unsubscribe();
    this.isUserLogged = false;
  }

  logout() {
    this.authService.logout();
  }

  onExpandedChange(val: boolean) {
    if (!this.screenLG) {
      this.menuOpened = val;
    }
  }

  recalculateExpanded() {
    this._expanded = this.menuOpened || this.screenLG;
  }
}
