import * as i0 from "./footer-social-media.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/pipe/bucket.pipe";
import * as i4 from "./footer-social-media.component";
import * as i5 from "../../shared/services/analytics.service";
var styles_FooterSocialMediaComponent = [i0.styles];
var RenderType_FooterSocialMediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterSocialMediaComponent, data: {} });
export { RenderType_FooterSocialMediaComponent as RenderType_FooterSocialMediaComponent };
function View_FooterSocialMediaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "title-md icon"], ["rel", "noopener"], ["target", "_blank"]], [[8, "id", 0], [8, "href", 4]], [[null, "click"], [null, "auxclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickSocial(_v.context.$implicit.text) !== false);
        ad = (pd_0 && ad);
    } if (("auxclick" === en)) {
        var pd_1 = (_co.clickSocial(_v.context.$implicit.text) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "social-", _v.context.index, ""); var currVal_1 = _v.context.$implicit.url; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.iconUrl)), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.text, ""); _ck(_v, 1, 0, currVal_2, currVal_3); }); }
function View_FooterSocialMediaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title d-none d-md-block font-weight-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "social-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterSocialMediaComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.links; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_FooterSocialMediaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.BucketPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterSocialMediaComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FooterSocialMediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-social-media", [], null, null, null, View_FooterSocialMediaComponent_0, RenderType_FooterSocialMediaComponent)), i1.ɵdid(1, 49152, null, 0, i4.FooterSocialMediaComponent, [i5.AnalyticsService], null, null)], null, null); }
var FooterSocialMediaComponentNgFactory = i1.ɵccf("app-footer-social-media", i4.FooterSocialMediaComponent, View_FooterSocialMediaComponent_Host_0, { data: "data" }, {}, []);
export { FooterSocialMediaComponentNgFactory as FooterSocialMediaComponentNgFactory };
