export const enum AlertMessageType {
  S = 'S',
  SUCCESS = S,
  E = 'E',
  ERROR = E,
  W = 'W',
  WARNING = W,
  I = 'I',
  INFO = I,
  D = 'D',
  DEBUG = D,
}

export const messageMap = new Map([
  // error
  [1, 'Verifique os campos marcados em vermelho.'],
  [2, 'Para continuar é necessário informar seu CPF.'],
  [4, 'Não foi encontrado o token de validação. Tente novamente.'],
  [5, 'Seus dados ainda não foram atualizados em nossa base. Tente novamente mais tarde.'],
  [6, 'Erro de comunicação: {{0}}'],
  [7, 'Verifique os campos de telefone. Os números não podem ser repetidos.'],
  [8, 'Suas informações foram salvas.'],
  [9, 'É necessário marcar o campo \'Não sou um robô\' para enviar o formulário.'],
  [10, 'É necessário aceitar o Termo de Adesão.'],
  [11, 'CEP não encontrado'],
  [12, 'O CEP {{0}} não foi encontrado.'],
  [14, 'Data de nascimento inválida.'],
  [15, 'Confira os dados fornecidos.'],
  [16, 'Sessão expirada! Por favor realize novamente o login.'],
  [17, 'Academia desejada não foi encontrada.'],
  [18, 'Plano desejado não foi encontrado.'],
  [19, 'Ocorreu um problema com o envio do e-mail. Tente novamente ou entre em contato com sua unidade.'],
  [999, 'Não foi possível conectar. Verifique sua conexão e tente novamente mais tarde.'],

  // success
  [20, 'Sua conta foi ativada.'],
  [21, 'Suas informações foram salvas com sucesso.'],
  [22, 'E-mail enviado com sucesso. Verifique sua caixa de entrada.'],
  [23, 'Pré-Venda cancelada com sucesso. Foi enviado um e-mail para {{0}} com maiores detalhes.'],

  // debug
  [24, 'Route test done!'],
  [25, 'Agenda: done!'],
  [26, 'Token gerado {{0}}...'],

  // warning
  [27, 'Ocorreu um erro ao tentar enviar o formulário. {{0}}'],
  [28, 'Erro de processamento: {{0}}'],

  // error backend
  [4120001, 'Dados não encontrados. Verifique e tente novamente.'],
  [4120004, 'Para se matricular online é preciso ser maior de 18 anos.'],
  [4120011, 'CPF não encontrado no nosso banco de dados. Verifique seus dados e tente novamente.'],
  [4120012, 'Este CPF já está sendo usado. Faça login ou redefina sua senha.'],
  [4120010, 'Este código de acesso já foi utilizado. Faça login ou redefina sua senha.'],
  [4120018, 'Este endereço de e-mail já está sendo usado.'],
  [4120039, 'Verifique o campo de vencimento do cartão.'],
  [4030004, 'Usuário bloqueado.'],
  [4030003, 'E-mail e/ou senha inválido(s).'],
  [4120015, 'Usuário bloqueado.'],
  [4120013, 'Dados não encontrados. Verifique e tente novamente.'],
  [4000004, 'Verifique os campos marcados em vermelho.'],
  [4120022, 'O plano selecionado não está disponível para a idade do cliente.'],
  [4120025, 'As alterações não serão salvas. Por favor, recarregue a página e tente novamente.'],
  [4000000, 'Ocorreu um erro no servidor. Tente novamente mais tarde.'],
  [4000018, 'Senha em branco.'],
  [4000020, 'E-mail em branco.'],
  [4120021, 'O plano selecionado não está disponível para essa faixa etária.'],
  [4120049, 'A situação desse contrato impossibilita o cancelamento pelo site.\n'
  + 'Por favor, dirija-se à sua Academia para solicitar o cancelamento do seu plano.'],
  [4120053, 'Nosso sistema identificou que o contrato selecionado foi originado e/ou alterado na Academia.'
  + ' O cancelamento online está disponível apenas para clientes que realizaram sua matrícula pelo site'
  + ' Bodytech e que não tiveram nenhuma alteração contratual implementada (desconto, renovação, alteração'
  + ' de forma de pagamento etc)\n\nPor favor, dirija-se à sua Academia para solicitar o cancelamento do seu'
  + ' plano. Nossa equipe estará à sua disposição para lhe atender.'],
]);

export const enum MessageMap {
  CAMPOS_EM_VERMELHO = 1,
  NECESSARIO_INFORMAR_CPF = 2,
  DADOS_ERRADOS_OU_INCOMPLETOS = 3,
  TOKEN_NAO_ENCONTRADO = 4,
  DADOS_SENDO_ATUALIZADOS = 5,
  ERRO_COMUNICACAO = 6,
  TELEFONE_REPETIDOS = 7,
  INFORMACOES_SALVAS = 8,
  CAMPO_NAO_SOU_ROBO = 9,
  ACEITAR_TERMO_ADESAO = 10,
  CEP_INVALIDO = 11,
  CEP_NAO_ENCONTRADO = 12,
  DATA_NASCIMENTO_INVALIDA = 14,
  CONFIRA_DADOS_FORNECIDOS = 15,
  SESSAO_EXPIRADA = 16,
  ACADEMIA_NAO_ENCONTRADA = 17,
  PLANO_NAO_ENCONTRADO = 18,
  PROBLEMA_ENVIO_EMAIL = 19,
  SEM_CONEXAO_INTERNET = 999,

  CONTA_ATIVADA = 20,
  INFO_SALVAS = 21,
  EMAIL_ENVIADO = 22,
  PRE_VENDA_CANCELADA = 23,

  ROUTE_TEST_DONE = 24,
  AGENDA_DONE = 25,
  TOKEN_GERADO = 26,

  ERRO_ENVIAR_FORMULARIO = 27,
  ERRO_PROCESSAMENTO = 28,
  PLANO_NAO_DISPONIVEL_IDADE_CLIENTE = 29,

  DADOS_NAO_ENCONTRADOS = 4120001,
  CPF_NAO_ENCONTRADO = 4120011,
  CPF_JA_UTILIZADO = 4120012,
  CODIGO_ACESSO_JA_UTILIZADO = 4120010,
  EMAIL_JA_UTILIZADO = 4120018,
  CARTAO_VENCIDO = 4120039,
  USUARIO_BLOQUEADO = 4030004,
  EMAIL_OU_SENHA_INVALIDOS = 4030003,
  ALTERACOES_NAO_SERAO_SALVAS = 4120025,
}


export class AlertMessage {
  constructor(
    public type: AlertMessageType,
    public msg: string,
  ) { }

  static success(code: MessageMap) {
    return new AlertMessage(AlertMessageType.S, messageMap.get(code));
  }

  static error(code: MessageMap) {
    return new AlertMessage(AlertMessageType.E, messageMap.get(code));
  }

  static warning(code: MessageMap) {
    return new AlertMessage(AlertMessageType.W, messageMap.get(code));
  }

  static info(message: string) {
    return new AlertMessage(AlertMessageType.I, message);
  }

  static debug(code: MessageMap) {
    return new AlertMessage(AlertMessageType.D, messageMap.get(code));
  }
}

export enum ToastType {
  E = 'toast-error',
  I = 'toast-info',
  S =  'toast-success',
  W = 'toast-warning',
}
