// Imports Angular
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { isDevMode, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import localePt from '@angular/common/locales/pt';

// Imports Libs
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { onError } from 'apollo-link-error';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { GalleryModule } from '@ngx-gallery/core';

// Other Imports and namespaces
import { AlertMessage, MessageMap, AlertMessageType } from '@models';
import { directives } from './directives';
import { guards } from '@guards';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { PopoverFadeModule } from 'app/component-modules/popover-fade/popover-fade.module';
import { OpenModalPaddingModule } from 'app/component-modules/open-modal-padding/open-modal-padding.module';

// Pipes
import { pipes } from '@pipes';

// Services
import { services } from '@services';
import { AlertMessageService } from '@services/alert-message.service';

// Interceptors
import { AuthInterceptor } from './auth.interceptor';

// Components
import { AgendaFilterComponent } from './components/agenda-filter/agenda-filter.component';
import { AlertComponent } from './components/alert/alert.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { DataAddressComponent } from './components/data-address/data-address.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FaqbotComponent } from './components/faqbot/faqbot.component';
import { GymSearchInputComponent } from './components/gym-search-input/gym-search-input.component';
import { HeaderStaticImageComponent } from './components/header-static-image/header-static-image.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { LoadingComponent } from './components/loading/loading.component';
import { modalComponents } from './components/modal';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { RedirectWithParameterComponent } from './components/redirect-with-parameter/redirect-with-parameter.component';
import { RegistryHintModalComponent } from './components/registry-hint-modal/registry-hint-modal.component';
import { SelectComponent } from './components/select/select.component';
import { SliderComponent } from './components/slider/slider.component';
import { SocialLoginButtonsComponent } from './components/social-login-buttons/social-login-buttons.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { EmptyCardComponent } from './components/empty-card/empty-card.component';
import { ModalSingleSelectComponent } from './components/modal/modal-single-select/modal-single-select.component';

registerLocaleData(localePt, 'pt-BR');

const globalComponents = [
  AlertComponent,
  CheckboxComponent,
  CollapseComponent,
  EmptyCardComponent,
  FaqbotComponent,
  HeaderStaticImageComponent,
  LoadingComponent,
  PaginationComponent,
  RadiobuttonComponent,
  RecaptchaComponent,
  RedirectWithParameterComponent,
  SelectComponent,
  SliderComponent,
  SuccessPageComponent,
  TabsComponent,
];

const sharedComponents = [
  AgendaFilterComponent,
  DataAddressComponent,
  ErrorMessageComponent,
  GymSearchInputComponent,
  ImageCarouselComponent,
  RegistryHintModalComponent,
  SocialLoginButtonsComponent,
];

export function createApollo(httpLink: HttpLink, alertMessageService: AlertMessageService) {
  const http = httpLink.create({uri: sur(SurEndpoints.Url)});
  const logoutLink = onError(apolloError => {
    if (isDevMode) {
      console.log('Caught from Apollo', apolloError);
    }
    if (apolloError) {
      if (apolloError.graphQLErrors) {
        for (const err of apolloError.graphQLErrors) {
          translateCodesToMessage(err, alertMessageService);
        }
      }
      if (apolloError.networkError) {
        alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET), [apolloError.networkError.message]);
      }
    }
  });

  return {
    link: logoutLink.concat(http),
    cache: new InMemoryCache(),
  };
}

function translateCodesToMessage(err: any, alertMessageService: AlertMessageService) {
  if (err.message.includes('br.com.bodytech.core.backend.Address.zipcode.invalid')) {
    alertMessageService.showToastr(MessageMap.CEP_INVALIDO, null, AlertMessageType.WARNING);
  } else {
    alertMessageService.showToastr(MessageMap.ERRO_PROCESSAMENTO, [err.message], AlertMessageType.WARNING);
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    GalleryModule.forRoot(),
    DeferLoadModule,
    ModalModule,
    NgSelectModule,
    PopoverFadeModule,
    SwiperModule,
    OpenModalPaddingModule,
  ],
  declarations: [
    ...directives,
    ...globalComponents,
    ...modalComponents,
    ...pipes,
    ...sharedComponents,
    ModalSingleSelectComponent,
  ],
  exports: [
    ...directives,
    ...globalComponents,
    ...modalComponents,
    ...pipes,
    ...sharedComponents,
    DeferLoadModule,
  ],
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: APOLLO_OPTIONS,
          useFactory: createApollo,
          deps: [HttpLink, AlertMessageService],
        },
        ...services,
        ...guards,
        ...pipes,
      ],
    };
  }

}
