import * as i0 from "@angular/core";
import * as i1 from "./debug-view-id.component";
var styles_DebugViewIdComponent = [];
var RenderType_DebugViewIdComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DebugViewIdComponent, data: {} });
export { RenderType_DebugViewIdComponent as RenderType_DebugViewIdComponent };
export function View_DebugViewIdComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" <", "", ">\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.mouseTarget == null) ? null : _co.mouseTarget.localName); var currVal_1 = (((_co.mouseTarget == null) ? null : _co.mouseTarget.id) ? ((" id=\"" + _co.mouseTarget.id) + "\"") : ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_DebugViewIdComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-debug-view-id", [], null, [["window", "mouseover"]], function (_v, en, $event) { var ad = true; if (("window:mouseover" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).enter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DebugViewIdComponent_0, RenderType_DebugViewIdComponent)), i0.ɵdid(1, 49152, null, 0, i1.DebugViewIdComponent, [], null, null)], null, null); }
var DebugViewIdComponentNgFactory = i0.ɵccf("app-debug-view-id", i1.DebugViewIdComponent, View_DebugViewIdComponent_Host_0, {}, {}, []);
export { DebugViewIdComponentNgFactory as DebugViewIdComponentNgFactory };
