import * as i0 from "./footer-bottom-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer-bottom-banner.component";
var styles_FooterBottomBannerComponent = [i0.styles];
var RenderType_FooterBottomBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterBottomBannerComponent, data: {} });
export { RenderType_FooterBottomBannerComponent as RenderType_FooterBottomBannerComponent };
function View_FooterBottomBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "footer-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }); }
export function View_FooterBottomBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "btcompany-banner text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "https://www.bodytech.com.br"], ["id", "footer-home"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Bodytech"], ["src", "assets/footer/img-logo-bodytech.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterBottomBannerComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_FooterBottomBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-bottom-banner", [], null, null, null, View_FooterBottomBannerComponent_0, RenderType_FooterBottomBannerComponent)), i1.ɵdid(1, 49152, null, 0, i3.FooterBottomBannerComponent, [], null, null)], null, null); }
var FooterBottomBannerComponentNgFactory = i1.ɵccf("app-footer-bottom-banner", i3.FooterBottomBannerComponent, View_FooterBottomBannerComponent_Host_0, { data: "data" }, {}, []);
export { FooterBottomBannerComponentNgFactory as FooterBottomBannerComponentNgFactory };
