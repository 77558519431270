import { BreakpointsService } from '@services/breakpoints.service';
import {
  Directive,
  ElementRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appWidthFromWindow]',
})
export class WidthFromWindowDirective implements OnDestroy {
  private subs: Subscription;

  constructor(
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2,
    private readonly breakpointsService: BreakpointsService,
  ) {
    this.subs = this.breakpointsService.emitterEvery.subscribe(() => {
      this.renderer.setStyle(this.elem.nativeElement, 'width', `${window.innerWidth}px`);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
