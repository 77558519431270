import { StringUtil } from '@utils/string-util';
import { GymService } from './gym.service';
import { BenefitService } from './benefit.service';
import { ActivityService } from './activity.service';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Router, NavigationEnd } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, mergeMap } from 'rxjs/operators';

import { GymResponse, Gym, GymSearchQuery, ResultType, GymActivity, GymConvenience, GymBenefit, OrderType, PhotoTypeEnum } from '@models';
import { FusePipe } from '@pipes';
import { GymSearchAbstractService } from './gym-search-abstract.service';

@Injectable()
export class GymSearchCacheService extends GymSearchAbstractService {

  private cacheGyms: Gym[] = null;
  private cacheActivities: GymActivity[] = null;
  private cacheBenefits: GymConvenience[] = null;

  constructor(
    protected readonly apollo: Apollo,
    protected readonly http: HttpClient,
    private readonly router: Router,
    private readonly activityService: ActivityService,
    private readonly benefitService: BenefitService,
  ) {
    super(apollo);
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.cacheGyms = null;
      }
    });
  }

  clearCache() {
    this.cacheGyms = null;
  }

  getGymNames(): Observable<any[]> {
    return this._getGymNames();
  }

  private distance(x1: number, y1: number, x2: number, y2: number): number {
    const a = Math.abs(x1 - x2);
    const b = Math.abs(y1 - y2);
    return Math.sqrt(a ** 2 + b ** 2);
  }

  private filterGymsByGymSearchQuery(filterParam: GymSearchQuery): Gym[] {
    const filtered = this.cacheGyms.filter(gym => {

      if (filterParam.minPrice &&
        gym.unitGroupPlan.map(groupPlan => groupPlan.plan.gymUnitPeriodicities.every(p => p.subscriptionFee < filterParam.maxPrice))) {
        return false;
      }

      if (filterParam.maxPrice &&
        gym.unitGroupPlan.map(groupPlan => groupPlan.plan.gymUnitPeriodicities.every(p => p.subscriptionFee < filterParam.maxPrice))) {
        return false;
      }

      const servicesId = gym.gymUnitServices.map(s => s.service.id);
      if (filterParam.services &&
          filterParam.services.length &&
          !filterParam.services.every(s => servicesId.includes(s))) {
        return false;
      }

      const activitiesId = gym.activities.map(a => a.id);
      if (filterParam.activities &&
        filterParam.activities.length &&
        !filterParam.activities.every(a => activitiesId.includes(a))) {
        return false;
      }

      return true;
    });

    if (JSONUtil.exists(filterParam.lat) && JSONUtil.exists(filterParam.lon)) {
      const lat = parseFloat(filterParam.lat);
      const lon = parseFloat(filterParam.lon);
      filtered.forEach(gym => {
        gym.distanceForSearch = this.distance(lat, lon, gym.address.latitudeN, gym.address.longitudeN);
      });
      filtered.sort((a, b) => a.distanceForSearch - b.distanceForSearch);
    } else {
      filtered.sort(StringUtil.strcmpName);
    }

    return filtered;
  }

  private paginate(gyms: Gym[], pageLength: number, offset: number): Gym[] {
    if (gyms.length < offset) {
      return [];
    } else {
      return gyms.slice(offset, Math.min((gyms.length - 1), (offset + pageLength)));
    }
  }

  private getActivities(): Observable<GymActivity[]> {
    if (this.cacheActivities) {
      return of(this.cacheActivities);
    } else {
      return this.activityService.getActiveActivities()
        .pipe(tap(val => this.cacheActivities = val));
    }
  }

  private getBenefits(): Observable<GymConvenience[]> {
    if (this.cacheActivities) {
      return of(this.cacheBenefits);
    } else {
      return this.benefitService.getBenefits()
        .pipe(tap(val => this.cacheBenefits = val));
    }
  }

  searchBy(filterParam: GymSearchQuery, pageLength: number, page: number): Observable<GymResponse> {
    filterParam['max'] = pageLength;
    filterParam['offset'] = page * pageLength;
    if (this.cacheGyms) {
      const filtered = this.filterGymsByGymSearchQuery(filterParam);
      const results = this.paginate(filtered, pageLength, filterParam.offset);
      return of({
        results,
        more: filtered.length > ((page + 1) * pageLength),
        total: filtered.length,
        resultType: 'search' as ResultType,
        orderType: JSONUtil.exists(filterParam.lat) && JSONUtil.exists(filterParam.lon) ? 'location.user' : 'alphabetically' as OrderType,
      });
    } else {

      const getGyms = this.apollo.query<Gym[]>({
        query: AppGraphql.listGymUnitsByStateCityAndNeighborhood,
        variables: {
          stateInitials: filterParam.stateInitials || 'sp',
          city: filterParam.city,
          neighborhood: filterParam.neighborhood,
        },
      }).pipe(
        map(JSONUtil.turnApolloMutable<Gym[]>('listGymUnitsByStateCityAndNeighborhood')),
      );

      return forkJoin([
        this.getActivities(),
        this.getBenefits(),
        getGyms,
      ]).pipe(
        tap(([_, benefits, gyms]) => {
          const benefitsMap: {[key: number]: GymBenefit} = {};
          benefits.forEach(b => benefitsMap[b.id] = b);
          gyms.forEach(gym => {
            gym.gymUnitServices.forEach(s => s.service = benefitsMap[s.service.id]);
            const photos = gym.photos
                            .filter(p => p.objectType === PhotoTypeEnum.THUMBNAIL)
                            .sort((a, b) => a.displayOrder - b.displayOrder);
            if (photos && photos.length) {
              gym.thumbnail = photos[0];
            }
            GymService.preFormatGym(gym);
          });

          this.cacheGyms = gyms;
        }),
        mergeMap(() => this.searchBy(filterParam, pageLength, page)),
      );
    }
  }

  searchGymNames(text: string): Observable<Gym[]> {
    return this.getGymNames()
    .pipe(
      map(value => new FusePipe().transform(value, text, { keys: ['name'] })),
    );
  }

}
