import * as i0 from "./navbar-user-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/directives/clickable.directive";
import * as i5 from "../../../shared/directives/dropdown-arrows.directive";
import * as i6 from "../../../shared/components/collapse/collapse.component.ngfactory";
import * as i7 from "../../../shared/components/collapse/collapse.component";
import * as i8 from "./navbar-user-menu.component";
import * as i9 from "../../../shared/services/auth.service";
var styles_NavbarUserMenuComponent = [i0.styles];
var RenderType_NavbarUserMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarUserMenuComponent, data: {} });
export { RenderType_NavbarUserMenuComponent as RenderType_NavbarUserMenuComponent };
function View_NavbarUserMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "list-group-item list-group-item-action"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.url; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "navbar-user-", _v.context.index, ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.text; _ck(_v, 2, 0, currVal_4); }); }
export function View_NavbarUserMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "nav-item d-lg-flex d-none"], ["id", "navbar-user-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["userLinkItem", 1]], null, 10, "div", [["appClickable", ""], ["appDropdownArrows", ""], ["aria-controls", "userMenu"], ["aria-label", "Toggle navigation"], ["class", "light"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 81920, null, 0, i4.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵdid(3, 81920, null, 0, i5.DropdownArrowsDirective, [i1.ElementRef, i1.Renderer2], { appDropdownArrows: [0, "appDropdownArrows"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "a", [["class", "nav-link user-link d-flex align-items-center"], ["id", "navbar-user-link"], ["routerLink", "/cliente"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(6, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "d-none d-xl-block user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "Perfil do usu\u00E1rio"], ["class", "user-pic rounded-circle"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["app-collapse", ""], ["class", "user-menu position-absolute text-center app-collapse"], ["id", "userMenu"]], [[2, "app-collapse-expanded", null], [2, "expanded", null], [2, "show", null], [2, "app-collapse-collapsed", null], [2, "collapsed", null], [40, "@bodyExpansion", 0]], [[null, "mouseleave"], [null, "click"], ["component", "@bodyExpansion.done"], ["component", "@bodyExpansion.start"]], function (_v, en, $event) { var ad = true; if (("component:@bodyExpansion.done" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._bodyAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@bodyExpansion.start" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13)._bodyAnimation($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13).close() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13).close() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i6.View_CollapseComponent_0, i6.RenderType_CollapseComponent)), i1.ɵdid(13, 49152, [["collapse", 4]], 0, i7.CollapseComponent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 0, "div", [["class", "arrow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavbarUserMenuComponent_1)), i1.ɵdid(16, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "a", [["appClickable", ""], ["class", "list-group-item list-group-item-action text-primary"], ["id", "navtop-user-logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 81920, null, 0, i4.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); var currVal_5 = "/cliente"; _ck(_v, 5, 0, currVal_5); var currVal_6 = "active"; _ck(_v, 6, 0, currVal_6); var currVal_15 = _co.navbarUserMenu; _ck(_v, 16, 0, currVal_15); var currVal_16 = ""; _ck(_v, 18, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 13).expanded; _ck(_v, 1, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 5).target; var currVal_4 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_7 = (_co.userName || "Usu\u00E1rio"); _ck(_v, 10, 0, currVal_7); var currVal_8 = (_co.userAvatar || "assets/images/default-user-image.png"); _ck(_v, 11, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 13).expanded; var currVal_10 = i1.ɵnov(_v, 13).expanded; var currVal_11 = i1.ɵnov(_v, 13).expanded; var currVal_12 = !i1.ɵnov(_v, 13).expanded; var currVal_13 = !i1.ɵnov(_v, 13).expanded; var currVal_14 = i1.ɵnov(_v, 13)._getExpandedState(); _ck(_v, 12, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_NavbarUserMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar-user-menu", [], null, null, null, View_NavbarUserMenuComponent_0, RenderType_NavbarUserMenuComponent)), i1.ɵdid(1, 114688, null, 0, i8.NavbarUserMenuComponent, [i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarUserMenuComponentNgFactory = i1.ɵccf("app-navbar-user-menu", i8.NavbarUserMenuComponent, View_NavbarUserMenuComponent_Host_0, { navbarUserMenu: "navbarUserMenu" }, {}, []);
export { NavbarUserMenuComponentNgFactory as NavbarUserMenuComponentNgFactory };
