import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';
import { of } from 'rxjs';
import { oldCanaisErrorMessages, ErrorMessage } from '@utils/app-errors';

export class Alert {
  public static readonly I = 'info';
  public static readonly S = 'success';
  public static readonly W = 'warning';
  public static readonly D = 'danger';

  constructor(
    public title = 'Title',
    public message = 'Message',
    public type: string = Alert.I,
    public timeout = 0,
    public when: Date = new Date(),
    public timer = -1,
  ) {
    console.warn('Do not use `AlertService`, use `AlertMessageService`');
  }

  icon() {
    switch (this.type) {
      case Alert.S: return 'check';
      case Alert.I: return 'info';
      case Alert.W: return 'exclamation-triangle';
      case Alert.D: return 'exclamation-circle';
      default: return 'info';
    }
  }

}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class AlertService {

  static readonly defaultTimeout = 3200;
  static readonly defaultOvertime = 2000;
  static readonly maxAlerts = 3;

  public messages: Alert[] = [];
  public alertsObs = of(this.messages);

  static genericError(reason: string) {
    const arr = reason.split(' ');
    return {
      code: arr.splice(0, 1).join(' '),
      message: arr.join(' '),
    };
  }

  constructor(
    private readonly loadingService: LoadingService,
  ) { }

  /**
   * timeout > 0 => fades out after timeout milliseconds
   * timeout = 0 => only fades out after is being given attention (mouse over)
   * timeout < 0 => never fades out, only dismissable by click
   */
  create(type: string, title: string, body: string, timeout?: number, overtime?: number) {
    const msg = new Alert(title, body, type, timeout);
    this.show(msg, overtime);
  }

  show(alert: Alert, overtime = AlertService.defaultOvertime) {
    this.messages.push(alert);
    if (this.messages.length > AlertService.maxAlerts) {
      this.messages.shift();
    }

    this.startTimer(alert);
    if (alert.timeout >= 0) {
      alert.timeout = overtime;
    }
  }

  startTimer(msg: Alert) {
    if (msg.timeout > 0) {
      msg.timer = window.setTimeout(() => this.dismiss(msg), msg.timeout);
    }
  }

  stopTimer(msg: Alert) {
    window.clearTimeout(msg.timer);
    msg.timer = -1;
  }

  dismiss(msg: Alert) {
    const index = this.messages.indexOf(msg);
    if (index > -1) {
      this.messages.splice(index, 1);
    }
  }

  oldCanaisErrorHandle = (err: any) => {
    if (err) {
      const reason: string = err.statusText || 'Generic error';
      const mappedError = this.translateOldCanaisErrors(reason);
      this.create(Alert.D, 'Ocorreu um erro no servidor', mappedError.message);
    }

    this.loadingService.stopLoading();
    return [err];
  }

  translateOldCanaisErrors(reason: string): ErrorMessage {
    return oldCanaisErrorMessages.find(error => reason.startsWith(error.code)) || AlertService.genericError(reason);
  }

}
