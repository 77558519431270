import { HttpClient } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { SimpleLink } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HeaderService {

  public template = new BehaviorSubject<TemplateRef<any>>(null);

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getHeaderLinks(): Observable<SimpleLink[]> {
    return this.http.get<SimpleLink[]>('assets/data/header-links.json');
  }

  public getHeaderLinksMobile(): Observable<SimpleLink[]> {
    return this.http.get<SimpleLink[]>('assets/data/header-links-mobile.json');
  }

  public getHeaderLinksLogged(): Observable<SimpleLink[]> {
    return this.http.get<SimpleLink[]>('assets/data/header-links-logged.json');
  }

}
