
export class StringUtil {
  static compareInsensitive(a: string, b: string) {
    a = a || '';
    b = b || '';
    return a.toLowerCase().includes(b.toLowerCase());
  }

  static strnormalize(s: string) {
    return s.trim().toUpperCase();
  }

  static strcmp(a: string, b: string) {
    if (a === undefined) {
      return b === undefined ? 0 : 1;
    }
    return a.localeCompare(
      b,
      'pt',
      {
        sensitivity: 'base',
        ignorePunctuation: true,
        numeric: true,
      },
    );
  }

  static strcmpName = (a: any, b: any) => StringUtil.strcmp(a.name, b.name);


}
