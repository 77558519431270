import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { isDevMode, ModuleWithProviders } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { AlertMessage } from '@models';
import { guards } from '@guards';
import { sur } from '@utils/app-endpoints';
import { pipes } from '@pipes';
import { services } from '@services';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthInterceptor } from './auth.interceptor';
import { AgendaFilterComponent } from './components/agenda-filter/agenda-filter.component';
import { AlertComponent } from './components/alert/alert.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { DataAddressComponent } from './components/data-address/data-address.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FaqbotComponent } from './components/faqbot/faqbot.component';
import { GymSearchInputComponent } from './components/gym-search-input/gym-search-input.component';
import { HeaderStaticImageComponent } from './components/header-static-image/header-static-image.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { RedirectWithParameterComponent } from './components/redirect-with-parameter/redirect-with-parameter.component';
import { RegistryHintModalComponent } from './components/registry-hint-modal/registry-hint-modal.component';
import { SelectComponent } from './components/select/select.component';
import { SliderComponent } from './components/slider/slider.component';
import { SocialLoginButtonsComponent } from './components/social-login-buttons/social-login-buttons.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { EmptyCardComponent } from './components/empty-card/empty-card.component';
registerLocaleData(localePt, 'pt-BR');
var globalComponents = [
    AlertComponent,
    CheckboxComponent,
    CollapseComponent,
    EmptyCardComponent,
    FaqbotComponent,
    HeaderStaticImageComponent,
    LoadingComponent,
    PaginationComponent,
    RadiobuttonComponent,
    RecaptchaComponent,
    RedirectWithParameterComponent,
    SelectComponent,
    SliderComponent,
    SuccessPageComponent,
    TabsComponent,
];
var sharedComponents = [
    AgendaFilterComponent,
    DataAddressComponent,
    ErrorMessageComponent,
    GymSearchInputComponent,
    ImageCarouselComponent,
    RegistryHintModalComponent,
    SocialLoginButtonsComponent,
];
export function createApollo(httpLink, alertMessageService) {
    var http = httpLink.create({ uri: sur("/graphql") });
    var logoutLink = onError(function (apolloError) {
        if (isDevMode) {
            console.log('Caught from Apollo', apolloError);
        }
        if (apolloError) {
            if (apolloError.graphQLErrors) {
                for (var _i = 0, _a = apolloError.graphQLErrors; _i < _a.length; _i++) {
                    var err = _a[_i];
                    translateCodesToMessage(err, alertMessageService);
                }
            }
            if (apolloError.networkError) {
                alertMessageService.showToastr(AlertMessage.error(999), [apolloError.networkError.message]);
            }
        }
    });
    return {
        link: logoutLink.concat(http),
        cache: new InMemoryCache(),
    };
}
function translateCodesToMessage(err, alertMessageService) {
    if (err.message.includes('br.com.bodytech.core.backend.Address.zipcode.invalid')) {
        alertMessageService.showToastr(11, null, "W");
    }
    else {
        alertMessageService.showToastr(28, [err.message], "W");
    }
}
var SharedModule = (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                },
                {
                    provide: APOLLO_OPTIONS,
                    useFactory: createApollo,
                    deps: [HttpLink, AlertMessageService],
                }
            ].concat(services, guards, pipes),
        };
    };
    return SharedModule;
}());
export { SharedModule };
