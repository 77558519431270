export class ArrayUtil {

  static insertIntoIndex<T>(array: T[], index: number, value: T) {
    array.splice(index, 0, value);
  }

  static removeItem<T>(array: T[], item: T) {
    if (array.includes(item)) {
      array.splice(array.indexOf(item), 1);
    }
  }

  static removeRepeatedItems<T>(array: T[]): T[] {
    return array.filter((value, index) => array.indexOf(value) === index);
  }

  static mapToArray<T>(map: T[]): T[] {
    return map.filter(val => Boolean(val));
  }

  static safePushIntoMap<T>(map: T[][], index: any, value: T) {
    if (!map[index]) {
      map[index] = [];
    }
    map[index].push(value);
  }

  static getFirstNonNullElementFromMap<T>(map: T[][]): T {
    return map.find(d => d.length > 0)[0];
  }

  static padMapToMaxLenght<T>(map: T[][], pad = {}): T[][] {
    const maxLenght = map.reduce((max, value) => max = Math.max(max, value.length), 0);
    map.forEach(value =>
      value.splice(value.length, 0, ...Array(maxLenght - value.length).fill(pad)),
    );
    return map;
  }

}
