import { Injectable } from '@angular/core';
import { GymBenefit } from '@models';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AppGraphql } from '@utils/app-graphql';

@Injectable()
export class BenefitService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getBenefits() {
    return this.apollo.query<GymBenefit[]>({
      query: AppGraphql.queryServiceList,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<GymBenefit[]>('listServicesRedis')),
    );
  }
}
