<div class="modal fade" bsModal #templateModal="bs-modal"
    (onHidden)="dismiss()"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100 pull-left">
          <div class="m-0 title-section no-bottom-line">
            {{ modalObj.title }}
          </div>
          <div class="m-0">{{ modalObj.event.teacherName | capitalize }}</div>
        </div>
        <button type="button" class="d-none close pull-right" aria-label="Close" (click)="templateModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="info-content" *ngIf="modalObj.event.audiences.length">
          <div class="title">Público</div>
          <div class="list-inline">
            <div *ngFor="let audience of modalObj.event.audiences">
              {{ audience.name }} ({{ audience.description }})
            </div>
          </div>
        </div>
        <div class="info-content" *ngIf="modalObj.event.targetAudienceLevel">
          <div class="title">Nível</div>
          <div class="list-inline">
            <div *ngFor="let level of modalObj.event.targetAudienceLevelList">
              {{ level }}
            </div>
          </div>
        </div>
        <div class="info-content" *ngIf="
        modalObj.event.intensity.domain13Description ||
        modalObj.event.intensity.domain14Description ||
        modalObj.event.intensity.domain15Description ||
        modalObj.event.intensity.domain16Description">
          <div class="title">Tipo de Aula</div>
          <div>{{ modalObj.event.intensity.domain13Description }}</div>
          <div>{{ modalObj.event.intensity.domain14Description }}</div>
          <div>{{ modalObj.event.intensity.domain15Description }}</div>
          <div>{{ modalObj.event.intensity.domain16Description }}</div>
        </div>
        <div class="info-content" *ngIf="modalObj.event.itemGridPlace">
          <div class="title">Local</div>
          <div>{{ modalObj.event.itemGridPlace }}</div>
        </div>
        <div class="date-container row no-gutters text-center border bg-light">
          <div class="col border-right">
            <div class="title">Duração</div>
            <div>{{ modalObj.event.duration }} min</div>
          </div>
          <div class="col">
            <div class="title">Hora início</div>
            <div>{{ modalObj.event.activityInitialTimeFormatted }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary'}}"
          (click)="confirm()">
          {{ modalObj.confirmButton || 'OK' }}
        </button>
      </div>
    </div>
  </div>
</div>
