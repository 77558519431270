import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {

  @Input() name = '';
  @Input() check = false;
  @Output() checkChange = new EventEmitter<boolean>();
  @Input() message = '';

  toggle() {
    this.check = !this.check;
    this.checkChange.emit(this.check);
  }

}
