import * as i0 from "./route-test.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/clickable.directive";
import * as i3 from "ngx-bootstrap/popover/popover.config";
import * as i4 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i5 from "ngx-bootstrap/positioning/positioning.service";
import * as i6 from "../../component-modules/popover-fade/popover-fade.directive";
import * as i7 from "./route-test.component";
import * as i8 from "@angular/router";
import * as i9 from "../../shared/services/alert-message.service";
import * as i10 from "@angular/common/http";
import * as i11 from "../../shared/services/auth.service";
import * as i12 from "apollo-angular";
var styles_RouteTestComponent = [i0.styles];
var RenderType_RouteTestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RouteTestComponent, data: {} });
export { RenderType_RouteTestComponent as RenderType_RouteTestComponent };
export function View_RouteTestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 44, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "small", [["class", "pr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-success"], ["popoverFade", "Load all routes"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.navigateToIndex(0) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(7, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-tasks"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-danger"], ["popoverFade", "Load not found"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.navigateToNotFound() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(13, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-bug"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-danger"], ["popoverFade", "Request file error"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.getUnknownFile() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(19, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fas fa-question"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-success"], ["popoverFade", "Request app token"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 25).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.getToken() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(25, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "fas fa-shield-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-danger"], ["popoverFade", "Generate a 403"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 31).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.setInvalidToken() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(31, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 0, "i", [["class", "fas fa-outdent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-info"], ["popoverFade", "Request agenda"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 37).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 37).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.getAgenda() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(37, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(38, 0, null, null, 0, "i", [["class", "fas fa-calendar-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 16777216, null, null, 5, "div", [["appClickable", ""], ["class", "badge alert-info"], ["popoverFade", "Test apollo"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 43).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 43).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.getApollo() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 81920, null, 0, i2.ClickableDirective, [i1.ElementRef, i1.Renderer2], { appClickable: [0, "appClickable"] }, null), i1.ɵprd(512, null, i3.PopoverConfig, i3.PopoverConfig, []), i1.ɵprd(512, null, i4.ComponentLoaderFactory, i4.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i5.PositioningService, i1.ApplicationRef]), i1.ɵdid(43, 212992, null, 0, i6.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.PopoverConfig, i4.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(44, 0, null, null, 0, "i", [["class", "fas fa-ticket-alt"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); var currVal_1 = "Load all routes"; _ck(_v, 7, 0, currVal_1); var currVal_2 = ""; _ck(_v, 10, 0, currVal_2); var currVal_3 = "Load not found"; _ck(_v, 13, 0, currVal_3); var currVal_4 = ""; _ck(_v, 16, 0, currVal_4); var currVal_5 = "Request file error"; _ck(_v, 19, 0, currVal_5); var currVal_6 = ""; _ck(_v, 22, 0, currVal_6); var currVal_7 = "Request app token"; _ck(_v, 25, 0, currVal_7); var currVal_8 = ""; _ck(_v, 28, 0, currVal_8); var currVal_9 = "Generate a 403"; _ck(_v, 31, 0, currVal_9); var currVal_10 = ""; _ck(_v, 34, 0, currVal_10); var currVal_11 = "Request agenda"; _ck(_v, 37, 0, currVal_11); var currVal_12 = ""; _ck(_v, 40, 0, currVal_12); var currVal_13 = "Test apollo"; _ck(_v, 43, 0, currVal_13); }, null); }
export function View_RouteTestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-debug-route-test", [], null, null, null, View_RouteTestComponent_0, RenderType_RouteTestComponent)), i1.ɵdid(1, 49152, null, 0, i7.RouteTestComponent, [i8.Router, i9.AlertMessageService, i10.HttpClient, i11.AuthService, i12.Apollo], null, null)], null, null); }
var RouteTestComponentNgFactory = i1.ɵccf("app-debug-route-test", i7.RouteTestComponent, View_RouteTestComponent_Host_0, {}, {}, []);
export { RouteTestComponentNgFactory as RouteTestComponentNgFactory };
