import { environment } from '@env/environment';

const genericEndpointInterpolation = (baseurl: string, path: string, params: Array<string | number> = []): string => {
  const interpoled = params.reduce<string>((old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()), path);
  return baseurl + interpoled;
};

export const enum BackEndpoints { // MUST start with a slash (/)
  Root = '/',
  AppToken = '/Aut/Canal/Token',
  Login = '/Autenticacao/Usuario/',
  Logout = '/Autenticacao/Usuario/logoff/',
  ResetUserAccessToken = '/Autenticacao/Usuario/reset',
  Grade = '/Grade/',
  Gym = '/Unidades/Publicadas/',
  Nation = '/Nacionalidade/',
  ProfileLists = '/DadosPessoais/',
  Relationship = '/Parentesco/',
  UserData = '/cliente/ConsultarDados/',
  Training = '/cliente/Treinos/?incluirdetalhe=true',
  Contract = '/cliente/Contratos/',
  Purchase = '/cliente/HistoricoFinanceiro/',
  PreSalePurchase = '/prevenda/cliente/consultardetalhes/',
  UpdateUser = '/cliente/DadosCadastrais/',
  ValidateCPF = '/cliente/validacaocpf/',
  ValidateTokenForeignMinor = '/cliente/validar/',
  NewAccess = '/usuario/cadastro/',
  UpdateAccount = '/usuario/AtualizarDadosAcesso/',
  RecoveryPassword = '/Usuario/AtualizarSenha/EnviarEmail',
  RecoveryPasswordFinish = '/Usuario/AtualizarSenha',
  RecoveryEmail = '/Usuario',
  ResetUserAccess = '/Usuario/Reset',
  ConfirmationNewAccount = '/autenticacao/usuario/confirmacao',
  UserValidatePreSale = '/usuario/ValidarUsuarioPreVenda',
  ResendBlockedEmail = '/Usuario/Reenviocadastro',
  ContractStart = '/contrato/cancelamento/',
  ContractPayment = '/contrato/cancelamento/formasPagamento/',
  ContractFinish = '/contrato/cancelamento/finaliza/',

  PreSaleCancel = '/prevenda/cliente/cancelamento/',

  CheckOperations = '/Cliente/OperacoesEcommerce/',

  // Novo Cliente : Passo 1
  RegistrationStart = '/Compra/Planos/',
  RegistrationItem = '/Compra/Cesta/',
  RegistrationCheckPayment = '/Compra/FormasPagamento/',
  // Novo Cliente : Passo 2
  RegistrationSendPersonal = '/Compra/DadosCadastro/',
  RegistrationSendAddress = '/Compra/DadosEndereco/',
  RegistrationFinish = '/Compra/Finaliza/',
  // Venda : Passo 1
  PurchaseStart = '/Cliente/Compra/Planos/',
  PurchaseItem = '/Compra/Cliente/Cesta/',
  PurchaseCheckPayment = '/Compra/Cliente/FormasPagamento/',
  // Venda : Passo 2
  PurchaseFinish = '/Compra/Cliente/Finaliza/',

  // Cupom
  Coupon = '/Compra/Cupom/',
  CuponCliente = '/Compra/Cliente/Cupom/',

  // Pre-venda : Novo Cliente
  PreSaleNewClientStart = '/prevenda/novocliente/planos/',
  PreSaleNewClientFinish = '/prevenda/novocliente/compra',

  // Pre-venda : Cliente
  PreSaleStart = '/prevenda/cliente/planos/',
  PreSaleClientFinish = '/prevenda/cliente/compra',

}

export const back = (path: BackEndpoints, ...params: Array<string | number>): string => {
  return genericEndpointInterpolation(environment.services.baseUrl, path, params);
};

export const enum BlogEndpoints { // MUST start with a slash (/)
  ApiUrl = '/wp-json/wp/v2',
  Post = '/posts/{{0}}',
  Media = '/media/{{0}}',
  Posts = '/posts',
}

export const blog = (path: BlogEndpoints, ...params: Array<string | number>): string => {
  return genericEndpointInterpolation(`${environment.wordpress.baseUrl}${BlogEndpoints.ApiUrl}`, path, params);
};

export class ExternalEndpoints { // MUST be the entire URL, including the protocol (e.g. https://my.domain.com/bla)
  public static readonly backFacebook = 'https://graph.facebook.com/{{0}}/picture?width=143&height=143';
}

export const external = (path: string, ...params: Array<string | number>): string => {
  return genericEndpointInterpolation('', path, params);
};

export const enum SurEndpoints { // MUST start with a slash (/)
  Root = '/',
  AppToken = '/auth',
  CorporateForm = '/corporateForm/',
  FaqBot = '/faq',
  Url = '/graphql',
  UrlGymUnit = '/gymUnit',
  UrlSendForgotPasswordEmail = '/userChannel/sendForgotPasswordEmail',
  UrlSendRandomPasswordEmail = '/userChannel/sendRandomPasswordEmail',
  UrlSendUnblockEmail = '/userChannel/sendUnblockEmail',
  UrlUnblockEmail = '/userChannel/unblock/{{0}}',
  UrlUpdateUserPassword = '/userChannel/updatePassword',
}

export const sur = (path: SurEndpoints, ...params: Array<string | number>): string => {
  return genericEndpointInterpolation(environment.apollo.baseUrl, path, params);
};

// tslint:disable-next-line:max-classes-per-file
export class AppEndpoints {
  public static get(value = '', ...params: Array<string|number>) {
    // Replaces {{i}} with params[i]
    return params.reduce<string>((old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()), value);
  }
}
