import { OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { of, merge } from 'rxjs';
import { map } from 'rxjs/operators';
var ErrorMessageComponent = (function () {
    function ErrorMessageComponent() {
        this.listError = [];
        this.config = {
            pattern: "Formato incorreto",
            max: "O valor ultrapassa o m\u00E1ximo permitido",
            min: "O valor \u00E9 menor que o m\u00EDnimo permitido",
            maxLength: "O campo ultrapassa o n\u00FAmero m\u00E1ximo de caracteres",
            minlength: "O campo n\u00E3o possui o valor m\u00EDnimo de caracteres",
            minLength: "O campo n\u00E3o possui o valor m\u00EDnimo de caracteres",
            email: "E-mail inv\u00E1lido",
            required: "Campo obrigat\u00F3rio",
            cpfNotValid: "CPF informado inv\u00E1lido",
            password: "A senha deve ser entre 6 e 12 caracteres contendo letras e n\u00FAmeros",
        };
    }
    Object.defineProperty(ErrorMessageComponent.prototype, "messages", {
        set: function (newConfig) {
            Object.assign(this.config, newConfig);
        },
        enumerable: true,
        configurable: true
    });
    ErrorMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        var formSubmit$ = this.control.formDirective.ngSubmit.pipe(map(function () {
            _this.hasSubmitted = true;
            return false;
        }));
        this.controlValue$ = merge(this.control.valueChanges, of(''), formSubmit$);
        this.controlValue$.subscribe(function () { return _this.setVisible(); });
    };
    ErrorMessageComponent.prototype.setVisible = function () {
        if (!this.control) {
            return;
        }
        if (this.control.invalid && (this.control.dirty || this.hasSubmitted)) {
            this.load();
        }
        else {
            this.listError = [];
        }
    };
    ErrorMessageComponent.prototype.load = function () {
        var _this = this;
        this.listError = Object.keys(this.control.errors)
            .map(function (keyError) { return _this.config[keyError]; });
    };
    return ErrorMessageComponent;
}());
export { ErrorMessageComponent };
