import * as i0 from "./modal-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/modal/modal.directive";
import * as i4 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i5 from "./modal-confirm.component";
import * as i6 from "../../../services/modal.service";
var styles_ModalConfirmComponent = [i0.styles];
var RenderType_ModalConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalConfirmComponent, data: {} });
export { RenderType_ModalConfirmComponent as RenderType_ModalConfirmComponent };
function View_ModalConfirmComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "col btn btn-outline-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.modalObj.cancelButton || "Cancelar"); _ck(_v, 1, 0, currVal_0); }); }
function View_ModalConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-dialog modal-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-header-title title text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "modal-body text-justify"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalConfirmComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.modalObj == null) ? null : _co.modalObj.cancelButton); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalObj.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.modalObj.message; _ck(_v, 5, 0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "col btn btn-", (((_co.modalObj == null) ? null : _co.modalObj.confirmButtonClass) || "primary"), ""); _ck(_v, 9, 0, currVal_3); var currVal_4 = (((_co.modalObj == null) ? null : _co.modalObj.confirmButton) || "OK"); _ck(_v, 10, 0, currVal_4); }); }
export function View_ModalConfirmComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "div", [["aria-hidden", "true"], ["aria-labelledby", "mySmallModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "onHidden"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("onHidden" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, [[1, 4], ["templateModal", 4]], 0, i3.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i4.ComponentLoaderFactory], null, { onHidden: "onHidden" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalConfirmComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.modalObj; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ModalConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-confirm", [], null, null, null, View_ModalConfirmComponent_0, RenderType_ModalConfirmComponent)), i1.ɵdid(1, 245760, null, 0, i5.ModalConfirmComponent, [i6.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalConfirmComponentNgFactory = i1.ɵccf("app-modal-confirm", i5.ModalConfirmComponent, View_ModalConfirmComponent_Host_0, {}, {}, []);
export { ModalConfirmComponentNgFactory as ModalConfirmComponentNgFactory };
