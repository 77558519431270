import * as i0 from "@angular/core";
import * as i1 from "./loading.component";
import * as i2 from "../../services/loading.service";
var styles_LoadingComponent = [];
var RenderType_LoadingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
export function View_LoadingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "loading-wrapper"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "spinner-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "double-bounce1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "double-bounce2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "text-center text-light"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Carregando\u2026"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoadingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i0.ɵdid(1, 114688, null, 0, i1.LoadingComponent, [i2.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingComponentNgFactory = i0.ɵccf("app-loading", i1.LoadingComponent, View_LoadingComponent_Host_0, { visible: "visible" }, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
