import { Component, Input } from '@angular/core';

import { FooterApp } from '@models';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'app-footer-get-app',
  templateUrl: './footer-get-app.component.html',
  styleUrls: ['./footer-get-app.component.scss'],
})
export class FooterGetAppComponent {

  @Input() data: FooterApp;
  public loadImage = false;

  constructor(
    private readonly analyticsService: AnalyticsService,
  ) { }

  clickApp(appOS: string) {
    this.analyticsService.trackEvent(
      `Aplicativo - ${appOS}`,
      'Footer',
      'Aplicativo',
    );
  }

  setLoadImage() {
    setTimeout(() => this.loadImage = true, 0);
  }
}
