import { AnalyticsService } from '@services/analytics.service';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { debounceTime } from 'rxjs/operators';
import { SEOService } from '@services/seo.service';
import { ModalService } from '@services/modal.service';
import { WindowContextService } from '@services/window-context.service';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  loading = false;

  constructor(
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly seoService: SEOService,
    private readonly windowContextService: WindowContextService,
    private readonly modalService: ModalService,
    private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private readonly analyticsService: AnalyticsService,
  ) { }

  ngOnInit() {
    this.analyticsService.init();
    this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2GoogleTagManager.startTracking();
    this.seoService.init();
    this.windowContextService.init();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.startLoading();
        this.modalService.init();
      }
      this.loadingService.stopLoading();
    });

    this.loadingService.loadingEmitter
    .pipe(
      debounceTime(100),
    )
    .subscribe((display: boolean) => this.loading = display);
  }

}
