var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Sentry from '@sentry/browser';
import { ErrorHandler } from '@angular/core';
import { environment } from '@env/environment';
Sentry.init(environment.sentry);
var SentryErrorHandler = (function (_super) {
    __extends(SentryErrorHandler, _super);
    function SentryErrorHandler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error);
    };
    return SentryErrorHandler;
}(ErrorHandler));
export { SentryErrorHandler };
