import { Pipe, PipeTransform } from '@angular/core';
import { GymWorkingPeriod } from '@models';

@Pipe({
  name: 'sortWorkingPeriods',
})
export class SortWorkingPeriodsPipe implements PipeTransform {
  private static readonly expectedSort = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'SÁB', 'DOM', 'FER'];

  transform(workingPeriods: GymWorkingPeriod[]): GymWorkingPeriod[] {
    let extraIndex = SortWorkingPeriodsPipe.expectedSort.length;

    return workingPeriods
    .map((wp): [number, GymWorkingPeriod] => {
      const initials = wp.dayOfTheWeek.substr(0, 3).toUpperCase();
      let idx = SortWorkingPeriodsPipe.expectedSort.indexOf(initials);
      if (idx < 0) {
        idx = extraIndex;
        extraIndex++;
      }
      return [idx, wp];
    })
    .sort((a, b) => a[0] - b[0])
    .map(([_, wp]) => wp);
  }
}
