import * as i0 from "./modal-test.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/popover/popover.config";
import * as i3 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i4 from "ngx-bootstrap/positioning/positioning.service";
import * as i5 from "../../component-modules/popover-fade/popover-fade.directive";
import * as i6 from "@angular/common";
import * as i7 from "./modal-test.component";
import * as i8 from "../../shared/services/modal.service";
var styles_ModalTestComponent = [i0.styles];
var RenderType_ModalTestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalTestComponent, data: {} });
export { RenderType_ModalTestComponent as RenderType_ModalTestComponent };
function View_ModalTestComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [], [[8, "className", 0]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).fadeShow() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).fadeHide() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.showModal(_v.context.$implicit.modal) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.PopoverConfig, i2.PopoverConfig, []), i1.ɵprd(512, null, i3.ComponentLoaderFactory, i3.ComponentLoaderFactory, [i1.ComponentFactoryResolver, i1.NgZone, i1.Injector, i4.PositioningService, i1.ApplicationRef]), i1.ɵdid(3, 212992, null, 0, i5.PopoverFadeDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i2.PopoverConfig, i3.ComponentLoaderFactory, i1.ChangeDetectorRef], { popoverFade: [0, "popoverFade"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.modal.title; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "badge alert-", _v.context.$implicit.class, ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, ""); _ck(_v, 4, 0, currVal_2); }); }
export function View_ModalTestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "small", [["class", "pr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-window-maximize"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalTestComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modals; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ModalTestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-debug-modal-test", [], null, null, null, View_ModalTestComponent_0, RenderType_ModalTestComponent)), i1.ɵdid(1, 49152, null, 0, i7.ModalTestComponent, [i8.ModalService], null, null)], null, null); }
var ModalTestComponentNgFactory = i1.ɵccf("app-debug-modal-test", i7.ModalTestComponent, View_ModalTestComponent_Host_0, {}, {}, []);
export { ModalTestComponentNgFactory as ModalTestComponentNgFactory };
