import { OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { ArrayUtil } from '@utils/array-util';
import { FormatUtils } from '@utils/format-utils';
import { BrandService } from '@services/brand.service';
var AgendaFilterComponent = (function () {
    function AgendaFilterComponent(router, route, modalService, brandService) {
        this.router = router;
        this.route = route;
        this.modalService = modalService;
        this.brandService = brandService;
        this.filterGymActivitiesByGymCache = {};
        this.showPlace = false;
        this.activityGroups = [];
        this.activityTags = [];
        this.ageTags = [];
        this.filter = {
            gym: null,
            ages: [],
            activities: [],
        };
        this.filterChanges = new EventEmitter();
        this.allAges = [];
    }
    AgendaFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.brand = this.brandService.getBrand();
        this.config = this.route.snapshot.data.config;
        this.allGyms = this.route.snapshot.data.gyms;
        this.activityGroups = this.route.snapshot.data.groups || [];
        if (!this.allActivities) {
            this.allActivities = this.route.snapshot.data.activities;
        }
        this.activityGroups = this.filterGroupsByActivities(this.allActivities, this.activityGroups);
        this.activityGroups.forEach(function (v) { return v.activityGroup = { id: -1 }; });
        this.allAges = this.route.snapshot.data.ages.map(function (age) { return ({
            id: age.id,
            name: age.name,
            description: FormatUtils.ageRangeToDescription(age.minimumAge, age.maximumAge),
        }); });
        var queryParamMap = this.route.snapshot.queryParamMap;
        var activities = atob(queryParamMap.get(AppConstants.PARAM_AGENDA_ACTIVITIES)) || '';
        activities.split(',').map(function (a) { return +a; }).forEach(function (activityId) {
            var activity = _this.allActivities.find(function (a) { return a.id === activityId; });
            if (activity) {
                activity.selected = true;
                _this.filter.activities.push(activity);
            }
        });
        this.updateActivityTags();
        var ages = atob(queryParamMap.get(AppConstants.PARAM_AGENDA_AGE_RANGES)) || '';
        ages.split(',').map(function (a) { return +a; }).forEach(function (ageId) {
            var age = _this.allAges.find(function (a) { return a.id === ageId; });
            if (age) {
                age.selected = true;
                _this.filter.ages.push(age);
            }
        });
    };
    AgendaFilterComponent.prototype.showGymSelectModal = function () {
        var _this = this;
        this.modalService.show({
            type: 12,
            title: 'Selecione academia',
            message: '',
            confirmCallback: function (data) {
                if (data !== _this.gym) {
                    _this.clearFilter(false);
                    _this.gym = data;
                    _this.filterChanged();
                }
            },
            gyms: this.allGyms,
            selected: this.gym ? this.gym.id : null,
        });
    };
    AgendaFilterComponent.prototype.showAgeSelectModal = function () {
        var _this = this;
        this.modalService.show({
            type: 9,
            title: 'Faixa etária',
            message: '',
            confirmCallback: function (data) {
                _this.hasModification(_this.filter.ages, data);
                _this.filter.ages = data;
            },
            ages: this.allAges,
        });
    };
    AgendaFilterComponent.prototype.filterGymActivitiesByGym = function (gym, allActivities) {
        if (!this.filterGymActivitiesByGymCache[gym.id]) {
            var activities = gym.activeActivities ? gym.activeActivities : gym.activities;
            if (!activities) {
                return [];
            }
            var ids_1 = activities.map(function (a) { return a.id; });
            var result = allActivities.filter(function (a) { return ids_1.includes(a.id); });
            this.filterGymActivitiesByGymCache[gym.id] = result;
        }
        return this.filterGymActivitiesByGymCache[gym.id];
    };
    AgendaFilterComponent.prototype.filterGroupsByActivities = function (activities, allGroups) {
        return allGroups.filter(function (group) {
            return activities.some(function (act) { return act.activityGroup.id === group.id; });
        });
    };
    AgendaFilterComponent.prototype.showActivitiesSelectModal = function () {
        var _this = this;
        var activities = this.filterGymActivitiesByGym(this.gym, this.allActivities);
        var groups = this.filterGroupsByActivities(activities, this.activityGroups);
        this.modalService.show({
            type: 6,
            title: this.config.activitiesModal.title,
            message: '',
            confirmCallback: function (data) {
                _this.hasModification(_this.filter.activities, data);
                _this.filter.activities = data;
                _this.updateActivityTags();
            },
            activities: activities,
            groups: groups,
            config: this.config.activitiesModal,
        });
    };
    AgendaFilterComponent.prototype.removeFilter = function (list, item) {
        var _this = this;
        item.selected = false;
        ArrayUtil.removeItem(list, item);
        if (list === this.filter.activities) {
            ArrayUtil.removeItem(this.activityTags, item);
            if (item.activityGroup.id < 0) {
                list.filter(function (a) { return a.activityGroup.id === item.id; }).forEach(function (a) { return _this.removeFilter(list, a); });
            }
        }
        this.filterChanged();
    };
    AgendaFilterComponent.prototype.updateActivityTags = function () {
        var _a;
        this.activityTags = [];
        for (var _i = 0, _b = this.activityGroups; _i < _b.length; _i++) {
            var group = _b[_i];
            (_a = this.activityTags).push.apply(_a, this.groupTagsWhenAllChecked(group.id));
        }
    };
    AgendaFilterComponent.prototype.groupTagsWhenAllChecked = function (group) {
        var listActivities = this.filter.activities.filter(function (a) { return a.activityGroup.id === group; });
        if (listActivities.length &&
            listActivities.length === this.allActivities.filter(function (a) { return a.activityGroup.id === group; }).length) {
            return [this.activityGroups.find(function (item) { return item.id === group; })];
        }
        else {
            return listActivities;
        }
    };
    AgendaFilterComponent.prototype.doSearch = function () {
        if (this.gym) {
            this.router.navigate(['agenda', this.gym.slug], { queryParams: FormatUtils.encodeAgendaFilterToParamObject(this.filter) });
        }
        else {
            this.showGymSelectModal();
        }
    };
    AgendaFilterComponent.prototype.clearFilter = function (clearAges) {
        if (clearAges === void 0) { clearAges = true; }
        this.filter.activities.forEach(function (activity) { return activity.selected = false; });
        if (clearAges) {
            this.filter.ages.forEach(function (age) { return age.selected = false; });
        }
        this.activityTags = [];
        this.filter.activities = [];
        if (clearAges) {
            this.filter.ages = [];
        }
        this.filterChanged();
    };
    AgendaFilterComponent.prototype.hasModification = function (arr1, arr2) {
        if ((arr1.filter(function (a) { return arr2.includes(a); }).length !== arr1.length) || arr1.length < arr2.length) {
            this.filterChanged();
        }
    };
    AgendaFilterComponent.prototype.filterChanged = function (isChanged) {
        if (isChanged === void 0) { isChanged = true; }
        this.filterChanges.emit(isChanged);
    };
    return AgendaFilterComponent;
}());
export { AgendaFilterComponent };
