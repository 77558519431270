var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit, OnDestroy, Renderer2, ViewContainerRef, ChangeDetectorRef, TemplateRef, } from '@angular/core';
import { PopoverDirective, PopoverConfig } from 'ngx-bootstrap/popover';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
var PopoverFadeDirective = (function (_super) {
    __extends(PopoverFadeDirective, _super);
    function PopoverFadeDirective(_elementRef, _renderer, _viewContainerRef, _config, cis, cdr) {
        var _this = _super.call(this, _elementRef, _renderer, _viewContainerRef, _config, cis) || this;
        _this._elementRef = _elementRef;
        _this._renderer = _renderer;
        _this._viewContainerRef = _viewContainerRef;
        _this._config = _config;
        _this.cis = cis;
        _this.cdr = cdr;
        _this.containerClass = 'wait-to-show';
        _this.container = 'body';
        _this.popoverDisabled = false;
        return _this;
    }
    Object.defineProperty(PopoverFadeDirective.prototype, "popoverFade", {
        get: function () { return this.popover; },
        set: function (val) { this.popover = val; },
        enumerable: true,
        configurable: true
    });
    PopoverFadeDirective.prototype.fadeShow = function () {
        var _this = this;
        if (!this.popoverDisabled) {
            clearTimeout(this.timer);
            this.show();
            this.timer = setTimeout(function () {
                _this._popover.instance.containerClass = _this.containerClass + ' fade force-show';
            }, 0);
        }
    };
    PopoverFadeDirective.prototype.fadeHide = function () {
        var _this = this;
        clearTimeout(this.timer);
        if (!this.popoverDisabled) {
            this._popover.instance.containerClass = this.containerClass + ' fade';
        }
        this.timer = setTimeout(function () { return _this.hide && _this._popover.dispose(); }, 151);
    };
    return PopoverFadeDirective;
}(PopoverDirective));
export { PopoverFadeDirective };
