
<div class="btcompany-banner text-center">
  <a id="footer-home" href="https://www.bodytech.com.br" target="_blank" rel="noopener">
    <img alt="Bodytech" src="assets/footer/img-logo-bodytech.svg">
  </a>
  <ng-container *ngIf="data">
    <hr class="divider">
    <div class="footer-text">
      {{ data }}
    </div>
  </ng-container>
</div>
