import * as i0 from "./redirect-with-parameter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header-static-image/header-static-image.component.ngfactory";
import * as i3 from "../header-static-image/header-static-image.component";
import * as i4 from "./redirect-with-parameter.component";
import * as i5 from "@angular/router";
var styles_RedirectWithParameterComponent = [i0.styles];
var RenderType_RedirectWithParameterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectWithParameterComponent, data: {} });
export { RenderType_RedirectWithParameterComponent as RenderType_RedirectWithParameterComponent };
function View_RedirectWithParameterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-static-image", [["imageUrl", "assets/headers/user_area.jpg"], ["pageTitle", "Login"]], null, null, null, i2.View_HeaderStaticImageComponent_0, i2.RenderType_HeaderStaticImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderStaticImageComponent, [], { pageTitle: [0, "pageTitle"], imageUrl: [1, "imageUrl"], prefixBucket: [2, "prefixBucket"] }, null)], function (_ck, _v) { var currVal_0 = "Login"; var currVal_1 = "assets/headers/user_area.jpg"; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_RedirectWithParameterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["header", 2]], null, 0, null, View_RedirectWithParameterComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "main-wrapper container justify-content-around d-flex text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "card small-card row-on-xs no-side-border-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "form-container text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title-section text-black"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Redirecionando"]))], null, null); }
export function View_RedirectWithParameterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_RedirectWithParameterComponent_0, RenderType_RedirectWithParameterComponent)), i1.ɵdid(1, 114688, null, 0, i4.RedirectWithParameterComponent, [i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectWithParameterComponentNgFactory = i1.ɵccf("ng-component", i4.RedirectWithParameterComponent, View_RedirectWithParameterComponent_Host_0, {}, {}, []);
export { RedirectWithParameterComponentNgFactory as RedirectWithParameterComponentNgFactory };
