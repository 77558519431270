import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Gym } from '@models';
import { GymService } from '@services';
import { map } from 'rxjs/operators';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class GymResolveGuard implements Resolve<Gym> {

  constructor(
    private readonly router: Router,
    private readonly gymService: GymService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<Gym | null> {
    const id = route.params['id'];
    if (id) {
      return this.gymService.getGym(id).pipe(
        map(gym => {
          if (!gym) {
            this.router.navigate(['/academias']);
            return null;
          }
          return gym;
        }),
      );
    }
  }

}
