import * as i0 from "./debug-guide-lines.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./debug-guide-lines.component";
var styles_DebugGuideLinesComponent = [i0.styles];
var RenderType_DebugGuideLinesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DebugGuideLinesComponent, data: {} });
export { RenderType_DebugGuideLinesComponent as RenderType_DebugGuideLinesComponent };
function View_DebugGuideLinesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "line-h"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "line-v"]], null, null, null, null, null))], null, null); }
function View_DebugGuideLinesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "line d-flex flex-column justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugGuideLinesComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lines; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DebugGuideLinesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugGuideLinesComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lines; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DebugGuideLinesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-debug-guide-lines", [], null, null, null, View_DebugGuideLinesComponent_0, RenderType_DebugGuideLinesComponent)), i1.ɵdid(1, 49152, null, 0, i3.DebugGuideLinesComponent, [], null, null)], null, null); }
var DebugGuideLinesComponentNgFactory = i1.ɵccf("app-debug-guide-lines", i3.DebugGuideLinesComponent, View_DebugGuideLinesComponent_Host_0, {}, {}, []);
export { DebugGuideLinesComponentNgFactory as DebugGuideLinesComponentNgFactory };
