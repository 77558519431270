<div class="text-dark row align-items-center justify-content-center" *ngIf="data">
  <a id="sitemap-{{index}}"
      *ngFor="let link of data.links; let index = index"
      #self="RouterLinkWithHrefDirective"
      class="title"
      [appRouterLink]="link.url"
      appRouterLinkActive="text-primary">
    {{ link.text }}
  </a>
</div>
