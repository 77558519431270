import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GymActivity } from '@models';
import { GymService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class GymActivitiesResolveGuard implements Resolve<GymActivity[]> {

  constructor(
    private readonly gymService: GymService,
  ) {}

  resolve(): ResolveResult<GymActivity[]> {
    return this.gymService.getActivities();
  }

}
