import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';

import {
  User,
  OldUpdatePasswordRequest,
  RecoveryEmailRequest,
  ResetUserAccessRequest,
  AlertMessage,
  NewAccessRequest,
  MessageMap,
} from '@models';
import { AppConstants } from '@utils/app-constants';
import { back, BackEndpoints, external, ExternalEndpoints, sur, SurEndpoints } from '@utils/app-endpoints';
import { JSONUtil } from '@utils/json-util';
import { FormatUtils } from '@utils/format-utils';
import { environment } from '@env/environment';
import { AlertMessageService } from './alert-message.service';
import { UserService } from './user.service';

@Injectable()
export class AuthService {

  private readonly loginEmitter = new EventEmitter<boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly alertMessage: AlertMessageService,
    private readonly userService: UserService,
  ) { }

  getListener(): Observable<boolean> {
    return this.loginEmitter;
  }

  checkAuth(): boolean {
    return localStorage.getItem(AppConstants.STOR_USER_TOKEN) != null;
  }

  getAppToken(key: string): Observable<string> {
    const token = localStorage.getItem(key);
    if (token) {
      return of(token);
    } else {
      const tokenRequest = key === AppConstants.STOR_APP_TOKEN
        ? this.http.post<any>(back(BackEndpoints.AppToken), { apiKey: '123', nomeCanal: 'SITE' })
          .pipe(map(json => json.TokenResult))
        : this.http.get<any>(sur(SurEndpoints.AppToken))
          .pipe(map(json => json.jwt));
      return tokenRequest.pipe(
        tap(tokenResult => {
          localStorage.setItem(key, tokenResult);
        }),
      );
    }
  }

  clearAppToken(key: string) {
    localStorage.removeItem(key);
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem(AppConstants.STOR_USER));
  }

  setUser(user: User) {
    localStorage.setItem(AppConstants.STOR_USER, JSON.stringify(user));
    localStorage.setItem(AppConstants.STOR_LAST_LOGIN, user.login);
  }

  updateUserEmail(email: string) {
    const user = this.getUser();
    user.login = email;
    this.setUser(user);
  }

  getUserToken(): string {
    const token = localStorage.getItem(AppConstants.STOR_USER_TOKEN);
    if (token) {
      return token;
    } else {
      return null;
    }
  }

  clearUserToken() {
    localStorage.removeItem(AppConstants.STOR_USER_TOKEN);
  }

  login(login: string, password: string, next = ''): Observable<void> {
    return this.authenticateUser(login, password)
      .pipe(
        mergeMap(() => this.getUserData()),
        map(json => ({
            id: JSONUtil.get(json, 'IdUsuario'),
            login,
            maingym: JSONUtil.get(json, 'UnidadePrincipal'),
            name: JSONUtil.get(json, 'NomeReduzido'),
            avatar: this.createFacebookUrl(JSONUtil.get(json, 'UserIdFacebook')),
            fullname: JSONUtil.get(json, 'Nome'),
            gender: JSONUtil.get(json, 'DadosCadastro.Sexo') === 'M' ? 1 : 2,
        })),
        map((user: User) => {
          this.setUser(user);
          if (next) {
            this.router.navigateByUrl(decodeURIComponent(next));
          } else {
            this.router.navigate(['/cliente']);
          }
          this.loginEmitter.emit(true);
        }),
        catchError(err => {
          localStorage.removeItem(AppConstants.STOR_USER_TOKEN);
          if (err.statusText.startsWith('4120001')) {
            this.alertMessage.showToastr(AlertMessage.error(MessageMap.DADOS_SENDO_ATUALIZADOS));
            return [err];
          } else if (err.statusText.startsWith(AppConstants.LEGACY_ERROR.BLOCKED_USER)) {
            this.userService.modalResendEmailConfirm(login);
          }

          return throwError(err);
        }),
      );
  }

  authenticateUser(email: string, senha: string) {
    return this.getIp()
      .pipe(
        mergeMap(ip => this.http.post<any>(back(BackEndpoints.Login), { email, senha, ip })),
        map(json => {
          const token = encodeURIComponent(json.AutenticarUsuarioResult);
          localStorage.setItem(AppConstants.STOR_USER_TOKEN, token);
          return token;
        }),
      );
  }

  getIp(): Observable<string> {
    if (environment.production) {
      return this.http.get<any>('https://api.ipify.org?format=json')
        .pipe(
          map(json => json.ip),
        );
    } else {
      return of('177.35.42.12');
    }
  }

  getUserData() {
    return this.http.get<any>(back(BackEndpoints.UserData));
  }

  createFacebookUrl(facebookID: string) {
    return facebookID ? external(ExternalEndpoints.backFacebook, facebookID) : null;
  }

  logout(next = location.pathname) {
    if (this.checkAuth()) {
      this.http.get<any>(back(BackEndpoints.Logout));
      localStorage.removeItem(AppConstants.STOR_USER_TOKEN);
      localStorage.removeItem(AppConstants.STOR_USER);
    }

    if (next) {
      this.router.navigate(['/cliente/login'], { queryParams: { next } });
    } else {
      this.router.navigate(['/login']);
    }
    this.loginEmitter.emit(false);
  }

  logoutAndStay() {
    if (this.checkAuth()) {
      this.http.get<any>(back(BackEndpoints.Logout));
      localStorage.removeItem(AppConstants.STOR_USER_TOKEN);
      localStorage.removeItem(AppConstants.STOR_USER);
    }

    this.loginEmitter.emit(false);
  }

  recoveryPassword(email: string): Observable<void> {
    this.getAppToken(AppConstants.STOR_APP_TOKEN);

    return this.http.post<any>(back(BackEndpoints.RecoveryPassword), { email })
    .pipe(catchError(err => {

      if (err.statusText.startsWith(AppConstants.LEGACY_ERROR.BLOCKED_USER_RESET)) {
        this.userService.modalResendEmailConfirm(email);
      }
      return throwError(err);
    }));
  }

  searchEmail(recoveryEmail: RecoveryEmailRequest) {
    let param = new HttpParams();
    param = param.set('cpf', recoveryEmail.cpf);
    param = param.set('matricula', recoveryEmail.registry);

    return this.http.get<any>(back(BackEndpoints.RecoveryEmail), { params: param });
  }

  updatePassword(uPassword: OldUpdatePasswordRequest): Observable<void> {
    return this.http.post<any>(back(BackEndpoints.RecoveryPasswordFinish),
      {
        senha: uPassword.password,
        confirmacaoSenha: uPassword.confirmPassword,
        tokenConfirmacao: uPassword.confirmToken,
      });
  }

  getResetUserAccessToken(recoveryEmail: RecoveryEmailRequest) {
    return this.http.post<any>(back(BackEndpoints.ResetUserAccessToken), { cpf: recoveryEmail.cpf, matricula: recoveryEmail.registry });
  }

  resetUserAccess(resetUserAccess: ResetUserAccessRequest, resetToken: string) {
    let param = new HttpParams();
    param = param.set('tokenreset', resetToken);

    return this.http.post<any>(back(BackEndpoints.ResetUserAccess), { email: resetUserAccess.email,
                                                                      confirmacaoemail: resetUserAccess.confirmEmail,
                                                                      senha: resetUserAccess.password,
                                                                      confirmacaosenha: resetUserAccess.confirmPassword },
                                                                    { params: param });
  }

  validateCpf(cpf: string) {
    // Remove '.' e '-' da string de CPF
    const cleanCPF = FormatUtils.cleanCPF(cpf);

    return this.http.get<any>(
      back(BackEndpoints.ValidateCPF),
      { params: { cpf: cleanCPF } },
    );
  }

  // Valida o token para cadastro de estrangeiro e menor
  validateTokenForeignMinor(token: string) {

    return this.http.get<any>(
      back(BackEndpoints.ValidateTokenForeignMinor),
      { params: { codigoacesso: token } },
    );
  }

  registerNewAccess(noUnidade: number, newAccessData: NewAccessRequest) {
    // Remove '.' e '-' da string de CPF
    const cleanCPF = newAccessData.cpfCliente.replace(/[.-]/g, '');
    newAccessData.cpfCliente = cleanCPF;

    return this.http.post<any>(
      back(BackEndpoints.NewAccess),
      newAccessData,
      { params: { unidade: noUnidade.toString() } },
    );
  }

  confirmNewUserLegacy(confirmation: string) {
    const data = { confirmacao: confirmation };
    return this.http.post<any>(
      back(BackEndpoints.ConfirmationNewAccount),
      data,
    );
  }

}
