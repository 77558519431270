var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
var AgendaService = (function () {
    function AgendaService(apollo) {
        this.apollo = apollo;
    }
    AgendaService.prototype.getEvents = function (gym, activities, ages) {
        return this.apollo.query({
            query: AppGraphql.queryListSchedules,
            variables: {
                gym: gym,
                activities: activities,
                ages: ages,
            },
        })
            .pipe(map(function (data) {
            var schedule = JSONUtil.turnApolloMutable('listSchedules')(data);
            var result = {
                days: [],
            };
            var _loop_1 = function (weekDay) {
                result.days.push({
                    day: weekDay,
                    events: schedule.filter(function (i) { return (i.dayOfTheWeek || 'Never').includes(weekDay); })
                        .map(function (event) { return (__assign({}, event)); }),
                });
            };
            for (var _i = 0, _a = AgendaService.WEEKDAYS; _i < _a.length; _i++) {
                var weekDay = _a[_i];
                _loop_1(weekDay);
            }
            return result;
        }));
    };
    AgendaService.prototype.mapIntToWeekday = function (weekDayId) {
        try {
            var weekday = +weekDayId;
            if (!weekday) {
                throw (new Error('Not expected'));
            }
            return AgendaService.WEEKDAYS[(7 - AgendaService.startIntensityService + weekday) % 7];
        }
        catch (_a) {
            return 'Never';
        }
    };
    AgendaService.prototype.indexName = function (activityId, weekDay) {
        return activityId + "#" + (weekDay || 'Never');
    };
    AgendaService.prototype.getEventById = function (id, activityId, weekDay) {
        return this.apollo.query({
            query: AppGraphql.querySchedule,
            variables: {
                id: id,
                activityId: activityId,
                weekDay: AgendaService.WEEKDAYS.indexOf(weekDay) + AgendaService.startIntensityService,
            },
        })
            .pipe(map(function (response) { return (__assign({}, JSONUtil.turnApolloMutable('schedule')(response))); }), tap(function (schedule) { return schedule.targetAudienceLevelList = schedule.targetAudienceLevel.split(';#'); }));
    };
    AgendaService.prototype.getSchedulerDetailByItemGridId = function (itemGridId, activityId, weekDay) {
        return this.apollo.query({
            query: AppGraphql.queryListSchedulesByItemGridId,
            variables: {
                itemGridId: itemGridId,
                activityId: activityId,
                weekDay: weekDay,
            },
        })
            .pipe(map(function (response) {
            var scheduler = { audiences: [] };
            var schedules = JSONUtil.turnApolloMutable('listSchedulesByItemGridId')(response);
            schedules.forEach(function (schedule) {
                scheduler.id = schedule.id;
                scheduler.itemGridTitle = schedule.itemGridTitle;
                scheduler.teacherName = schedule.teacherName;
                scheduler.itemGridId = schedule.itemGridId;
                scheduler.itemGridPlace = schedule.itemGridPlace;
                scheduler.targetAudienceLevel = schedule.targetAudienceLevel;
                scheduler.duration = schedule.duration;
                scheduler.activityInitialTimeFormatted = schedule.activityInitialTimeFormatted;
                scheduler.audiences.push(schedule.audiences[0]);
            });
            scheduler.intensity = __assign({}, JSONUtil.turnApolloMutable('getIntensityByActivityIdAndWeekDay')(response));
            return scheduler;
        }), tap(function (schedule) { return schedule.targetAudienceLevelList = schedule.targetAudienceLevel.split(';#'); }));
    };
    AgendaService.startIntensityService = 1;
    AgendaService.WEEKDAYS = [
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
        'Domingo',
    ];
    return AgendaService;
}());
export { AgendaService };
