import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { Component, Output, EventEmitter, Input } from '@angular/core';


const styleCollapsed = style({ height: '0px', visibility: 'hidden', display: 'none' });
const styleExpanded = style({ height: '*', visibility: 'visible' });

@Component({
  selector: 'app-collapse, [app-collapse]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./collapse.component.scss'],
  animations: [
    trigger('bodyExpansion', [
      state('collapsed', styleCollapsed),
      state('collapsed_animationless', styleCollapsed),
      state('expanded', styleExpanded),
      state('expanded_animationless', styleExpanded),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
      transition('* <=> *', animate(0)),
    ]),
  ],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'app-collapse',
    '[class.app-collapse-expanded]': 'expanded',
    '[class.expanded]': 'expanded',
    '[class.show]': 'expanded',
    '[class.app-collapse-collapsed]': '!expanded',
    '[class.collapsed]': '!expanded',
    '[@bodyExpansion]': '_getExpandedState()',
    '(@bodyExpansion.done)': '_bodyAnimation($event)',
    '(@bodyExpansion.start)': '_bodyAnimation($event)',
  },
})
export class CollapseComponent {
  @Input() animation = true;

  @Input()
  get expanded(): any { return this._expanded; }
  set expanded(expanded: any) {
    expanded = Boolean(expanded);

    // Only emit events and update the internal value if the value changes.
    if (this._expanded !== expanded) {
      this._expanded = expanded;
      this.expandedChange.emit(expanded);

      if (expanded) {
        this.opened.emit();
      } else {
        this.closed.emit();
      }
    }
  }
  private _expanded = false;

  /** An event emitted after the body's expansion animation happens. */
  @Output() afterExpand = new EventEmitter<void>();
  /** An event emitted after the body's collapse animation happens. */
  @Output() afterCollapse = new EventEmitter<void>();
  /**
   * Emits whenever the expanded state of the accordion changes.
   * Primarily used to facilitate two-way binding.
   * @docs-private
   */
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Event emitted every time the AccordionItem is closed. */
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  /** Event emitted every time the AccordionItem is opened. */
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  /** Gets the expanded state string. */
  _getExpandedState() {
    return (this.expanded ? 'expanded' : 'collapsed') + (this.animation ? '' : '_animationless');
  }

  _bodyAnimation(event: AnimationEvent) {
    const classList = event.element.classList;
    const cssClass = 'expanded';
    const { phaseName, toState, fromState } = event;

    // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
    // order to prevent the cases where switching too early would cause the animation to jump.
    // Note that we do it directly on the DOM element to avoid the slight delay that comes
    // with doing it via change detection.
    if (phaseName === 'done' && toState === 'expanded') {
      classList.add(cssClass);
    }
    if (phaseName === 'start' && toState === 'collapsed') {
      classList.remove(cssClass);
    }

    if (phaseName === 'done' && toState === 'expanded' && fromState !== 'void') {
      this.afterExpand.emit();
    }
    if (phaseName === 'done' && toState === 'collapsed' && fromState !== 'void') {
      this.afterCollapse.emit();
    }
  }

  toggle() {
    this.expanded = !this._expanded;
  }

  open() {
    this.expanded = true;
  }

  close() {
    this.expanded = false;
  }
}
