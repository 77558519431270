import * as i0 from "./collapse.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./collapse.component";
var styles_CollapseComponent = [i0.styles];
var RenderType_CollapseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollapseComponent, data: { "animation": [{ type: 7, name: "bodyExpansion", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { height: "0px", visibility: "hidden", display: "none" }, offset: null }, options: undefined }, { type: 0, name: "collapsed_animationless", styles: { type: 6, styles: { height: "0px", visibility: "hidden", display: "none" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { height: "*", visibility: "visible" }, offset: null }, options: undefined }, { type: 0, name: "expanded_animationless", styles: { type: 6, styles: { height: "*", visibility: "visible" }, offset: null }, options: undefined }, { type: 1, expr: "expanded <=> collapsed", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }, { type: 1, expr: "* <=> *", animation: { type: 4, styles: null, timings: 0 }, options: null }], options: {} }] } });
export { RenderType_CollapseComponent as RenderType_CollapseComponent };
export function View_CollapseComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_CollapseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-collapse", [["class", "app-collapse"]], [[2, "app-collapse-expanded", null], [2, "expanded", null], [2, "show", null], [2, "app-collapse-collapsed", null], [2, "collapsed", null], [40, "@bodyExpansion", 0]], [["component", "@bodyExpansion.done"], ["component", "@bodyExpansion.start"]], function (_v, en, $event) { var ad = true; if (("component:@bodyExpansion.done" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._bodyAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@bodyExpansion.start" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._bodyAnimation($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CollapseComponent_0, RenderType_CollapseComponent)), i1.ɵdid(1, 49152, null, 0, i2.CollapseComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).expanded; var currVal_1 = i1.ɵnov(_v, 1).expanded; var currVal_2 = i1.ɵnov(_v, 1).expanded; var currVal_3 = !i1.ɵnov(_v, 1).expanded; var currVal_4 = !i1.ɵnov(_v, 1).expanded; var currVal_5 = i1.ɵnov(_v, 1)._getExpandedState(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
var CollapseComponentNgFactory = i1.ɵccf("app-collapse, [app-collapse]", i2.CollapseComponent, View_CollapseComponent_Host_0, { animation: "animation", expanded: "expanded" }, { afterExpand: "afterExpand", afterCollapse: "afterCollapse", expandedChange: "expandedChange", closed: "closed", opened: "opened" }, ["*"]);
export { CollapseComponentNgFactory as CollapseComponentNgFactory };
