import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/components/header-static-image/header-static-image.component.ngfactory";
import * as i3 from "../shared/components/header-static-image/header-static-image.component";
import * as i4 from "../shared/components/empty-card/empty-card.component.ngfactory";
import * as i5 from "../shared/components/empty-card/empty-card.component";
import * as i6 from "./not-found.component";
import * as i7 from "../shared/services/header.service";
import * as i8 from "@angular/router";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
function View_NotFoundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-static-image", [], null, null, null, i2.View_HeaderStaticImageComponent_0, i2.RenderType_HeaderStaticImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderStaticImageComponent, [], { headerObj: [0, "headerObj"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.header; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { headerComponent: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["header", 2]], null, 0, null, View_NotFoundComponent_1)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "main", [["class", "main-wrapper container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "app-empty-card", [["altIcon", "P\u00E1gina n\u00E3o encontrada"], ["buttonLink", "/"], ["buttonText", "Voltar para home"], ["icon", "/assets/icons_custom/ic-empty-cloud.svg"], ["message", "Esta p\u00E1gina n\u00E3o existe ou encontra-se indispon\u00EDvel.<br>\n    Verifique sua conex\u00E3o ou tente novamente mais tarde."]], null, null, null, i4.View_EmptyCardComponent_0, i4.RenderType_EmptyCardComponent)), i1.ɵdid(4, 114688, null, 2, i5.EmptyCardComponent, [], { message: [0, "message"], icon: [1, "icon"], altIcon: [2, "altIcon"], buttonText: [3, "buttonText"], buttonLink: [4, "buttonLink"] }, null), i1.ɵqud(335544320, 2, { cardHeader: 0 }), i1.ɵqud(335544320, 3, { cardButtons: 0 })], function (_ck, _v) { var currVal_0 = "Esta p\u00E1gina n\u00E3o existe ou encontra-se indispon\u00EDvel.<br>\n    Verifique sua conex\u00E3o ou tente novamente mais tarde."; var currVal_1 = "/assets/icons_custom/ic-empty-cloud.svg"; var currVal_2 = "P\u00E1gina n\u00E3o encontrada"; var currVal_3 = "Voltar para home"; var currVal_4 = "/"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i6.NotFoundComponent, [i7.HeaderService, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("ng-component", i6.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
