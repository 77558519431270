import { NgModule } from '@angular/core';
import { OpenModalPaddingService } from './open-modal-padding.service';
import { OpenModalPaddingDirective } from './open-modal-padding.directive';
import { WidthFromWindowDirective } from './width-from-window.directive';


@NgModule({
  providers: [
    OpenModalPaddingService,
  ],
  declarations: [
    OpenModalPaddingDirective,
    WidthFromWindowDirective,
  ],
  exports: [
    OpenModalPaddingDirective,
    WidthFromWindowDirective,
  ],
})
export class OpenModalPaddingModule {}
