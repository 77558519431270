import { Pipe, PipeTransform } from '@angular/core';
import * as Fuse from 'fuse.js';

@Pipe({
  name: 'fuse',
})
export class FusePipe implements PipeTransform {

  transform(list: any, searchString: string, options: Fuse.FuseOptions<any>): any {
    if (!searchString) {
      return list;
    }

    options = Object.assign({
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
    }, options);
    const fuse = new Fuse(list, options); // "list" is the item array
    return fuse.search(searchString);
  }

}
