import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal/modal.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "@angular/common";
import * as i4 from "./modal-alert.component";
import * as i5 from "../../../services/modal.service";
var styles_ModalAlertComponent = [];
var RenderType_ModalAlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalAlertComponent, data: {} });
export { RenderType_ModalAlertComponent as RenderType_ModalAlertComponent };
function View_ModalAlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-dialog modal-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-header-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalObj.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.modalObj.message; _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "col btn btn-", (((_co.modalObj == null) ? null : _co.modalObj.confirmButtonClass) || "primary"), ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.modalObj == null) ? null : _co.modalObj.confirmButton) || "OK"); _ck(_v, 8, 0, currVal_3); }); }
export function View_ModalAlertComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 4, "div", [["aria-hidden", "true"], ["aria-labelledby", "mySmallModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, [[1, 4], ["templateModal", 4]], 0, i1.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i2.ComponentLoaderFactory], { config: [0, "config"] }, null), i0.ɵpod(3, { backdrop: 0, keyboard: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalAlertComponent_1)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, "static", false); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.modalObj; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ModalAlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-alert", [], null, null, null, View_ModalAlertComponent_0, RenderType_ModalAlertComponent)), i0.ɵdid(1, 245760, null, 0, i4.ModalAlertComponent, [i5.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalAlertComponentNgFactory = i0.ɵccf("app-modal-alert", i4.ModalAlertComponent, View_ModalAlertComponent_Host_0, {}, {}, []);
export { ModalAlertComponentNgFactory as ModalAlertComponentNgFactory };
