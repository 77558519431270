import * as i0 from "./debug.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./screen-size/screen-size.component.ngfactory";
import * as i3 from "./screen-size/screen-size.component";
import * as i4 from "./modal-test/modal-test.component.ngfactory";
import * as i5 from "./modal-test/modal-test.component";
import * as i6 from "../shared/services/modal.service";
import * as i7 from "./route-test/route-test.component.ngfactory";
import * as i8 from "./route-test/route-test.component";
import * as i9 from "@angular/router";
import * as i10 from "../shared/services/alert-message.service";
import * as i11 from "@angular/common/http";
import * as i12 from "../shared/services/auth.service";
import * as i13 from "apollo-angular";
import * as i14 from "./debug-view-id/debug-view-id.component.ngfactory";
import * as i15 from "./debug-view-id/debug-view-id.component";
import * as i16 from "./debug-banner/debug-banner.component.ngfactory";
import * as i17 from "./debug-banner/debug-banner.component";
import * as i18 from "@angular/common";
import * as i19 from "./debug-guide-lines/debug-guide-lines.component.ngfactory";
import * as i20 from "./debug-guide-lines/debug-guide-lines.component";
import * as i21 from "./debug.component";
var styles_DebugComponent = [i0.styles];
var RenderType_DebugComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DebugComponent, data: {} });
export { RenderType_DebugComponent as RenderType_DebugComponent };
function View_DebugComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "d-print-none text-white debug-container p-3 position-fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDebug() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fab fa-angular"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DEGUB PANEL"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-debug-screen-size", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onResize($event.target.innerWidth) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScreenSizeComponent_0, i2.RenderType_ScreenSizeComponent)), i1.ɵdid(11, 114688, null, 0, i3.ScreenSizeComponent, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-debug-modal-test", [], null, null, null, i4.View_ModalTestComponent_0, i4.RenderType_ModalTestComponent)), i1.ɵdid(13, 49152, null, 0, i5.ModalTestComponent, [i6.ModalService], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-debug-route-test", [], null, null, null, i7.View_RouteTestComponent_0, i7.RenderType_RouteTestComponent)), i1.ɵdid(15, 49152, null, 0, i8.RouteTestComponent, [i9.Router, i10.AlertMessageService, i11.HttpClient, i12.AuthService, i13.Apollo], null, null)], function (_ck, _v) { _ck(_v, 11, 0); }, null); }
function View_DebugComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-print-none text-white debug-container p-3 mb-4 position-fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-debug-view-id", [], null, [["window", "mouseover"]], function (_v, en, $event) { var ad = true; if (("window:mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).enter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_DebugViewIdComponent_0, i14.RenderType_DebugViewIdComponent)), i1.ɵdid(2, 49152, null, 0, i15.DebugViewIdComponent, [], null, null)], null, null); }
function View_DebugComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-debug-banner", [["style", "cursor: pointer"]], [[2, "d-none", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDebug() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DebugBannerComponent_0, i16.RenderType_DebugBannerComponent)), i1.ɵdid(2, 49152, null, 0, i17.DebugBannerComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugComponent_2)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugComponent_3)), i1.ɵdid(6, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "d-print-none"]], [[2, "d-none", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-debug-guide-lines", [], null, null, null, i19.View_DebugGuideLinesComponent_0, i19.RenderType_DebugGuideLinesComponent)), i1.ɵdid(9, 49152, null, 0, i20.DebugGuideLinesComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.debug; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.mouseinfo; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.debug; _ck(_v, 1, 0, currVal_0); var currVal_3 = !_co.guidelines; _ck(_v, 7, 0, currVal_3); }); }
export function View_DebugComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DebugComponent_1)), i1.ɵdid(1, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inProd; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DebugComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-debug", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyTrigger($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DebugComponent_0, RenderType_DebugComponent)), i1.ɵdid(1, 49152, null, 0, i21.DebugComponent, [], null, null)], null, null); }
var DebugComponentNgFactory = i1.ɵccf("app-debug", i21.DebugComponent, View_DebugComponent_Host_0, {}, {}, []);
export { DebugComponentNgFactory as DebugComponentNgFactory };
