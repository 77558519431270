<div class="card row-on-xs no-side-border-xs">
  <ng-container *ngTemplateOutlet="cardHeader"></ng-container>
  <div class="image">
    <div class="position-relative backdrop d-flex justify-content-center align-items-center">
      <img alt="{{altIcon}}" src="{{icon}}">
    </div>
  </div>
  <div class="card-body text-center">
    <div class="message" [innerHTML]="message"></div>
    <ng-container *ngTemplateOutlet="cardButtons"></ng-container>
    <a *ngIf="buttonText" id="empty-button" [routerLink]="buttonLink" class="btn btn-primary btn-confirm">
      {{buttonText}}
    </a>
  </div>
</div>
