import { Address, City, GymAgendaFilter, GymPhone, GymSearchFilter } from '@models';
import { AppConstants } from '@utils/app-constants';

export class FormatUtils {

  /**
   * Formata um objeto GymPhone para uma string no padrão (00) 0000-0000
   * @param phone
   */
  static formatPhoneToString(phone: GymPhone) {
    return `(${phone.ddd}) ${this.formatPhoneNumberToString(phone.number)}`;
  }

  static formatPhoneNumberToString(number: string) {
      return [number.slice(0, 4), '-', number.slice(4)].join('');
  }

  /**
   * Formata o endereço para o padrão seguindo o exemplo: "Rua Achille Bassi, 42, Jardim Lutfalla"
   * Ignora valores vazios e número '0'
   * @param address
   */
  static formatAddressToString(address: Address) {
    const street = [address.publicPlace.name, address.street].join(' ');
    const array = [street, address.number !== '0' ? address.number : '', address.neighborhood];
    return array.filter(i => i).join(', ');
  }

  /**
   * Formata o Cidade para o padrão seguindo o exemplo: "São Carlos, SP"
   * @param city
   */
  static formatCityToString(city: City) {
    return `${city.name}, ${city.state.initials}`;
  }

  /**
   * Coloca mascara para exibição do CNPJ
   */
  static formatCNPJToString(cnpj: string) {
    if (cnpj) {
      cnpj = '00000000000000'.substring(cnpj.length).concat(cnpj);
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return cnpj;
    }
  }

  /**
   * Coloca mascara para exibição do CPF
   */
  static formatCPFToString(cpf: string) {
    if (cpf) {
      cpf = '00000000000'.substring(cpf.length).concat(cpf);
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return cpf;
    }
  }

  static formatZipCodeToString(zipCode: string) {
    if (zipCode) {
      zipCode = '00000000'.substring(zipCode.length).concat(zipCode);
      return zipCode.replace(/^(\d{5})(\d{3})/, '$1-$2');
    } else {
      return zipCode;
    }
  }

  static encodeAgendaFilterToParamObject(filter: GymAgendaFilter) {
    const param: any = {};
    param[AppConstants.PARAM_AGENDA_AGE_RANGES] = FormatUtils.arrayToEncodedIdsString(filter.ages);
    param[AppConstants.PARAM_AGENDA_ACTIVITIES] = FormatUtils.arrayToEncodedIdsString(filter.activities);
    return param;
  }

  static encodeGymSearchFilterToParamObject(filter: GymSearchFilter) {
    const param: any = {};
    param[AppConstants.PARAM_SEARCHGYM_ACTIVITIES] = FormatUtils.arrayToEncodedIdsString(filter.activities);
    param[AppConstants.PARAM_SEARCHGYM_SERVICES] = FormatUtils.arrayToEncodedIdsString(filter.benefits);
    param[AppConstants.PARAM_SEARCHGYM_PRICES] = filter.price.length ? btoa(filter.price.join(',')) : null;
    return param;
  }

  static arrayToEncodedIdsString(array: any[]) {
    return array && array.length ? btoa(array.map(a => a.id || a).join(',')) : null;
  }

  static translateAgeNumberToString(age: number): string {
    if (age > 1) { return age + ' anos'; }
    if (age === 1) { return age + ' ano'; }
    const months = age * 12;
    if (months === 1) {
      return months + ' mês';
    } else {
      return months + ' meses';
    }
  }

  static ageRangeToDescription(minAge: number, maxAge: number) {
    const minDescription = this.translateAgeNumberToString(minAge);

    if (minAge === maxAge) {
      return minDescription + ' de idade';
    }

    const maxDescription = (maxAge < 100) ?
      ' a ' + this.translateAgeNumberToString(maxAge)
      : ' ou mais';

    return minDescription + maxDescription;
  }

  static formatCardExpirationIfNeeded(expiration = '') {
    if (!expiration.length) {
      return '';
    }
    if (expiration.length === 5) {
      const [month, year] = expiration.split('/');
      return month + '/' + '2000'.slice(0, 4 - year.length).concat(year);
    }
    if (expiration.length === 7) {
      return expiration;
    }
    throw { message: 'Formato inválido! Use MM/YY ou MM/YYYY' };
  }

  static formatCEPToNumber(cep: string): number {
    return parseInt(cep.replace(/[-]/g, ''), 10);
  }

  static cleanCPF(cpf: string): string {
    return cpf.replace(/[\.-]/g, '');
  }

  /**
   * Aplica máscara num número de telefone com ddd, considerando tanto números com 8 ou 9 digitos
   * @param phone: Nº de telefone/ celular com ddd em string
   */
  static maskPhone(phone: string) {
    let pattern: string;
    if (phone.length === 11) {
      pattern = '(##) #####-####';
    } else if (phone.length === 10) {
      pattern = '(##) ####-####';
    } else {
      return null; // Telefone inválido, retorna nulo para limpar o campo
    }

    let i = 0;
    return pattern.replace(/#/g, _ => phone[i++]);
  }

  /**
   * Aplica máscara num número, e adiciona 'leading zeroes' caso seja necessário
   * @param num: Nº a ser formatado, em string
   * @param reqDigits: Nº de dígitos a forçar. Adiciona leading zeroes caso o nº original tenha menos dígitos
   * @param pattern: String com padrão a ser seguido. Substitui '#' no padrão pelos dígitos.
   */
  static maskNumber(num: string, reqDigits: number, pattern: string) {
    num = num.replace(/-.,/g, '');

    while (num.length < reqDigits) {
      num = '0'.concat(num);
    }

    let i = 0;
    return pattern.replace(/#/g, _ => num[i++]);
  }
}
