import { HttpClient } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';
import { back } from '@utils/app-endpoints';
import { GymService } from './gym.service';
var UserTrainingService = (function () {
    function UserTrainingService(http, gymService) {
        this.http = http;
        this.gymService = gymService;
    }
    UserTrainingService.prototype.getDashboard = function () {
        var _this = this;
        return this.http.get(back("/cliente/Treinos/?incluirdetalhe=true"))
            .pipe(map(function (json) { return json || []; }), map(function (jsonResponse) {
            var response = [];
            var _loop_1 = function (json) {
                var detalhes = JSONUtil.get(json, 'DetalheTreino') || [];
                var trainings = [];
                var ergometer = [];
                detalhes.forEach(function (item) {
                    return JSONUtil.get(item, 'TipoTreino')
                        ? ergometer.push(_this.extractErgometerFromJson(item))
                        : trainings.push(_this.extractTrainingFromJson(item));
                });
                response.push(Object.assign(_this.composeTrainingResponse(json), { ergometer: ergometer, trainings: trainings }, { letter: String.fromCharCode(97 + (jsonResponse.indexOf(json) % 26)) }));
            };
            for (var _i = 0, jsonResponse_1 = jsonResponse; _i < jsonResponse_1.length; _i++) {
                var json = jsonResponse_1[_i];
                _loop_1(json);
            }
            return response;
        }), mergeMap(function (json) { return json ? _this.gymService.getGymNames()
            .pipe(tap(function (gyms) { return json.forEach(function (train) { return train.gym = gyms.find(function (gym) { return gym.id === train.gym; }).name || 'Servidor indisponível'; }); }), map(function (_) { return json; })) : EMPTY; }));
    };
    UserTrainingService.prototype.extractTrainingFromJson = function (item) {
        return {
            order: JSONUtil.get(item, 'Ordem'),
            machine: JSONUtil.get(item, 'Maquina'),
            exercise: JSONUtil.get(item, 'Exercicio'),
            series: JSONUtil.get(item, 'Serie'),
            repetition: JSONUtil.get(item, 'Repeticao'),
            load: JSONUtil.get(item, 'Carga'),
            regulate: JSONUtil.get(item, 'Regulagem'),
            interval: JSONUtil.get(item, 'Frequencia'),
            method: JSONUtil.get(item, 'Metodo'),
        };
    };
    UserTrainingService.prototype.extractErgometerFromJson = function (item) {
        return {
            exercise: JSONUtil.get(item, 'Exercicio'),
            frequency: JSONUtil.get(item, 'Frequencia'),
            load: JSONUtil.get(item, 'Carga'),
            target: JSONUtil.get(item, 'ZonaAlvo'),
            time: JSONUtil.get(item, 'Tempo'),
            observation: JSONUtil.get(item, 'Observacao'),
        };
    };
    UserTrainingService.prototype.composeTrainingResponse = function (json) {
        return {
            gym: JSONUtil.get(json, 'Unidade') || 0,
            start: JSONUtil.getDate(json, 'DataCriacao'),
            end: JSONUtil.getDate(json, 'DataVencimento'),
            sequence: JSONUtil.get(json, 'Sequencia'),
            frequency: JSONUtil.get(json, 'Frequencia'),
            progress: {
                current: JSONUtil.get(json, 'QuantidadeTreinosRealizados'),
                total: JSONUtil.get(json, 'QuantidadeTreinosPrescritos'),
            },
            trainings: [],
            ergometer: [],
            observation: JSONUtil.get(json, 'Observacao'),
            streching: JSONUtil.get(json, 'Alongamento'),
            teacher: JSONUtil.get(json, 'Professor'),
        };
    };
    return UserTrainingService;
}());
export { UserTrainingService };
