var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { FusePipe } from '@pipes';
import { GymSearchAbstractService } from './gym-search-abstract.service';
import { GymService } from './gym.service';
import { sur } from '@utils/app-endpoints';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
var GymSearchRealService = (function (_super) {
    __extends(GymSearchRealService, _super);
    function GymSearchRealService(http, apollo) {
        var _this = _super.call(this, apollo) || this;
        _this.http = http;
        _this.apollo = apollo;
        return _this;
    }
    GymSearchRealService.prototype.getGymNames = function () {
        return this._getGymNames();
    };
    GymSearchRealService.prototype.searchBy = function (filterParam, max, page) {
        delete filterParam.stateInitials;
        delete filterParam.city;
        delete filterParam.neighborhood;
        filterParam['max'] = max;
        filterParam['offset'] = page * max;
        var filters = {};
        Object.entries(filterParam).forEach(function (v) { return filters[v[0]] = v[1].toString(); });
        return this.http.get(sur("/gymUnit"), { params: filters })
            .pipe(map(function (value) {
            value.results.forEach(function (r) { return GymService.preFormatGym(r); });
            return {
                results: value.results,
                more: value.totalCount > (page * max) + max,
                total: value.totalCount,
                resultType: value.resultType,
                orderType: value.orderType,
            };
        }));
    };
    GymSearchRealService.prototype.searchGymsAutocomplete = function (text) {
        var filters = {
            search: text,
            max: '8',
            simple: 'true',
        };
        return this.http.get(sur("/gymUnit"), { params: filters }).pipe(map(function (value) { return value.results; }));
    };
    GymSearchRealService.prototype.searchGymNames = function (text) {
        return this.getGymNames()
            .pipe(map(function (value) { return new FusePipe().transform(value, text, { keys: ['name'] }); }));
    };
    return GymSearchRealService;
}(GymSearchAbstractService));
export { GymSearchRealService };
