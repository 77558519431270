import { environment } from '@env/environment';
var genericEndpointInterpolation = function (baseurl, path, params) {
    if (params === void 0) { params = []; }
    var interpoled = params.reduce(function (old, curr, i) { return old.split("{{" + i + "}}").join(curr.toString()); }, path);
    return baseurl + interpoled;
};
var ɵ0 = genericEndpointInterpolation;
export var back = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.services.baseUrl, path, params);
};
export var blog = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation("" + environment.wordpress.baseUrl + "/wp-json/wp/v2", path, params);
};
var ExternalEndpoints = (function () {
    function ExternalEndpoints() {
    }
    ExternalEndpoints.backFacebook = 'https://graph.facebook.com/{{0}}/picture?width=143&height=143';
    return ExternalEndpoints;
}());
export { ExternalEndpoints };
export var external = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation('', path, params);
};
export var sur = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.apollo.baseUrl, path, params);
};
var AppEndpoints = (function () {
    function AppEndpoints() {
    }
    AppEndpoints.get = function (value) {
        if (value === void 0) { value = ''; }
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        return params.reduce(function (old, curr, i) { return old.split("{{" + i + "}}").join(curr.toString()); }, value);
    };
    return AppEndpoints;
}());
export { AppEndpoints };
export { ɵ0 };
