export * from './bucket.pipe';
export * from './calculate.pipe';
export * from './capitalize.pipe';
export * from './filesize.pipe';
export * from './filter-activity.pipe';
export * from './format.pipe';
export * from './fuse.pipe';
export * from '../is-overflown.pipe';
export * from './order-by.pipe';
export * from './sortWorkingPeriods.pipe';
export * from './split.pipe';

import { BucketPipe } from './bucket.pipe';
import { CalculatePipe } from './calculate.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { FilesizePipe } from './filesize.pipe';
import { FilterActivityPipe } from './filter-activity.pipe';
import { FormatPipe } from './format.pipe';
import { FusePipe } from './fuse.pipe';
import { IsOverflownPipe } from '../is-overflown.pipe';
import { OrderByPipe } from './order-by.pipe';
import { SortPlansPipe } from './sort-plans.pipe';
import { SortWorkingPeriodsPipe } from './sortWorkingPeriods.pipe';
import { SplitPipe } from './split.pipe';

export const pipes = [
  BucketPipe,
  CalculatePipe,
  CapitalizePipe,
  FilesizePipe,
  FilterActivityPipe,
  FormatPipe,
  FusePipe,
  IsOverflownPipe,
  OrderByPipe,
  SortPlansPipe,
  SortWorkingPeriodsPipe,
  SplitPipe,
];
