import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';

import {
  ProfileAccount,
  ProfileData,
  ProfilePayment,
  ProfileResponse,
  SelectItem,
  ZipServiceAddress,
  FranchiseRequest,
  User,
  IdHolder,
} from '@models';
import { AuthService } from './auth.service';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { FormatUtils } from '@utils/format-utils';
import { JSONUtil } from '@utils/json-util';

@Injectable()
export class UserProfileService {

  cache: ProfileData;
  dataUser: User;

  cacheSelectorLists: Observable<any>;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly apollo: Apollo,
  ) { }

  getCachedData(): Observable<ProfileData> {
    this.dataUser = JSON.parse(localStorage.getItem('user-data'));

    if (this.cache && this.cache.id === this.dataUser.id) {
      return of(JSON.parse(JSON.stringify(this.cache)));
    }

    return this.getData();
  }

  getData(): Observable<ProfileData> {
    return this.http.get<any>(back(BackEndpoints.UserData))
      .pipe(
        map(json => {
          const contactList = JSONUtil.get(json, 'DadosContato.ListaContatos');
          this.cache = {
            id: JSONUtil.get(json, 'IdUsuario'),
            name: JSONUtil.get(json, 'Nome'),
            shortname: JSONUtil.get(json, 'NomeReduzido'),
            fullname: JSONUtil.get(json, 'Nome'),
            userpic: null,
            gender: JSONUtil.get(json, 'DadosCadastro.Sexo') === 'M' ? 1 : 2,
            cpfDocument: JSONUtil.get(json, 'CPF') < 0 ? '' : JSONUtil.get(json, 'CPF'),
            rgDocument: JSONUtil.get(json, 'DadosCadastro.RG'),
            rgExpeditor: JSONUtil.get(json, 'DadosCadastro.OrgaoExpeditor'),
            registry: JSONUtil.get(json, 'Matricula'),
            birthday: JSONUtil.getDate(json, 'DataNascimento').toISOString(),
            nationality: JSONUtil.get(json, 'DadosCadastro.Nacionalidade'),
            general: {
              profession: JSONUtil.getNumber(json, 'DadosCadastro.Profissao'),
              civil: JSONUtil.getNumber(json, 'DadosCadastro.EstadoCivil'),
              birthplace: JSONUtil.get(json, 'DadosCadastro.Naturalidade'),
              father: JSONUtil.get(json, 'DadosCadastro.NomePai'),
              mother: JSONUtil.get(json, 'DadosCadastro.NomeMae'),
            },
            address: {
              zipCode: FormatUtils.maskNumber(JSONUtil.get(json, 'DadosContato.CEP').toString(), 8, '#####-###'),
              street: JSONUtil.get(json, 'DadosContato.Endereco'),
              number: JSONUtil.get(json, 'DadosContato.Numero'),
              adjunct: JSONUtil.get(json, 'DadosContato.Complemento'),
              neighborhood: JSONUtil.get(json, 'DadosContato.Bairro'),
              city: { id: JSONUtil.get(json, 'DadosContato.IdCidade') },
              state: { id: JSONUtil.get(json, 'DadosContato.IdEstado') },
              publicPlace: { id: 1 }, // Public place é necessário no serviço porém não utilizado atualmente no frontend
            },
            phones: this.getPhoneList(JSONUtil.get(json, 'DadosContato.ListaTelefones')),
            emergency: contactList === null ? [] : contactList.map(
              (item: any) => ({
                name: JSONUtil.get(item, 'NomeContato'),
                relation: JSONUtil.get(item, 'IdParentesco'),
                phone: this.getPhone(item),
              })),
            newsletter: JSONUtil.get(json, 'DadosContato.EmailPublicidade'),
            email: JSONUtil.get(json, 'EmailPrincipal') || this.authService.getUser().login,
          };

          return this.cache;
        }),
        mergeMap(data => {
          return this.http.get<any>(back(BackEndpoints.Nation))
            .pipe(
              map((json: any[]) => {
                const nation = json.find(i => JSONUtil.get(i, 'IdNacionalidade') === data.nationality);
                const nationality: IdHolder<string> = { canais: data.nationality, portal: JSONUtil.get(nation, 'Descricao') };
                return Object.assign(data, { nationality });
              }),
              mergeMap(() => this.getCachedData()),
            );
        }),
      );
  }

  getPhoneList(json: any[]) {
    return {
      home: this.getPhone(json.find(tel => JSONUtil.get(tel, 'IdTipoTelefone') === 1)),
      cell: this.getPhone(json.find(tel => JSONUtil.get(tel, 'IdTipoTelefone') === 3)),
      work: this.getPhone(json.find(tel => JSONUtil.get(tel, 'IdTipoTelefone') === 2)),
    };
  }

  getPhone(json: any): string {
    return json ? JSONUtil.get(json, 'CodigoDDD').toString() + JSONUtil.get(json, 'NumeroTelefone') : '';
  }

  setData(data: ProfileData) {
    const phones = [];
    if (data.phones.home) {
      data.phones.home = this.stripPhoneMask(data.phones.home);
      phones.push({
        CodigoDDD: data.phones.home.slice(0, 2),
        CodigoDDI: 55,
        IdTipoTelefone: 1,
        NomeTipoTelefone: 'Residencial',
        NumeroTelefone: data.phones.home.slice(2),
        TelefonePreferencial: false,
      });
    }
    if (data.phones.work) {
      data.phones.work = this.stripPhoneMask(data.phones.work);
      phones.push({
        CodigoDDD: data.phones.work.slice(0, 2),
        CodigoDDI: 55,
        IdTipoTelefone: 2,
        NomeTipoTelefone: 'Comercial',
        NumeroTelefone: data.phones.work.slice(2),
        TelefonePreferencial: false,
      });
    }
    if (data.phones.cell) {
      data.phones.cell = this.stripPhoneMask(data.phones.cell);
      phones.push({
        CodigoDDD: data.phones.cell.slice(0, 2),
        CodigoDDI: 55,
        IdTipoTelefone: 3,
        NomeTipoTelefone: 'Celular',
        NumeroTelefone: data.phones.cell.slice(2),
        TelefonePreferencial: false,
      });
    }

    const emergency = [];
    for (const contact of data.emergency) {
      contact.phone = this.stripPhoneMask(contact.phone);
      emergency.push({
        CodigoDDI: 55,
        CodigoDDD: contact.phone.slice(0, 2),
        ContatoPreferencial: false,
        IdParentesco: contact.relation,
        NomeContato: contact.name,
        NumeroTelefone: contact.phone.slice(2),
      });
    }

    const send = {
      DadosCadastro: {
        DataConsulta: null,
        EstadoCivil: data.general.civil,
        Nacionalidade: data.nationality.canais,
        Naturalidade: data.general.birthplace,
        NomeMae: data.general.mother,
        NomePai: data.general.father,
        Profissao: data.general.profession,
        RG: data.rgDocument,
        OrgaoExpeditor: data.rgExpeditor,
        Sexo: data.gender === 1 ? 'M' : 'F',
      },
      DadosContato: {
        Bairro: data.address.neighborhood,
        CEP: FormatUtils.formatCEPToNumber(data.address.zipCode),
        Complemento: data.address.adjunct,
        EmailPublicidade: data.newsletter,
        Endereco: data.address.street,
        IdCidade: data.address.city.id,
        IdEstado: data.auxStateId,
        IdPais: 0,
        Numero: data.address.number,
        SQTipoLogradouro: 6,
        ListaContatos: emergency,
        ListaTelefones: phones,
      },
    } as any;

    return this.http.post(back(BackEndpoints.UpdateUser), send)
      .pipe(
        tap(() => this.cache = data),
      );
  }

  stripPhoneMask(phone: string): string {
    return phone.replace(/[\(\) -]/g, '');
  }

  getAccount(): Observable<ProfileAccount> {
    return this.getCachedData().pipe(
      map(json => ({
        id: json.id,
        login: json.email,
        email: json.email,
        token: this.authService.getUserToken(),
      }),
    ));
  }

  setAccount(data: ProfileAccount) {
    const send = {
      emailAtual: data.login,
      email: data.login1,
      confirmacaoEmail: data.login2,
      senhaAtual: data.password,
      senha: data.password1,
      confirmacaoSenha: data.password2,
    };
    return this.http.post(back(BackEndpoints.UpdateAccount), send, { observe: 'response'})
      .pipe(
        mergeMap(response => response.statusText.startsWith('2000000') ? of(response) : throwError(response)),
        tap(() => {
          this.cache.email = data.login1 || data.login;
          this.authService.updateUserEmail(this.cache.email);
        }),
      );
  }

  getPayment(): Observable<ProfilePayment> {
    return this.http.get<ProfileResponse>('/assets/mockup/user-profile.json')
    .pipe(
      map(json => json.payment),
    );
  }

  getFranchisePersonalInfo(): Observable<FranchiseRequest> {
    if (!this.authService.getUser()) {
      return null;
    }
    return this.getCachedData()
    .pipe(
      map(json => ({
        name: JSONUtil.get(json, 'fullname'),
        gender: JSONUtil.get(json, 'gender'),
        email: JSONUtil.get(json, 'email'),
        maritalStatus: {
          id: JSONUtil.get(json, 'general.civil'),
        },
        birthDate: JSONUtil.get(json, 'birthday'),
        landlinePhone: JSONUtil.get(json, 'phones.home'),
        cellPhone: JSONUtil.get(json, 'phones.cell'),
        cpf: JSONUtil.get(json, 'cpfDocument'),
        identityDocument: JSONUtil.get(json, 'rgDocument'),
        address: JSONUtil.get(json, 'address'),
      } as FranchiseRequest)),
    );
  }

  getCivilState(): Observable<SelectItem[]> {
    return this.apollo.query<any>({
      query: AppGraphql.queryMaritalStatusList,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<SelectItem[]>('listMaritalStatusesRedis')),
    );
  }

  getProfessions(): Observable<SelectItem[]> {
    return this.getSelectLists('listaProfissao', 'IdProfissao');
  }

  getRelationships(): Observable<SelectItem[]> {
    return this.http.get<any>(back(BackEndpoints.Relationship))
      .pipe(
        map(json => json.map((item: any) => this.mapSimpleDataFromOldCanais(item, 'IdParentesco'))),
      );
  }

  mapSimpleDataFromOldCanais(item: any, key: string) {
    return {
      id: JSONUtil.get(item, key),
      text: JSONUtil.get(item, 'Descricao'),
    };
  }

  getSelectLists(list: string, key: string): Observable<any> {
    if (!this.cacheSelectorLists) {
      this.cacheSelectorLists = this.http.get<any>(back(BackEndpoints.ProfileLists))
        .pipe(
          map(json => JSONUtil.get(json, list)),
          map(json => json.map((item: any) => this.mapSimpleDataFromOldCanais(item, key))),
          shareReplay(1),
        );
    }

    return this.cacheSelectorLists;
  }

  searchZip(zip: string): Observable<ZipServiceAddress> {
    zip = zip.replace(/[-]/g, '');

    return this.apollo.query<ZipServiceAddress>({
      query: AppGraphql.queryGetAddressByZipCode,
      variables: { zip },
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<ZipServiceAddress>('getAddressByZipCode')),
    );
  }

}
