import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gym, MyContractResponse, RegistrationPaymentData, SelectItem, User } from '@models';
import { AuthService } from './auth.service';
import { GymService } from './gym.service';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { JSONUtil } from '@utils/json-util';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class MyContractService {

  cache: MyContractResponse[];
  user: User;

  constructor(
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly gymService: GymService,
  ) { }

  getInfo(): Observable<MyContractResponse[]> {
    this.user = null;
    return this.http.get<any>(back(BackEndpoints.Contract))
      .pipe(
        map(json => (json || []).map(this.mapContratoFromOldCanais)),
        catchError(() => [[]]),
        mergeMap((json: MyContractResponse[]) =>
        this.gymService.getGymNames()
        .pipe(
          catchError(() => json.map(contract => ({id: contract.gym, name: '?'}))),
          map((gymNames: any[]) => this.convertGymIdsToName(json, gymNames)),
          catchError(() => [json]),
          ),
          ),
        tap(result => {
          this.user = this.authService.getUser();
          this.cache = result;
        }),
      );
  }

  mapContratoFromOldCanais(contract: any): MyContractResponse {
    const gym = JSONUtil.get(contract, 'Unidade');
    return {
      gym,
      contractCode: JSONUtil.get(contract, 'Codigo'),
      plan: [
        JSONUtil.get(contract, 'ListaItens.0.PlanoUnidade.Plano.NomePlano'),
        JSONUtil.get(contract, 'SituacaoContrato'),
      ].join(' - '),
      dates: {
        start: JSONUtil.getDate(contract, 'DataInicio').toISOString(),
        end: JSONUtil.getDate(contract, 'DataFim').toISOString(),
      },
      interval: JSONUtil.get(contract, 'ListaItens.0.Horario'),
      deadline: JSONUtil.get(contract, 'ListaItens.0.PlanoUnidade.Preco.Periodicidade'),
      frequency: JSONUtil.get(contract, 'ListaItens.0.PlanoUnidade.Preco.Frequencia'),
      purchaseCode: JSONUtil.get(contract, 'ListaItens.0.CodigoCompra'),
      termsUrl: '',
      planValue: JSONUtil.get(contract, 'ValorPlanos'),
      servicesValue: JSONUtil.get(contract, 'ValorReceitas'),
      renewable: JSONUtil.get(contract, 'PodeRenovar'),
      preRenewable: JSONUtil.get(contract, 'PodeRenovarAntecipado'),
      cancelable: JSONUtil.get(contract, 'PodeCancelar'),
    };
  }

  convertGymIdsToName(contracts: MyContractResponse[], gymNames: any[]): MyContractResponse[] {
    contracts.forEach(
      contract => {
        const contractGym = gymNames.find(gym => gym.id === contract.gym) || {};
        contract.gym = {
          id: contractGym.id,
          name: contractGym.name,
          slug: contractGym.slug,
        } as Gym;
      },
    );
    return contracts;
  }

  cancelContract(codigoContrato: string): Observable<any> {
    return this.http.get<any>(back(BackEndpoints.ContractStart),
      { params: { codigoContrato } })
      .pipe(
        map(this.mapCancelamentoInfoFromOldCanais),
        mergeMap(result => result.data.withTax
          ? this.http.get<any>(back(BackEndpoints.ContractPayment), { params: { codigoContrato } })
            .pipe(
              map(this.mapCancelamentoFormasPagamento),
              map(payment => Object.assign(result, payment)),
            )
          : of(result),
        ),
        catchError(error => [{ error }]),
        mergeMap(result => this.getCachedInfo()
          .pipe(
            map(contracts => contracts.find(c => c.contractCode === codigoContrato)),
            map(contract => Object.assign(result, { contract })),
          ),
        ),
        mergeMap(result => (result['data'] && result['data'].userId !== this.user.id) || !result['contract']
              ? of({ error: { statusText: '0000000 Contrato não encontrado' }}) : of(result)),
      );
  }

  mapCancelamentoInfoFromOldCanais(data: any): any {
    const withTax = JSONUtil.get(data, 'ComMulta');
    const isRecurrent = JSONUtil.get(data, 'IsCobrancaRecorrente');
    const commonData = {
      withTax,
      isRecurrent,
      requestPayment: withTax && isRecurrent,
      userId: JSONUtil.get(data, 'Cliente'),
      dateStart: JSONUtil.getDate(data, 'DataInicio'),
      dateCancel: JSONUtil.getDate(data, 'DataCancelamento'),
    };
    const taxData = withTax ? {
      code: JSONUtil.get(data, 'ListaPlanos.0.NumeroCarrinhoCompraUnidade'),
      plan: JSONUtil.get(data, 'ListaPlanos.0.NomePlano'),
      valuePayed: JSONUtil.get(data, 'ListaPlanos.0.ValorPlano'),
      valueRemaining: JSONUtil.get(data, 'ListaPlanos.0.ValorRestantePlano'),
      dateMin: JSONUtil.getDate(data, 'ListaPlanos.0.DataCancelamentoMinimaAcordada'),
      cancelTax: JSONUtil.get(data, 'ValorMulta'),
    } : null;
    return { data: Object.assign(commonData, taxData) };

  }

  mapCancelamentoFormasPagamento(data: any) {
    const flagOptions: any[] = JSONUtil.get(data, 'FormasDePagamento');
    return {
      payment: {
        cardNumber: JSONUtil.get(data, 'DadosCartaoCliente.NumeroCartaoMascarado'),
        cardFlag: JSONUtil.get(data, 'DadosCartaoCliente.IdBandeira'),
        cardExpiration: JSONUtil.get(data, 'DadosCartaoCliente.DataValidade'),
        cardOwnerName: JSONUtil.get(data, 'DadosCartaoCliente.NomeCliente'),
        justClick: JSONUtil.get(data, 'JustClick'),
      } as RegistrationPaymentData,
      flagOptions: flagOptions.map(p => ({
        id: JSONUtil.get(p, 'IdBandeira'),
        text: JSONUtil.get(p, 'NomeBandeira'),
      })) as Array<SelectItem<any>>,
    };
  }

  getCachedInfo() {
    if (this.user === this.authService.getUser()) {
      return of(this.cache);
    } else {
      return this.getInfo();
    }
  }

  cancelContractFinish(codigoContrato: string, data = {} as RegistrationPaymentData) {
    return this.http.post(back(BackEndpoints.ContractFinish),
      this.mapPaymentToOldCanais(data), { params: { codigoContrato } });
  }

  mapPaymentToOldCanais(data: RegistrationPaymentData): any {
    return {
      DadosCartaoCliente: {
        CodigoSeguranca: data.cardCVV,
        DataValidade: data.cardExpiration,
        NomeCliente: data.cardOwnerName,
        NumeroCartao: data.cardNumber,
        NumeroCartaoMascarado: data.cardNumber,
      },
      IdFormaDePagamento: data.cardFlag,
      JustClick: data.justClick,
    };
  }

}
