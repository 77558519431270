import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { UpdatePasswordRequest, RegistrationSuccessRequest, ModalType, AlertMessage, MessageMap } from '@models';
import { ModalService } from './modal.service';
import { AlertMessageService } from './alert-message.service';

@Injectable()
export class UserService {

  constructor(
    private readonly alertMessage: AlertMessageService,
    private readonly http: HttpClient,
    private readonly modalService: ModalService,
  ) { }

  updateUserPassword(updatePasswordRequest: UpdatePasswordRequest) {
    return this.http.post<any>(sur(SurEndpoints.UrlUpdateUserPassword),
      {
        password: updatePasswordRequest.password,
        confirm: updatePasswordRequest.confirm,
        token: updatePasswordRequest.token,
      });
  }

  sendForgotPasswordEmail(email: string) {
    return this.http.post<any>(sur(SurEndpoints.UrlSendForgotPasswordEmail), { email });
  }

  unblockEmail(confirmation: string) {
    return this.http.get<any>(sur(SurEndpoints.UrlUnblockEmail, confirmation));
  }

  sendUnblockEmail(email: string) {
    return this.http.post<any>(sur(SurEndpoints.UrlSendUnblockEmail), { email });
  }

  sendRandomPasswordEmail(registrationSuccessRequest: RegistrationSuccessRequest) {
    return this.http.post<any>(sur(SurEndpoints.UrlSendRandomPasswordEmail),
      {
        email: registrationSuccessRequest.email,
        password: registrationSuccessRequest.password,
      });
  }

  modalResendEmailConfirm(email: string) {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'CONTA DE ACESSO BLOQUEADA',
      message: 'Sua conta de acesso ainda não foi desbloqueada. ' +
        'Por favor, realize o desbloqueio clicando no link enviado para seu e-mail. ' +
        'Se você não recebeu o e-mail de confirmação, clique no botão Reenviar abaixo, para envio do e-mail de confirmação. ',
      cancelButton: 'OK',
      confirmButton: 'REENVIAR',
      confirmCallback: () => this.resendemail(email),
    });
  }

  resendemail(email: string) {
    this.sendUnblockEmail(email).subscribe(
      () => this.alertMessage.showToastr(AlertMessage.success(MessageMap.EMAIL_ENVIADO)),
      () => this.alertMessage.showToastr(AlertMessage.error(MessageMap.PROBLEMA_ENVIO_EMAIL)),
    );
  }
}
