<app-navbar-top
  [navbarItems]="links"
  [navbarItemsMobile]="linksMobile"
  [navbarItemsLogged]="linksLogged"
  [menuOpenImagePath]="'assets/icons/ic-drawer.svg'"
  [menuCloseImagePath]="'assets/icons/ic-close.svg'"
  [height]="barHeightClose">
</app-navbar-top>

<div class="nav-background" [class.on-top]="opacity > 0"
  [style.height.px]="opacity > 0 ? headerHeight : barHeightClose">
  <!-- Background -->
</div>

<div [style.height.px]="headerHeight">
  <!-- Space -->
</div>

<div #headerContainer appOpenModalPadding class="header-container"
  [ngStyle]="{
      'opacity': opacity,
      'min-height.px': barHeightClose + 1
    }">
  <div class="header-content">
    <ng-container #headerContent></ng-container>
  </div>
</div>
